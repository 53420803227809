import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

export type AdminDeal = {
  __typename?: 'AdminDeal';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  zipCodes?: Maybe<Array<DealZipCode>>;
  national?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  bidders?: Maybe<Array<DealBidder>>;
  multiplier?: Maybe<Scalars['Int']>;
  multiplierDescription?: Maybe<Scalars['String']>;
  priceInCents?: Maybe<Scalars['Int']>;
  priceText?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<DealFrequency>;
  visible?: Maybe<Scalars['Boolean']>;
  groupDiscountPercentage?: Maybe<Scalars['Int']>;
  joinCount?: Maybe<Scalars['Int']>;
  landingUrl?: Maybe<Scalars['String']>;
};

export type AppLink = {
  __typename?: 'AppLink';
  id: Scalars['ID'];
  type: AppLinkType;
  action: AppLinkAction;
  jobId?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  bidId?: Maybe<Scalars['String']>;
  dealContractId?: Maybe<Scalars['String']>;
  dealId?: Maybe<Scalars['String']>;
};

export enum AppLinkAction {
  None = 'NONE',
  DealJoined = 'DEAL_JOINED',
  DealLeft = 'DEAL_LEFT',
  DealDateProposed = 'DEAL_DATE_PROPOSED',
  DealDateAccepted = 'DEAL_DATE_ACCEPTED',
  DealDateRejected = 'DEAL_DATE_REJECTED',
  DealCompletedByContractor = 'DEAL_COMPLETED_BY_CONTRACTOR'
}

export enum AppLinkType {
  HiredForJob = 'HIRED_FOR_JOB',
  ChatMessage = 'CHAT_MESSAGE',
  BiddableJob = 'BIDDABLE_JOB',
  BidActivity = 'BID_ACTIVITY',
  PropertyReady = 'PROPERTY_READY',
  DealActivity = 'DEAL_ACTIVITY'
}

export type AppLinkWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  type?: Maybe<AppLinkType>;
  type_not?: Maybe<AppLinkType>;
  type_in?: Maybe<Array<AppLinkType>>;
  type_not_in?: Maybe<Array<AppLinkType>>;
  action?: Maybe<AppLinkAction>;
  action_not?: Maybe<AppLinkAction>;
  action_in?: Maybe<Array<AppLinkAction>>;
  action_not_in?: Maybe<Array<AppLinkAction>>;
  jobId?: Maybe<Scalars['String']>;
  jobId_not?: Maybe<Scalars['String']>;
  jobId_in?: Maybe<Array<Scalars['String']>>;
  jobId_not_in?: Maybe<Array<Scalars['String']>>;
  jobId_lt?: Maybe<Scalars['String']>;
  jobId_lte?: Maybe<Scalars['String']>;
  jobId_gt?: Maybe<Scalars['String']>;
  jobId_gte?: Maybe<Scalars['String']>;
  jobId_contains?: Maybe<Scalars['String']>;
  jobId_not_contains?: Maybe<Scalars['String']>;
  jobId_starts_with?: Maybe<Scalars['String']>;
  jobId_not_starts_with?: Maybe<Scalars['String']>;
  jobId_ends_with?: Maybe<Scalars['String']>;
  jobId_not_ends_with?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  propertyId_not?: Maybe<Scalars['String']>;
  propertyId_in?: Maybe<Array<Scalars['String']>>;
  propertyId_not_in?: Maybe<Array<Scalars['String']>>;
  propertyId_lt?: Maybe<Scalars['String']>;
  propertyId_lte?: Maybe<Scalars['String']>;
  propertyId_gt?: Maybe<Scalars['String']>;
  propertyId_gte?: Maybe<Scalars['String']>;
  propertyId_contains?: Maybe<Scalars['String']>;
  propertyId_not_contains?: Maybe<Scalars['String']>;
  propertyId_starts_with?: Maybe<Scalars['String']>;
  propertyId_not_starts_with?: Maybe<Scalars['String']>;
  propertyId_ends_with?: Maybe<Scalars['String']>;
  propertyId_not_ends_with?: Maybe<Scalars['String']>;
  bidId?: Maybe<Scalars['String']>;
  bidId_not?: Maybe<Scalars['String']>;
  bidId_in?: Maybe<Array<Scalars['String']>>;
  bidId_not_in?: Maybe<Array<Scalars['String']>>;
  bidId_lt?: Maybe<Scalars['String']>;
  bidId_lte?: Maybe<Scalars['String']>;
  bidId_gt?: Maybe<Scalars['String']>;
  bidId_gte?: Maybe<Scalars['String']>;
  bidId_contains?: Maybe<Scalars['String']>;
  bidId_not_contains?: Maybe<Scalars['String']>;
  bidId_starts_with?: Maybe<Scalars['String']>;
  bidId_not_starts_with?: Maybe<Scalars['String']>;
  bidId_ends_with?: Maybe<Scalars['String']>;
  bidId_not_ends_with?: Maybe<Scalars['String']>;
  dealContractId?: Maybe<Scalars['String']>;
  dealContractId_not?: Maybe<Scalars['String']>;
  dealContractId_in?: Maybe<Array<Scalars['String']>>;
  dealContractId_not_in?: Maybe<Array<Scalars['String']>>;
  dealContractId_lt?: Maybe<Scalars['String']>;
  dealContractId_lte?: Maybe<Scalars['String']>;
  dealContractId_gt?: Maybe<Scalars['String']>;
  dealContractId_gte?: Maybe<Scalars['String']>;
  dealContractId_contains?: Maybe<Scalars['String']>;
  dealContractId_not_contains?: Maybe<Scalars['String']>;
  dealContractId_starts_with?: Maybe<Scalars['String']>;
  dealContractId_not_starts_with?: Maybe<Scalars['String']>;
  dealContractId_ends_with?: Maybe<Scalars['String']>;
  dealContractId_not_ends_with?: Maybe<Scalars['String']>;
  dealId?: Maybe<Scalars['String']>;
  dealId_not?: Maybe<Scalars['String']>;
  dealId_in?: Maybe<Array<Scalars['String']>>;
  dealId_not_in?: Maybe<Array<Scalars['String']>>;
  dealId_lt?: Maybe<Scalars['String']>;
  dealId_lte?: Maybe<Scalars['String']>;
  dealId_gt?: Maybe<Scalars['String']>;
  dealId_gte?: Maybe<Scalars['String']>;
  dealId_contains?: Maybe<Scalars['String']>;
  dealId_not_contains?: Maybe<Scalars['String']>;
  dealId_starts_with?: Maybe<Scalars['String']>;
  dealId_not_starts_with?: Maybe<Scalars['String']>;
  dealId_ends_with?: Maybe<Scalars['String']>;
  dealId_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<AppLinkWhereInput>>;
  OR?: Maybe<Array<AppLinkWhereInput>>;
  NOT?: Maybe<Array<AppLinkWhereInput>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  contractor?: Maybe<Contractor>;
  homeowner?: Maybe<Homeowner>;
  partnerToken?: Maybe<Scalars['String']>;
  oneTimeKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type AuthPayloadContractor = {
  __typename?: 'AuthPayloadContractor';
  token?: Maybe<Scalars['String']>;
  contractor?: Maybe<Contractor>;
  jobId?: Maybe<Scalars['ID']>;
};

export type Bid = {
  __typename?: 'Bid';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  bidChat: BidChat;
  status: BidStatus;
  amountCurrency: Currency;
  hasUnreadMessages?: Maybe<Scalars['Boolean']>;
  contractor?: Maybe<LimitedContractor>;
};

export type BidCharge = {
  __typename?: 'BidCharge';
  bidId?: Maybe<Scalars['ID']>;
  jobTitle?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  /** @deprecated Prefer "amountInCents" over "amount". */
  amount?: Maybe<Scalars['Int']>;
  amountInCents?: Maybe<Scalars['Int']>;
  lastChargeDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  displayStatus?: Maybe<Scalars['String']>;
};

export type BidChat = {
  __typename?: 'BidChat';
  id: Scalars['ID'];
  messages?: Maybe<Array<BidMessage>>;
};


export type BidChatMessagesArgs = {
  where?: Maybe<BidMessageWhereInput>;
  orderBy?: Maybe<BidMessageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum BidChatOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type BidChatWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  bid?: Maybe<BidWhereInput>;
  deal?: Maybe<DealWhereInput>;
  homeowner?: Maybe<HomeownerWhereInput>;
  contractor?: Maybe<ContractorWhereInput>;
  messages_every?: Maybe<BidMessageWhereInput>;
  messages_some?: Maybe<BidMessageWhereInput>;
  messages_none?: Maybe<BidMessageWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<BidChatWhereInput>>;
  OR?: Maybe<Array<BidChatWhereInput>>;
  NOT?: Maybe<Array<BidChatWhereInput>>;
};

export type BiddableOwner = {
  __typename?: 'BiddableOwner';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type BiddableProperty = IPropertyData & {
  __typename?: 'BiddableProperty';
  id?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  renoworksId?: Maybe<Scalars['String']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  designCenterImageUrl?: Maybe<Scalars['String']>;
  viewerImageUrl?: Maybe<Scalars['String']>;
  newViewer?: Maybe<Scalars['Boolean']>;
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  owner?: Maybe<BiddableOwner>;
};

export type BidMessage = {
  __typename?: 'BidMessage';
  id: Scalars['ID'];
  text: Scalars['String'];
  createdAt: Scalars['DateTime'];
  systemMessage?: Maybe<Scalars['Boolean']>;
  attachment?: Maybe<Picture>;
  sender: User;
  bidChatId?: Maybe<Scalars['ID']>;
};

export enum BidMessageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  SystemMessageAsc = 'systemMessage_ASC',
  SystemMessageDesc = 'systemMessage_DESC',
  ReadAsc = 'read_ASC',
  ReadDesc = 'read_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type BidMessageWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  chat?: Maybe<BidChatWhereInput>;
  sender?: Maybe<UserWhereInput>;
  bid?: Maybe<BidWhereInput>;
  dealContract?: Maybe<DealContractWhereInput>;
  text?: Maybe<Scalars['String']>;
  text_not?: Maybe<Scalars['String']>;
  text_in?: Maybe<Array<Scalars['String']>>;
  text_not_in?: Maybe<Array<Scalars['String']>>;
  text_lt?: Maybe<Scalars['String']>;
  text_lte?: Maybe<Scalars['String']>;
  text_gt?: Maybe<Scalars['String']>;
  text_gte?: Maybe<Scalars['String']>;
  text_contains?: Maybe<Scalars['String']>;
  text_not_contains?: Maybe<Scalars['String']>;
  text_starts_with?: Maybe<Scalars['String']>;
  text_not_starts_with?: Maybe<Scalars['String']>;
  text_ends_with?: Maybe<Scalars['String']>;
  text_not_ends_with?: Maybe<Scalars['String']>;
  attachment?: Maybe<PictureWhereInput>;
  systemMessage?: Maybe<Scalars['Boolean']>;
  systemMessage_not?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  read_not?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<BidMessageWhereInput>>;
  OR?: Maybe<Array<BidMessageWhereInput>>;
  NOT?: Maybe<Array<BidMessageWhereInput>>;
};

export enum BidOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AmountCurrencyAsc = 'amountCurrency_ASC',
  AmountCurrencyDesc = 'amountCurrency_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC'
}

export enum BidStatus {
  Submitted = 'SUBMITTED',
  Hired = 'HIRED',
  Rejected = 'REJECTED'
}

export type BidWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Currency>;
  amountCurrency_not?: Maybe<Currency>;
  amountCurrency_in?: Maybe<Array<Currency>>;
  amountCurrency_not_in?: Maybe<Array<Currency>>;
  status?: Maybe<BidStatus>;
  status_not?: Maybe<BidStatus>;
  status_in?: Maybe<Array<BidStatus>>;
  status_not_in?: Maybe<Array<BidStatus>>;
  bidChat?: Maybe<BidChatWhereInput>;
  job?: Maybe<JobWhereInput>;
  contractor?: Maybe<ContractorWhereInput>;
  homeowner?: Maybe<HomeownerWhereInput>;
  order?: Maybe<OrderWhereInput>;
  AND?: Maybe<Array<BidWhereInput>>;
  OR?: Maybe<Array<BidWhereInput>>;
  NOT?: Maybe<Array<BidWhereInput>>;
};

export enum BillingStatus {
  Current = 'CURRENT',
  PastDue = 'PAST_DUE'
}

export type Charge = {
  __typename?: 'Charge';
  id: Scalars['ID'];
  status: ChargeStatus;
  description: Scalars['String'];
  trigger: ChargeTrigger;
  paymentMethod: PaymentMethod;
  user: User;
  amount: Scalars['Float'];
  amountCurrency: Currency;
  authDate: Scalars['DateTime'];
  captureDate?: Maybe<Scalars['DateTime']>;
  redactedCard: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  fullName: Scalars['String'];
  email: Scalars['String'];
};

export type ChargeAmounts = {
  __typename?: 'ChargeAmounts';
  newPropertyCharge?: Maybe<ChargeInfo>;
};

export enum ChargeFrequency {
  Free = 'FREE',
  Subscription = 'SUBSCRIPTION',
  OneTime = 'ONE_TIME'
}

export type ChargeInfo = {
  __typename?: 'ChargeInfo';
  chargeFrequency?: Maybe<ChargeFrequency>;
  amountInCents?: Maybe<Scalars['Int']>;
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export enum ChargeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StripePaymentIntentIdAsc = 'stripePaymentIntentId_ASC',
  StripePaymentIntentIdDesc = 'stripePaymentIntentId_DESC',
  StripeChargeIdAsc = 'stripeChargeId_ASC',
  StripeChargeIdDesc = 'stripeChargeId_DESC',
  StripeOrderIdAsc = 'stripeOrderId_ASC',
  StripeOrderIdDesc = 'stripeOrderId_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  TriggerAsc = 'trigger_ASC',
  TriggerDesc = 'trigger_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AmountCurrencyAsc = 'amountCurrency_ASC',
  AmountCurrencyDesc = 'amountCurrency_DESC',
  AuthDateAsc = 'authDate_ASC',
  AuthDateDesc = 'authDate_DESC',
  CaptureDateAsc = 'captureDate_ASC',
  CaptureDateDesc = 'captureDate_DESC',
  FailureCodeAsc = 'failureCode_ASC',
  FailureCodeDesc = 'failureCode_DESC',
  FailureMessageAsc = 'failureMessage_ASC',
  FailureMessageDesc = 'failureMessage_DESC',
  RawChargeAsc = 'rawCharge_ASC',
  RawChargeDesc = 'rawCharge_DESC',
  RedactedCardAsc = 'redactedCard_ASC',
  RedactedCardDesc = 'redactedCard_DESC',
  AddressLine1Asc = 'addressLine1_ASC',
  AddressLine1Desc = 'addressLine1_DESC',
  AddressLine2Asc = 'addressLine2_ASC',
  AddressLine2Desc = 'addressLine2_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export enum ChargeStatus {
  Authorized = 'AUTHORIZED',
  Captured = 'CAPTURED',
  Failed = 'FAILED'
}

export enum ChargeTrigger {
  NewProperty = 'NEW_PROPERTY',
  OrderedMeasurement = 'ORDERED_MEASUREMENT',
  HiredBid = 'HIRED_BID',
  Other = 'OTHER'
}

export type ChargeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not?: Maybe<Scalars['String']>;
  stripePaymentIntentId_in?: Maybe<Array<Scalars['String']>>;
  stripePaymentIntentId_not_in?: Maybe<Array<Scalars['String']>>;
  stripePaymentIntentId_lt?: Maybe<Scalars['String']>;
  stripePaymentIntentId_lte?: Maybe<Scalars['String']>;
  stripePaymentIntentId_gt?: Maybe<Scalars['String']>;
  stripePaymentIntentId_gte?: Maybe<Scalars['String']>;
  stripePaymentIntentId_contains?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not_contains?: Maybe<Scalars['String']>;
  stripePaymentIntentId_starts_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not_starts_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId_ends_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not_ends_with?: Maybe<Scalars['String']>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripeChargeId_not?: Maybe<Scalars['String']>;
  stripeChargeId_in?: Maybe<Array<Scalars['String']>>;
  stripeChargeId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeChargeId_lt?: Maybe<Scalars['String']>;
  stripeChargeId_lte?: Maybe<Scalars['String']>;
  stripeChargeId_gt?: Maybe<Scalars['String']>;
  stripeChargeId_gte?: Maybe<Scalars['String']>;
  stripeChargeId_contains?: Maybe<Scalars['String']>;
  stripeChargeId_not_contains?: Maybe<Scalars['String']>;
  stripeChargeId_starts_with?: Maybe<Scalars['String']>;
  stripeChargeId_not_starts_with?: Maybe<Scalars['String']>;
  stripeChargeId_ends_with?: Maybe<Scalars['String']>;
  stripeChargeId_not_ends_with?: Maybe<Scalars['String']>;
  stripeOrderId?: Maybe<Scalars['String']>;
  stripeOrderId_not?: Maybe<Scalars['String']>;
  stripeOrderId_in?: Maybe<Array<Scalars['String']>>;
  stripeOrderId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeOrderId_lt?: Maybe<Scalars['String']>;
  stripeOrderId_lte?: Maybe<Scalars['String']>;
  stripeOrderId_gt?: Maybe<Scalars['String']>;
  stripeOrderId_gte?: Maybe<Scalars['String']>;
  stripeOrderId_contains?: Maybe<Scalars['String']>;
  stripeOrderId_not_contains?: Maybe<Scalars['String']>;
  stripeOrderId_starts_with?: Maybe<Scalars['String']>;
  stripeOrderId_not_starts_with?: Maybe<Scalars['String']>;
  stripeOrderId_ends_with?: Maybe<Scalars['String']>;
  stripeOrderId_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<ChargeStatus>;
  status_not?: Maybe<ChargeStatus>;
  status_in?: Maybe<Array<ChargeStatus>>;
  status_not_in?: Maybe<Array<ChargeStatus>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  trigger?: Maybe<ChargeTrigger>;
  trigger_not?: Maybe<ChargeTrigger>;
  trigger_in?: Maybe<Array<ChargeTrigger>>;
  trigger_not_in?: Maybe<Array<ChargeTrigger>>;
  order?: Maybe<OrderWhereInput>;
  paymentMethod?: Maybe<PaymentMethodWhereInput>;
  user?: Maybe<UserWhereInput>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Currency>;
  amountCurrency_not?: Maybe<Currency>;
  amountCurrency_in?: Maybe<Array<Currency>>;
  amountCurrency_not_in?: Maybe<Array<Currency>>;
  authDate?: Maybe<Scalars['DateTime']>;
  authDate_not?: Maybe<Scalars['DateTime']>;
  authDate_in?: Maybe<Array<Scalars['DateTime']>>;
  authDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  authDate_lt?: Maybe<Scalars['DateTime']>;
  authDate_lte?: Maybe<Scalars['DateTime']>;
  authDate_gt?: Maybe<Scalars['DateTime']>;
  authDate_gte?: Maybe<Scalars['DateTime']>;
  captureDate?: Maybe<Scalars['DateTime']>;
  captureDate_not?: Maybe<Scalars['DateTime']>;
  captureDate_in?: Maybe<Array<Scalars['DateTime']>>;
  captureDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  captureDate_lt?: Maybe<Scalars['DateTime']>;
  captureDate_lte?: Maybe<Scalars['DateTime']>;
  captureDate_gt?: Maybe<Scalars['DateTime']>;
  captureDate_gte?: Maybe<Scalars['DateTime']>;
  failureCode?: Maybe<Scalars['String']>;
  failureCode_not?: Maybe<Scalars['String']>;
  failureCode_in?: Maybe<Array<Scalars['String']>>;
  failureCode_not_in?: Maybe<Array<Scalars['String']>>;
  failureCode_lt?: Maybe<Scalars['String']>;
  failureCode_lte?: Maybe<Scalars['String']>;
  failureCode_gt?: Maybe<Scalars['String']>;
  failureCode_gte?: Maybe<Scalars['String']>;
  failureCode_contains?: Maybe<Scalars['String']>;
  failureCode_not_contains?: Maybe<Scalars['String']>;
  failureCode_starts_with?: Maybe<Scalars['String']>;
  failureCode_not_starts_with?: Maybe<Scalars['String']>;
  failureCode_ends_with?: Maybe<Scalars['String']>;
  failureCode_not_ends_with?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  failureMessage_not?: Maybe<Scalars['String']>;
  failureMessage_in?: Maybe<Array<Scalars['String']>>;
  failureMessage_not_in?: Maybe<Array<Scalars['String']>>;
  failureMessage_lt?: Maybe<Scalars['String']>;
  failureMessage_lte?: Maybe<Scalars['String']>;
  failureMessage_gt?: Maybe<Scalars['String']>;
  failureMessage_gte?: Maybe<Scalars['String']>;
  failureMessage_contains?: Maybe<Scalars['String']>;
  failureMessage_not_contains?: Maybe<Scalars['String']>;
  failureMessage_starts_with?: Maybe<Scalars['String']>;
  failureMessage_not_starts_with?: Maybe<Scalars['String']>;
  failureMessage_ends_with?: Maybe<Scalars['String']>;
  failureMessage_not_ends_with?: Maybe<Scalars['String']>;
  redactedCard?: Maybe<Scalars['String']>;
  redactedCard_not?: Maybe<Scalars['String']>;
  redactedCard_in?: Maybe<Array<Scalars['String']>>;
  redactedCard_not_in?: Maybe<Array<Scalars['String']>>;
  redactedCard_lt?: Maybe<Scalars['String']>;
  redactedCard_lte?: Maybe<Scalars['String']>;
  redactedCard_gt?: Maybe<Scalars['String']>;
  redactedCard_gte?: Maybe<Scalars['String']>;
  redactedCard_contains?: Maybe<Scalars['String']>;
  redactedCard_not_contains?: Maybe<Scalars['String']>;
  redactedCard_starts_with?: Maybe<Scalars['String']>;
  redactedCard_not_starts_with?: Maybe<Scalars['String']>;
  redactedCard_ends_with?: Maybe<Scalars['String']>;
  redactedCard_not_ends_with?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine1_not?: Maybe<Scalars['String']>;
  addressLine1_in?: Maybe<Array<Scalars['String']>>;
  addressLine1_not_in?: Maybe<Array<Scalars['String']>>;
  addressLine1_lt?: Maybe<Scalars['String']>;
  addressLine1_lte?: Maybe<Scalars['String']>;
  addressLine1_gt?: Maybe<Scalars['String']>;
  addressLine1_gte?: Maybe<Scalars['String']>;
  addressLine1_contains?: Maybe<Scalars['String']>;
  addressLine1_not_contains?: Maybe<Scalars['String']>;
  addressLine1_starts_with?: Maybe<Scalars['String']>;
  addressLine1_not_starts_with?: Maybe<Scalars['String']>;
  addressLine1_ends_with?: Maybe<Scalars['String']>;
  addressLine1_not_ends_with?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine2_not?: Maybe<Scalars['String']>;
  addressLine2_in?: Maybe<Array<Scalars['String']>>;
  addressLine2_not_in?: Maybe<Array<Scalars['String']>>;
  addressLine2_lt?: Maybe<Scalars['String']>;
  addressLine2_lte?: Maybe<Scalars['String']>;
  addressLine2_gt?: Maybe<Scalars['String']>;
  addressLine2_gte?: Maybe<Scalars['String']>;
  addressLine2_contains?: Maybe<Scalars['String']>;
  addressLine2_not_contains?: Maybe<Scalars['String']>;
  addressLine2_starts_with?: Maybe<Scalars['String']>;
  addressLine2_not_starts_with?: Maybe<Scalars['String']>;
  addressLine2_ends_with?: Maybe<Scalars['String']>;
  addressLine2_not_ends_with?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_not?: Maybe<Scalars['String']>;
  city_in?: Maybe<Array<Scalars['String']>>;
  city_not_in?: Maybe<Array<Scalars['String']>>;
  city_lt?: Maybe<Scalars['String']>;
  city_lte?: Maybe<Scalars['String']>;
  city_gt?: Maybe<Scalars['String']>;
  city_gte?: Maybe<Scalars['String']>;
  city_contains?: Maybe<Scalars['String']>;
  city_not_contains?: Maybe<Scalars['String']>;
  city_starts_with?: Maybe<Scalars['String']>;
  city_not_starts_with?: Maybe<Scalars['String']>;
  city_ends_with?: Maybe<Scalars['String']>;
  city_not_ends_with?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_not?: Maybe<Scalars['String']>;
  state_in?: Maybe<Array<Scalars['String']>>;
  state_not_in?: Maybe<Array<Scalars['String']>>;
  state_lt?: Maybe<Scalars['String']>;
  state_lte?: Maybe<Scalars['String']>;
  state_gt?: Maybe<Scalars['String']>;
  state_gte?: Maybe<Scalars['String']>;
  state_contains?: Maybe<Scalars['String']>;
  state_not_contains?: Maybe<Scalars['String']>;
  state_starts_with?: Maybe<Scalars['String']>;
  state_not_starts_with?: Maybe<Scalars['String']>;
  state_ends_with?: Maybe<Scalars['String']>;
  state_not_ends_with?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  zipCode_not?: Maybe<Scalars['String']>;
  zipCode_in?: Maybe<Array<Scalars['String']>>;
  zipCode_not_in?: Maybe<Array<Scalars['String']>>;
  zipCode_lt?: Maybe<Scalars['String']>;
  zipCode_lte?: Maybe<Scalars['String']>;
  zipCode_gt?: Maybe<Scalars['String']>;
  zipCode_gte?: Maybe<Scalars['String']>;
  zipCode_contains?: Maybe<Scalars['String']>;
  zipCode_not_contains?: Maybe<Scalars['String']>;
  zipCode_starts_with?: Maybe<Scalars['String']>;
  zipCode_not_starts_with?: Maybe<Scalars['String']>;
  zipCode_ends_with?: Maybe<Scalars['String']>;
  zipCode_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ChargeWhereInput>>;
  OR?: Maybe<Array<ChargeWhereInput>>;
  NOT?: Maybe<Array<ChargeWhereInput>>;
};

export type Chimney = {
  __typename?: 'Chimney';
  id: Scalars['ID'];
  refId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum ChimneyOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type ChimneyWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ChimneyWhereInput>>;
  OR?: Maybe<Array<ChimneyWhereInput>>;
  NOT?: Maybe<Array<ChimneyWhereInput>>;
};

export type Contractor = {
  __typename?: 'Contractor';
  id: Scalars['ID'];
  avatar?: Maybe<Picture>;
  companyName: Scalars['String'];
  companyAdminName?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyBio?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
  secondaryWebsite?: Maybe<Scalars['String']>;
  trades?: Maybe<Array<Trade>>;
  counties?: Maybe<Array<County>>;
  workPictureUrls: Array<Scalars['String']>;
  hubspotStage?: Maybe<HubspotContractorStage>;
  hubspotCompanyId?: Maybe<Scalars['String']>;
  bids?: Maybe<Array<Bid>>;
  orderedMeasurements?: Maybe<Array<OrderedMeasurement>>;
  user: User;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type ContractorTradesArgs = {
  where?: Maybe<TradeWhereInput>;
  orderBy?: Maybe<TradeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ContractorCountiesArgs = {
  where?: Maybe<CountyWhereInput>;
  orderBy?: Maybe<CountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ContractorBidsArgs = {
  where?: Maybe<BidWhereInput>;
  orderBy?: Maybe<BidOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ContractorOrderedMeasurementsArgs = {
  where?: Maybe<OrderedMeasurementWhereInput>;
  orderBy?: Maybe<OrderedMeasurementOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractorAvatar = {
  __typename?: 'ContractorAvatar';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
};

export type ContractorBid = {
  __typename?: 'ContractorBid';
  id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Currency>;
  status?: Maybe<BidStatus>;
  job?: Maybe<ContractorJob>;
  bidChat?: Maybe<BidChat>;
};

export type ContractorDeal = {
  __typename?: 'ContractorDeal';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  groupDiscountPercentage?: Maybe<Scalars['Int']>;
  frequency?: Maybe<DealFrequency>;
  joinCount?: Maybe<Scalars['Int']>;
  landingUrl?: Maybe<Scalars['String']>;
};

export type ContractorJob = {
  __typename?: 'ContractorJob';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnailSlug?: Maybe<Scalars['String']>;
  contractorChargeAmount?: Maybe<Scalars['Float']>;
  dummyProperty?: Maybe<Scalars['Boolean']>;
  propertyMeasurements?: Maybe<Scalars['Json']>;
  date?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<JobStatus>;
  completedOn?: Maybe<Scalars['DateTime']>;
  trades?: Maybe<Array<Trade>>;
  selections?: Maybe<Array<Selection>>;
  pictures?: Maybe<Array<Picture>>;
  iconSlug?: Maybe<Scalars['String']>;
  property?: Maybe<PropertyType>;
  myBid?: Maybe<ContractorBid>;
  bidCount?: Maybe<Scalars['Int']>;
  hasSelectedBid?: Maybe<Scalars['Boolean']>;
  hasUnreadMessages?: Maybe<Scalars['Boolean']>;
  isSuggested?: Maybe<Scalars['Boolean']>;
};

export type ContractorOrderedMeasurement = {
  __typename?: 'ContractorOrderedMeasurement';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  invitedEmails?: Maybe<Array<Scalars['String']>>;
  property?: Maybe<OrderedMeasurementProperty>;
  contractor?: Maybe<Contractor>;
};

export type ContractorWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  avatar?: Maybe<PictureWhereInput>;
  companyName?: Maybe<Scalars['String']>;
  companyName_not?: Maybe<Scalars['String']>;
  companyName_in?: Maybe<Array<Scalars['String']>>;
  companyName_not_in?: Maybe<Array<Scalars['String']>>;
  companyName_lt?: Maybe<Scalars['String']>;
  companyName_lte?: Maybe<Scalars['String']>;
  companyName_gt?: Maybe<Scalars['String']>;
  companyName_gte?: Maybe<Scalars['String']>;
  companyName_contains?: Maybe<Scalars['String']>;
  companyName_not_contains?: Maybe<Scalars['String']>;
  companyName_starts_with?: Maybe<Scalars['String']>;
  companyName_not_starts_with?: Maybe<Scalars['String']>;
  companyName_ends_with?: Maybe<Scalars['String']>;
  companyName_not_ends_with?: Maybe<Scalars['String']>;
  companyAdminName?: Maybe<Scalars['String']>;
  companyAdminName_not?: Maybe<Scalars['String']>;
  companyAdminName_in?: Maybe<Array<Scalars['String']>>;
  companyAdminName_not_in?: Maybe<Array<Scalars['String']>>;
  companyAdminName_lt?: Maybe<Scalars['String']>;
  companyAdminName_lte?: Maybe<Scalars['String']>;
  companyAdminName_gt?: Maybe<Scalars['String']>;
  companyAdminName_gte?: Maybe<Scalars['String']>;
  companyAdminName_contains?: Maybe<Scalars['String']>;
  companyAdminName_not_contains?: Maybe<Scalars['String']>;
  companyAdminName_starts_with?: Maybe<Scalars['String']>;
  companyAdminName_not_starts_with?: Maybe<Scalars['String']>;
  companyAdminName_ends_with?: Maybe<Scalars['String']>;
  companyAdminName_not_ends_with?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyAddress_not?: Maybe<Scalars['String']>;
  companyAddress_in?: Maybe<Array<Scalars['String']>>;
  companyAddress_not_in?: Maybe<Array<Scalars['String']>>;
  companyAddress_lt?: Maybe<Scalars['String']>;
  companyAddress_lte?: Maybe<Scalars['String']>;
  companyAddress_gt?: Maybe<Scalars['String']>;
  companyAddress_gte?: Maybe<Scalars['String']>;
  companyAddress_contains?: Maybe<Scalars['String']>;
  companyAddress_not_contains?: Maybe<Scalars['String']>;
  companyAddress_starts_with?: Maybe<Scalars['String']>;
  companyAddress_not_starts_with?: Maybe<Scalars['String']>;
  companyAddress_ends_with?: Maybe<Scalars['String']>;
  companyAddress_not_ends_with?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyPhone_not?: Maybe<Scalars['String']>;
  companyPhone_in?: Maybe<Array<Scalars['String']>>;
  companyPhone_not_in?: Maybe<Array<Scalars['String']>>;
  companyPhone_lt?: Maybe<Scalars['String']>;
  companyPhone_lte?: Maybe<Scalars['String']>;
  companyPhone_gt?: Maybe<Scalars['String']>;
  companyPhone_gte?: Maybe<Scalars['String']>;
  companyPhone_contains?: Maybe<Scalars['String']>;
  companyPhone_not_contains?: Maybe<Scalars['String']>;
  companyPhone_starts_with?: Maybe<Scalars['String']>;
  companyPhone_not_starts_with?: Maybe<Scalars['String']>;
  companyPhone_ends_with?: Maybe<Scalars['String']>;
  companyPhone_not_ends_with?: Maybe<Scalars['String']>;
  companyBio?: Maybe<Scalars['String']>;
  companyBio_not?: Maybe<Scalars['String']>;
  companyBio_in?: Maybe<Array<Scalars['String']>>;
  companyBio_not_in?: Maybe<Array<Scalars['String']>>;
  companyBio_lt?: Maybe<Scalars['String']>;
  companyBio_lte?: Maybe<Scalars['String']>;
  companyBio_gt?: Maybe<Scalars['String']>;
  companyBio_gte?: Maybe<Scalars['String']>;
  companyBio_contains?: Maybe<Scalars['String']>;
  companyBio_not_contains?: Maybe<Scalars['String']>;
  companyBio_starts_with?: Maybe<Scalars['String']>;
  companyBio_not_starts_with?: Maybe<Scalars['String']>;
  companyBio_ends_with?: Maybe<Scalars['String']>;
  companyBio_not_ends_with?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  companyPosition_not?: Maybe<Scalars['String']>;
  companyPosition_in?: Maybe<Array<Scalars['String']>>;
  companyPosition_not_in?: Maybe<Array<Scalars['String']>>;
  companyPosition_lt?: Maybe<Scalars['String']>;
  companyPosition_lte?: Maybe<Scalars['String']>;
  companyPosition_gt?: Maybe<Scalars['String']>;
  companyPosition_gte?: Maybe<Scalars['String']>;
  companyPosition_contains?: Maybe<Scalars['String']>;
  companyPosition_not_contains?: Maybe<Scalars['String']>;
  companyPosition_starts_with?: Maybe<Scalars['String']>;
  companyPosition_not_starts_with?: Maybe<Scalars['String']>;
  companyPosition_ends_with?: Maybe<Scalars['String']>;
  companyPosition_not_ends_with?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
  primaryWebsite_not?: Maybe<Scalars['String']>;
  primaryWebsite_in?: Maybe<Array<Scalars['String']>>;
  primaryWebsite_not_in?: Maybe<Array<Scalars['String']>>;
  primaryWebsite_lt?: Maybe<Scalars['String']>;
  primaryWebsite_lte?: Maybe<Scalars['String']>;
  primaryWebsite_gt?: Maybe<Scalars['String']>;
  primaryWebsite_gte?: Maybe<Scalars['String']>;
  primaryWebsite_contains?: Maybe<Scalars['String']>;
  primaryWebsite_not_contains?: Maybe<Scalars['String']>;
  primaryWebsite_starts_with?: Maybe<Scalars['String']>;
  primaryWebsite_not_starts_with?: Maybe<Scalars['String']>;
  primaryWebsite_ends_with?: Maybe<Scalars['String']>;
  primaryWebsite_not_ends_with?: Maybe<Scalars['String']>;
  secondaryWebsite?: Maybe<Scalars['String']>;
  secondaryWebsite_not?: Maybe<Scalars['String']>;
  secondaryWebsite_in?: Maybe<Array<Scalars['String']>>;
  secondaryWebsite_not_in?: Maybe<Array<Scalars['String']>>;
  secondaryWebsite_lt?: Maybe<Scalars['String']>;
  secondaryWebsite_lte?: Maybe<Scalars['String']>;
  secondaryWebsite_gt?: Maybe<Scalars['String']>;
  secondaryWebsite_gte?: Maybe<Scalars['String']>;
  secondaryWebsite_contains?: Maybe<Scalars['String']>;
  secondaryWebsite_not_contains?: Maybe<Scalars['String']>;
  secondaryWebsite_starts_with?: Maybe<Scalars['String']>;
  secondaryWebsite_not_starts_with?: Maybe<Scalars['String']>;
  secondaryWebsite_ends_with?: Maybe<Scalars['String']>;
  secondaryWebsite_not_ends_with?: Maybe<Scalars['String']>;
  trades_every?: Maybe<TradeWhereInput>;
  trades_some?: Maybe<TradeWhereInput>;
  trades_none?: Maybe<TradeWhereInput>;
  counties_every?: Maybe<CountyWhereInput>;
  counties_some?: Maybe<CountyWhereInput>;
  counties_none?: Maybe<CountyWhereInput>;
  hubspotStage?: Maybe<HubspotContractorStage>;
  hubspotStage_not?: Maybe<HubspotContractorStage>;
  hubspotStage_in?: Maybe<Array<HubspotContractorStage>>;
  hubspotStage_not_in?: Maybe<Array<HubspotContractorStage>>;
  hubspotCompanyId?: Maybe<Scalars['String']>;
  hubspotCompanyId_not?: Maybe<Scalars['String']>;
  hubspotCompanyId_in?: Maybe<Array<Scalars['String']>>;
  hubspotCompanyId_not_in?: Maybe<Array<Scalars['String']>>;
  hubspotCompanyId_lt?: Maybe<Scalars['String']>;
  hubspotCompanyId_lte?: Maybe<Scalars['String']>;
  hubspotCompanyId_gt?: Maybe<Scalars['String']>;
  hubspotCompanyId_gte?: Maybe<Scalars['String']>;
  hubspotCompanyId_contains?: Maybe<Scalars['String']>;
  hubspotCompanyId_not_contains?: Maybe<Scalars['String']>;
  hubspotCompanyId_starts_with?: Maybe<Scalars['String']>;
  hubspotCompanyId_not_starts_with?: Maybe<Scalars['String']>;
  hubspotCompanyId_ends_with?: Maybe<Scalars['String']>;
  hubspotCompanyId_not_ends_with?: Maybe<Scalars['String']>;
  bids_every?: Maybe<BidWhereInput>;
  bids_some?: Maybe<BidWhereInput>;
  bids_none?: Maybe<BidWhereInput>;
  orderedMeasurements_every?: Maybe<OrderedMeasurementWhereInput>;
  orderedMeasurements_some?: Maybe<OrderedMeasurementWhereInput>;
  orderedMeasurements_none?: Maybe<OrderedMeasurementWhereInput>;
  user?: Maybe<UserWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ContractorWhereInput>>;
  OR?: Maybe<Array<ContractorWhereInput>>;
  NOT?: Maybe<Array<ContractorWhereInput>>;
};

export type County = {
  __typename?: 'County';
  id: Scalars['ID'];
  name: Scalars['String'];
  state: State;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum CountyOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type CountyWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  state?: Maybe<StateWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CountyWhereInput>>;
  OR?: Maybe<Array<CountyWhereInput>>;
  NOT?: Maybe<Array<CountyWhereInput>>;
};

export enum Currency {
  Usd = 'USD'
}


export type Deal = {
  __typename?: 'Deal';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  zipCodes?: Maybe<Array<DealZipCode>>;
  national: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
  bidders?: Maybe<Array<DealBidder>>;
  multiplier: Scalars['Int'];
  multiplierDescription: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  frequency: DealFrequency;
  visible: Scalars['Boolean'];
  groupDiscountPercentage: Scalars['Int'];
  chats?: Maybe<Array<BidChat>>;
  joinCount: Scalars['Int'];
  landingUrl: Scalars['String'];
};


export type DealZipCodesArgs = {
  where?: Maybe<DealZipCodeWhereInput>;
  orderBy?: Maybe<DealZipCodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DealUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DealBiddersArgs = {
  where?: Maybe<DealBidderWhereInput>;
  orderBy?: Maybe<DealBidderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DealChatsArgs = {
  where?: Maybe<BidChatWhereInput>;
  orderBy?: Maybe<BidChatOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DealBidder = {
  __typename?: 'DealBidder';
  id: Scalars['ID'];
  contractor: Contractor;
  priceInCents: Scalars['Int'];
  priceText?: Maybe<Scalars['String']>;
  deal?: Maybe<Deal>;
  dealContracts?: Maybe<Array<DealContract>>;
  joinCount: Scalars['Int'];
};


export type DealBidderDealContractsArgs = {
  where?: Maybe<DealContractWhereInput>;
  orderBy?: Maybe<DealContractOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DealBidderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PriceInCentsAsc = 'priceInCents_ASC',
  PriceInCentsDesc = 'priceInCents_DESC',
  PriceTextAsc = 'priceText_ASC',
  PriceTextDesc = 'priceText_DESC',
  JoinCountAsc = 'joinCount_ASC',
  JoinCountDesc = 'joinCount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DealBidderWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  contractor?: Maybe<ContractorWhereInput>;
  priceInCents?: Maybe<Scalars['Int']>;
  priceInCents_not?: Maybe<Scalars['Int']>;
  priceInCents_in?: Maybe<Array<Scalars['Int']>>;
  priceInCents_not_in?: Maybe<Array<Scalars['Int']>>;
  priceInCents_lt?: Maybe<Scalars['Int']>;
  priceInCents_lte?: Maybe<Scalars['Int']>;
  priceInCents_gt?: Maybe<Scalars['Int']>;
  priceInCents_gte?: Maybe<Scalars['Int']>;
  priceText?: Maybe<Scalars['String']>;
  priceText_not?: Maybe<Scalars['String']>;
  priceText_in?: Maybe<Array<Scalars['String']>>;
  priceText_not_in?: Maybe<Array<Scalars['String']>>;
  priceText_lt?: Maybe<Scalars['String']>;
  priceText_lte?: Maybe<Scalars['String']>;
  priceText_gt?: Maybe<Scalars['String']>;
  priceText_gte?: Maybe<Scalars['String']>;
  priceText_contains?: Maybe<Scalars['String']>;
  priceText_not_contains?: Maybe<Scalars['String']>;
  priceText_starts_with?: Maybe<Scalars['String']>;
  priceText_not_starts_with?: Maybe<Scalars['String']>;
  priceText_ends_with?: Maybe<Scalars['String']>;
  priceText_not_ends_with?: Maybe<Scalars['String']>;
  deal?: Maybe<DealWhereInput>;
  dealContracts_every?: Maybe<DealContractWhereInput>;
  dealContracts_some?: Maybe<DealContractWhereInput>;
  dealContracts_none?: Maybe<DealContractWhereInput>;
  joinCount?: Maybe<Scalars['Int']>;
  joinCount_not?: Maybe<Scalars['Int']>;
  joinCount_in?: Maybe<Array<Scalars['Int']>>;
  joinCount_not_in?: Maybe<Array<Scalars['Int']>>;
  joinCount_lt?: Maybe<Scalars['Int']>;
  joinCount_lte?: Maybe<Scalars['Int']>;
  joinCount_gt?: Maybe<Scalars['Int']>;
  joinCount_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DealBidderWhereInput>>;
  OR?: Maybe<Array<DealBidderWhereInput>>;
  NOT?: Maybe<Array<DealBidderWhereInput>>;
};

export type DealContract = {
  __typename?: 'DealContract';
  id: Scalars['ID'];
  property: Property;
  date?: Maybe<Scalars['DateTime']>;
  dateAccepted: Scalars['Boolean'];
  dealBidder: DealBidder;
  proposedBy: ProposedBy;
  completed: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
};

export enum DealContractOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  DateAcceptedAsc = 'dateAccepted_ASC',
  DateAcceptedDesc = 'dateAccepted_DESC',
  ProposedByAsc = 'proposedBy_ASC',
  ProposedByDesc = 'proposedBy_DESC',
  CompletedAsc = 'completed_ASC',
  CompletedDesc = 'completed_DESC',
  DeletedAsc = 'deleted_ASC',
  DeletedDesc = 'deleted_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DealContractWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  property?: Maybe<PropertyWhereInput>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  dateAccepted?: Maybe<Scalars['Boolean']>;
  dateAccepted_not?: Maybe<Scalars['Boolean']>;
  dealBidder?: Maybe<DealBidderWhereInput>;
  proposedBy?: Maybe<ProposedBy>;
  proposedBy_not?: Maybe<ProposedBy>;
  proposedBy_in?: Maybe<Array<ProposedBy>>;
  proposedBy_not_in?: Maybe<Array<ProposedBy>>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_not?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deleted_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DealContractWhereInput>>;
  OR?: Maybe<Array<DealContractWhereInput>>;
  NOT?: Maybe<Array<DealContractWhereInput>>;
};

export enum DealFilter {
  NeedsActions = 'NEEDS_ACTIONS',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED'
}

export enum DealFrequency {
  OneTime = 'ONE_TIME',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type DealWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  zipCodes_every?: Maybe<DealZipCodeWhereInput>;
  zipCodes_some?: Maybe<DealZipCodeWhereInput>;
  zipCodes_none?: Maybe<DealZipCodeWhereInput>;
  national?: Maybe<Scalars['Boolean']>;
  national_not?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  users_every?: Maybe<UserWhereInput>;
  users_some?: Maybe<UserWhereInput>;
  users_none?: Maybe<UserWhereInput>;
  bidders_every?: Maybe<DealBidderWhereInput>;
  bidders_some?: Maybe<DealBidderWhereInput>;
  bidders_none?: Maybe<DealBidderWhereInput>;
  multiplier?: Maybe<Scalars['Int']>;
  multiplier_not?: Maybe<Scalars['Int']>;
  multiplier_in?: Maybe<Array<Scalars['Int']>>;
  multiplier_not_in?: Maybe<Array<Scalars['Int']>>;
  multiplier_lt?: Maybe<Scalars['Int']>;
  multiplier_lte?: Maybe<Scalars['Int']>;
  multiplier_gt?: Maybe<Scalars['Int']>;
  multiplier_gte?: Maybe<Scalars['Int']>;
  multiplierDescription?: Maybe<Scalars['String']>;
  multiplierDescription_not?: Maybe<Scalars['String']>;
  multiplierDescription_in?: Maybe<Array<Scalars['String']>>;
  multiplierDescription_not_in?: Maybe<Array<Scalars['String']>>;
  multiplierDescription_lt?: Maybe<Scalars['String']>;
  multiplierDescription_lte?: Maybe<Scalars['String']>;
  multiplierDescription_gt?: Maybe<Scalars['String']>;
  multiplierDescription_gte?: Maybe<Scalars['String']>;
  multiplierDescription_contains?: Maybe<Scalars['String']>;
  multiplierDescription_not_contains?: Maybe<Scalars['String']>;
  multiplierDescription_starts_with?: Maybe<Scalars['String']>;
  multiplierDescription_not_starts_with?: Maybe<Scalars['String']>;
  multiplierDescription_ends_with?: Maybe<Scalars['String']>;
  multiplierDescription_not_ends_with?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Scalars['String']>>;
  image_not_in?: Maybe<Array<Scalars['String']>>;
  image_lt?: Maybe<Scalars['String']>;
  image_lte?: Maybe<Scalars['String']>;
  image_gt?: Maybe<Scalars['String']>;
  image_gte?: Maybe<Scalars['String']>;
  image_contains?: Maybe<Scalars['String']>;
  image_not_contains?: Maybe<Scalars['String']>;
  image_starts_with?: Maybe<Scalars['String']>;
  image_not_starts_with?: Maybe<Scalars['String']>;
  image_ends_with?: Maybe<Scalars['String']>;
  image_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  frequency?: Maybe<DealFrequency>;
  frequency_not?: Maybe<DealFrequency>;
  frequency_in?: Maybe<Array<DealFrequency>>;
  frequency_not_in?: Maybe<Array<DealFrequency>>;
  visible?: Maybe<Scalars['Boolean']>;
  visible_not?: Maybe<Scalars['Boolean']>;
  groupDiscountPercentage?: Maybe<Scalars['Int']>;
  groupDiscountPercentage_not?: Maybe<Scalars['Int']>;
  groupDiscountPercentage_in?: Maybe<Array<Scalars['Int']>>;
  groupDiscountPercentage_not_in?: Maybe<Array<Scalars['Int']>>;
  groupDiscountPercentage_lt?: Maybe<Scalars['Int']>;
  groupDiscountPercentage_lte?: Maybe<Scalars['Int']>;
  groupDiscountPercentage_gt?: Maybe<Scalars['Int']>;
  groupDiscountPercentage_gte?: Maybe<Scalars['Int']>;
  chats_every?: Maybe<BidChatWhereInput>;
  chats_some?: Maybe<BidChatWhereInput>;
  chats_none?: Maybe<BidChatWhereInput>;
  joinCount?: Maybe<Scalars['Int']>;
  joinCount_not?: Maybe<Scalars['Int']>;
  joinCount_in?: Maybe<Array<Scalars['Int']>>;
  joinCount_not_in?: Maybe<Array<Scalars['Int']>>;
  joinCount_lt?: Maybe<Scalars['Int']>;
  joinCount_lte?: Maybe<Scalars['Int']>;
  joinCount_gt?: Maybe<Scalars['Int']>;
  joinCount_gte?: Maybe<Scalars['Int']>;
  landingUrl?: Maybe<Scalars['String']>;
  landingUrl_not?: Maybe<Scalars['String']>;
  landingUrl_in?: Maybe<Array<Scalars['String']>>;
  landingUrl_not_in?: Maybe<Array<Scalars['String']>>;
  landingUrl_lt?: Maybe<Scalars['String']>;
  landingUrl_lte?: Maybe<Scalars['String']>;
  landingUrl_gt?: Maybe<Scalars['String']>;
  landingUrl_gte?: Maybe<Scalars['String']>;
  landingUrl_contains?: Maybe<Scalars['String']>;
  landingUrl_not_contains?: Maybe<Scalars['String']>;
  landingUrl_starts_with?: Maybe<Scalars['String']>;
  landingUrl_not_starts_with?: Maybe<Scalars['String']>;
  landingUrl_ends_with?: Maybe<Scalars['String']>;
  landingUrl_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DealWhereInput>>;
  OR?: Maybe<Array<DealWhereInput>>;
  NOT?: Maybe<Array<DealWhereInput>>;
};

export type DealZipCode = {
  __typename?: 'DealZipCode';
  id: Scalars['ID'];
  code: Scalars['String'];
};

export enum DealZipCodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DealZipCodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DealZipCodeWhereInput>>;
  OR?: Maybe<Array<DealZipCodeWhereInput>>;
  NOT?: Maybe<Array<DealZipCodeWhereInput>>;
};

export type Door = {
  __typename?: 'Door';
  id: Scalars['ID'];
  refId: Scalars['String'];
  materialCode?: Maybe<Scalars['String']>;
  type: DoorType;
  shape?: Maybe<Scalars['String']>;
  widthInInches: Scalars['Float'];
  heightInInches: Scalars['Float'];
  area?: Maybe<Scalars['Float']>;
  perimeter?: Maybe<Scalars['Float']>;
  wall: Wall;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum DoorOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  MaterialCodeAsc = 'materialCode_ASC',
  MaterialCodeDesc = 'materialCode_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ShapeAsc = 'shape_ASC',
  ShapeDesc = 'shape_DESC',
  WidthInInchesAsc = 'widthInInches_ASC',
  WidthInInchesDesc = 'widthInInches_DESC',
  HeightInInchesAsc = 'heightInInches_ASC',
  HeightInInchesDesc = 'heightInInches_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  PerimeterAsc = 'perimeter_ASC',
  PerimeterDesc = 'perimeter_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export enum DoorType {
  MainEntrance = 'MAIN_ENTRANCE',
  AdditionalEntrance = 'ADDITIONAL_ENTRANCE',
  GarageDoor = 'GARAGE_DOOR'
}

export type DoorWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  materialCode_not?: Maybe<Scalars['String']>;
  materialCode_in?: Maybe<Array<Scalars['String']>>;
  materialCode_not_in?: Maybe<Array<Scalars['String']>>;
  materialCode_lt?: Maybe<Scalars['String']>;
  materialCode_lte?: Maybe<Scalars['String']>;
  materialCode_gt?: Maybe<Scalars['String']>;
  materialCode_gte?: Maybe<Scalars['String']>;
  materialCode_contains?: Maybe<Scalars['String']>;
  materialCode_not_contains?: Maybe<Scalars['String']>;
  materialCode_starts_with?: Maybe<Scalars['String']>;
  materialCode_not_starts_with?: Maybe<Scalars['String']>;
  materialCode_ends_with?: Maybe<Scalars['String']>;
  materialCode_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<DoorType>;
  type_not?: Maybe<DoorType>;
  type_in?: Maybe<Array<DoorType>>;
  type_not_in?: Maybe<Array<DoorType>>;
  shape?: Maybe<Scalars['String']>;
  shape_not?: Maybe<Scalars['String']>;
  shape_in?: Maybe<Array<Scalars['String']>>;
  shape_not_in?: Maybe<Array<Scalars['String']>>;
  shape_lt?: Maybe<Scalars['String']>;
  shape_lte?: Maybe<Scalars['String']>;
  shape_gt?: Maybe<Scalars['String']>;
  shape_gte?: Maybe<Scalars['String']>;
  shape_contains?: Maybe<Scalars['String']>;
  shape_not_contains?: Maybe<Scalars['String']>;
  shape_starts_with?: Maybe<Scalars['String']>;
  shape_not_starts_with?: Maybe<Scalars['String']>;
  shape_ends_with?: Maybe<Scalars['String']>;
  shape_not_ends_with?: Maybe<Scalars['String']>;
  widthInInches?: Maybe<Scalars['Float']>;
  widthInInches_not?: Maybe<Scalars['Float']>;
  widthInInches_in?: Maybe<Array<Scalars['Float']>>;
  widthInInches_not_in?: Maybe<Array<Scalars['Float']>>;
  widthInInches_lt?: Maybe<Scalars['Float']>;
  widthInInches_lte?: Maybe<Scalars['Float']>;
  widthInInches_gt?: Maybe<Scalars['Float']>;
  widthInInches_gte?: Maybe<Scalars['Float']>;
  heightInInches?: Maybe<Scalars['Float']>;
  heightInInches_not?: Maybe<Scalars['Float']>;
  heightInInches_in?: Maybe<Array<Scalars['Float']>>;
  heightInInches_not_in?: Maybe<Array<Scalars['Float']>>;
  heightInInches_lt?: Maybe<Scalars['Float']>;
  heightInInches_lte?: Maybe<Scalars['Float']>;
  heightInInches_gt?: Maybe<Scalars['Float']>;
  heightInInches_gte?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  area_not?: Maybe<Scalars['Float']>;
  area_in?: Maybe<Array<Scalars['Float']>>;
  area_not_in?: Maybe<Array<Scalars['Float']>>;
  area_lt?: Maybe<Scalars['Float']>;
  area_lte?: Maybe<Scalars['Float']>;
  area_gt?: Maybe<Scalars['Float']>;
  area_gte?: Maybe<Scalars['Float']>;
  perimeter?: Maybe<Scalars['Float']>;
  perimeter_not?: Maybe<Scalars['Float']>;
  perimeter_in?: Maybe<Array<Scalars['Float']>>;
  perimeter_not_in?: Maybe<Array<Scalars['Float']>>;
  perimeter_lt?: Maybe<Scalars['Float']>;
  perimeter_lte?: Maybe<Scalars['Float']>;
  perimeter_gt?: Maybe<Scalars['Float']>;
  perimeter_gte?: Maybe<Scalars['Float']>;
  wall?: Maybe<WallWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DoorWhereInput>>;
  OR?: Maybe<Array<DoorWhereInput>>;
  NOT?: Maybe<Array<DoorWhereInput>>;
};

export type EmailWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  salutation_not?: Maybe<Scalars['String']>;
  salutation_in?: Maybe<Array<Scalars['String']>>;
  salutation_not_in?: Maybe<Array<Scalars['String']>>;
  salutation_lt?: Maybe<Scalars['String']>;
  salutation_lte?: Maybe<Scalars['String']>;
  salutation_gt?: Maybe<Scalars['String']>;
  salutation_gte?: Maybe<Scalars['String']>;
  salutation_contains?: Maybe<Scalars['String']>;
  salutation_not_contains?: Maybe<Scalars['String']>;
  salutation_starts_with?: Maybe<Scalars['String']>;
  salutation_not_starts_with?: Maybe<Scalars['String']>;
  salutation_ends_with?: Maybe<Scalars['String']>;
  salutation_not_ends_with?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonLink_not?: Maybe<Scalars['String']>;
  buttonLink_in?: Maybe<Array<Scalars['String']>>;
  buttonLink_not_in?: Maybe<Array<Scalars['String']>>;
  buttonLink_lt?: Maybe<Scalars['String']>;
  buttonLink_lte?: Maybe<Scalars['String']>;
  buttonLink_gt?: Maybe<Scalars['String']>;
  buttonLink_gte?: Maybe<Scalars['String']>;
  buttonLink_contains?: Maybe<Scalars['String']>;
  buttonLink_not_contains?: Maybe<Scalars['String']>;
  buttonLink_starts_with?: Maybe<Scalars['String']>;
  buttonLink_not_starts_with?: Maybe<Scalars['String']>;
  buttonLink_ends_with?: Maybe<Scalars['String']>;
  buttonLink_not_ends_with?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonText_not?: Maybe<Scalars['String']>;
  buttonText_in?: Maybe<Array<Scalars['String']>>;
  buttonText_not_in?: Maybe<Array<Scalars['String']>>;
  buttonText_lt?: Maybe<Scalars['String']>;
  buttonText_lte?: Maybe<Scalars['String']>;
  buttonText_gt?: Maybe<Scalars['String']>;
  buttonText_gte?: Maybe<Scalars['String']>;
  buttonText_contains?: Maybe<Scalars['String']>;
  buttonText_not_contains?: Maybe<Scalars['String']>;
  buttonText_starts_with?: Maybe<Scalars['String']>;
  buttonText_not_starts_with?: Maybe<Scalars['String']>;
  buttonText_ends_with?: Maybe<Scalars['String']>;
  buttonText_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<EmailWhereInput>>;
  OR?: Maybe<Array<EmailWhereInput>>;
  NOT?: Maybe<Array<EmailWhereInput>>;
};

export type HiredOwner = {
  __typename?: 'HiredOwner';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type HiredProperty = IPropertyData & {
  __typename?: 'HiredProperty';
  id?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  renoworksId?: Maybe<Scalars['String']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  designCenterImageUrl?: Maybe<Scalars['String']>;
  viewerImageUrl?: Maybe<Scalars['String']>;
  newViewer?: Maybe<Scalars['Boolean']>;
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  owner?: Maybe<HiredOwner>;
};

export type Homeowner = {
  __typename?: 'Homeowner';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  purpose?: Maybe<HomeownerPurpose>;
  properties?: Maybe<Array<Property>>;
  hubspotStage?: Maybe<HubspotHomeownerStage>;
  user: User;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type HomeownerPropertiesArgs = {
  where?: Maybe<PropertyWhereInput>;
  orderBy?: Maybe<PropertyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HomeownerDeal = {
  __typename?: 'HomeownerDeal';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bidders?: Maybe<Array<LimitedDealBidder>>;
  image?: Maybe<Scalars['String']>;
  groupDiscountPercentage?: Maybe<Scalars['Int']>;
  frequency?: Maybe<DealFrequency>;
  joinCount?: Maybe<Scalars['Int']>;
  dealContract?: Maybe<LimitedDealContract>;
  landingUrl?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<LimitedDealContract>>;
};


export type HomeownerDealContractsArgs = {
  propertyId?: Maybe<Scalars['ID']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export enum HomeownerPurpose {
  Homeowner = 'HOMEOWNER',
  PropertyManager = 'PROPERTY_MANAGER',
  Realtor = 'REALTOR',
  HouseFlipper = 'HOUSE_FLIPPER'
}

export type HomeownerUserData = {
  __typename?: 'HomeownerUserData';
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type HomeownerWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  purpose?: Maybe<HomeownerPurpose>;
  purpose_not?: Maybe<HomeownerPurpose>;
  purpose_in?: Maybe<Array<HomeownerPurpose>>;
  purpose_not_in?: Maybe<Array<HomeownerPurpose>>;
  properties_every?: Maybe<PropertyWhereInput>;
  properties_some?: Maybe<PropertyWhereInput>;
  properties_none?: Maybe<PropertyWhereInput>;
  hubspotStage?: Maybe<HubspotHomeownerStage>;
  hubspotStage_not?: Maybe<HubspotHomeownerStage>;
  hubspotStage_in?: Maybe<Array<HubspotHomeownerStage>>;
  hubspotStage_not_in?: Maybe<Array<HubspotHomeownerStage>>;
  user?: Maybe<UserWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<HomeownerWhereInput>>;
  OR?: Maybe<Array<HomeownerWhereInput>>;
  NOT?: Maybe<Array<HomeownerWhereInput>>;
};

export enum HubspotContractorStage {
  Unsynced = 'UNSYNCED',
  Completed = 'COMPLETED',
  Won = 'WON',
  Bid = 'BID',
  Referred = 'REFERRED',
  New = 'NEW'
}

export enum HubspotHomeownerStage {
  Unsynced = 'UNSYNCED',
  Completed = 'COMPLETED',
  Hired = 'HIRED',
  Job = 'JOB',
  Property = 'PROPERTY',
  New = 'NEW'
}

export type IPropertyData = {
  id?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  renoworksId?: Maybe<Scalars['String']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  designCenterImageUrl?: Maybe<Scalars['String']>;
  viewerImageUrl?: Maybe<Scalars['String']>;
  newViewer?: Maybe<Scalars['Boolean']>;
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  title: Scalars['String'];
  description: Scalars['String'];
  selections?: Maybe<Array<Selection>>;
  trades?: Maybe<Array<Trade>>;
  pictures?: Maybe<Array<Picture>>;
  thumbnailSlug: Scalars['String'];
  iconSlug: Scalars['String'];
  property: Property;
  bids?: Maybe<Array<Bid>>;
  hiredBid?: Maybe<Bid>;
  hiredOn?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  completedOn?: Maybe<Scalars['DateTime']>;
  propertyMeasurements?: Maybe<Scalars['Json']>;
  contractorChargeAmount: Scalars['Float'];
  suggestedEmails?: Maybe<Array<SuggestedEmail>>;
  contractorProductSku: Scalars['String'];
  notifiedContractors: Array<Scalars['ID']>;
  dummyProperty: Scalars['Boolean'];
  bidCount?: Maybe<Scalars['Int']>;
};


export type JobSelectionsArgs = {
  where?: Maybe<SelectionWhereInput>;
  orderBy?: Maybe<SelectionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type JobTradesArgs = {
  where?: Maybe<TradeWhereInput>;
  orderBy?: Maybe<TradeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type JobPicturesArgs = {
  where?: Maybe<PictureWhereInput>;
  orderBy?: Maybe<PictureOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type JobBidsArgs = {
  where?: Maybe<BidWhereInput>;
  orderBy?: Maybe<BidOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type JobSuggestedEmailsArgs = {
  where?: Maybe<SuggestedEmailWhereInput>;
  orderBy?: Maybe<SuggestedEmailOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum JobOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ThumbnailSlugAsc = 'thumbnailSlug_ASC',
  ThumbnailSlugDesc = 'thumbnailSlug_DESC',
  IconSlugAsc = 'iconSlug_ASC',
  IconSlugDesc = 'iconSlug_DESC',
  HiredOnAsc = 'hiredOn_ASC',
  HiredOnDesc = 'hiredOn_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  CompletedOnAsc = 'completedOn_ASC',
  CompletedOnDesc = 'completedOn_DESC',
  PropertyMeasurementsAsc = 'propertyMeasurements_ASC',
  PropertyMeasurementsDesc = 'propertyMeasurements_DESC',
  ContractorChargeAmountAsc = 'contractorChargeAmount_ASC',
  ContractorChargeAmountDesc = 'contractorChargeAmount_DESC',
  ContractorProductSkuAsc = 'contractorProductSku_ASC',
  ContractorProductSkuDesc = 'contractorProductSku_DESC',
  DummyPropertyAsc = 'dummyProperty_ASC',
  DummyPropertyDesc = 'dummyProperty_DESC'
}

export type JobSpecification = {
  __typename?: 'JobSpecification';
  id: Scalars['ID'];
  title: Scalars['String'];
  contractorChargeAmount: Scalars['Float'];
  productSku: Scalars['String'];
  slug: Scalars['String'];
  icon: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type JobSpecificationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  contractorChargeAmount?: Maybe<Scalars['Float']>;
  contractorChargeAmount_not?: Maybe<Scalars['Float']>;
  contractorChargeAmount_in?: Maybe<Array<Scalars['Float']>>;
  contractorChargeAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  contractorChargeAmount_lt?: Maybe<Scalars['Float']>;
  contractorChargeAmount_lte?: Maybe<Scalars['Float']>;
  contractorChargeAmount_gt?: Maybe<Scalars['Float']>;
  contractorChargeAmount_gte?: Maybe<Scalars['Float']>;
  productSku?: Maybe<Scalars['String']>;
  productSku_not?: Maybe<Scalars['String']>;
  productSku_in?: Maybe<Array<Scalars['String']>>;
  productSku_not_in?: Maybe<Array<Scalars['String']>>;
  productSku_lt?: Maybe<Scalars['String']>;
  productSku_lte?: Maybe<Scalars['String']>;
  productSku_gt?: Maybe<Scalars['String']>;
  productSku_gte?: Maybe<Scalars['String']>;
  productSku_contains?: Maybe<Scalars['String']>;
  productSku_not_contains?: Maybe<Scalars['String']>;
  productSku_starts_with?: Maybe<Scalars['String']>;
  productSku_not_starts_with?: Maybe<Scalars['String']>;
  productSku_ends_with?: Maybe<Scalars['String']>;
  productSku_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Scalars['String']>>;
  icon_not_in?: Maybe<Array<Scalars['String']>>;
  icon_lt?: Maybe<Scalars['String']>;
  icon_lte?: Maybe<Scalars['String']>;
  icon_gt?: Maybe<Scalars['String']>;
  icon_gte?: Maybe<Scalars['String']>;
  icon_contains?: Maybe<Scalars['String']>;
  icon_not_contains?: Maybe<Scalars['String']>;
  icon_starts_with?: Maybe<Scalars['String']>;
  icon_not_starts_with?: Maybe<Scalars['String']>;
  icon_ends_with?: Maybe<Scalars['String']>;
  icon_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<JobSpecificationWhereInput>>;
  OR?: Maybe<Array<JobSpecificationWhereInput>>;
  NOT?: Maybe<Array<JobSpecificationWhereInput>>;
};

export enum JobStatus {
  Open = 'OPEN',
  Hired = 'HIRED',
  Completed = 'COMPLETED',
  Inactive = 'INACTIVE'
}

export type JobWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  selections_every?: Maybe<SelectionWhereInput>;
  selections_some?: Maybe<SelectionWhereInput>;
  selections_none?: Maybe<SelectionWhereInput>;
  trades_every?: Maybe<TradeWhereInput>;
  trades_some?: Maybe<TradeWhereInput>;
  trades_none?: Maybe<TradeWhereInput>;
  pictures_every?: Maybe<PictureWhereInput>;
  pictures_some?: Maybe<PictureWhereInput>;
  pictures_none?: Maybe<PictureWhereInput>;
  thumbnailSlug?: Maybe<Scalars['String']>;
  thumbnailSlug_not?: Maybe<Scalars['String']>;
  thumbnailSlug_in?: Maybe<Array<Scalars['String']>>;
  thumbnailSlug_not_in?: Maybe<Array<Scalars['String']>>;
  thumbnailSlug_lt?: Maybe<Scalars['String']>;
  thumbnailSlug_lte?: Maybe<Scalars['String']>;
  thumbnailSlug_gt?: Maybe<Scalars['String']>;
  thumbnailSlug_gte?: Maybe<Scalars['String']>;
  thumbnailSlug_contains?: Maybe<Scalars['String']>;
  thumbnailSlug_not_contains?: Maybe<Scalars['String']>;
  thumbnailSlug_starts_with?: Maybe<Scalars['String']>;
  thumbnailSlug_not_starts_with?: Maybe<Scalars['String']>;
  thumbnailSlug_ends_with?: Maybe<Scalars['String']>;
  thumbnailSlug_not_ends_with?: Maybe<Scalars['String']>;
  iconSlug?: Maybe<Scalars['String']>;
  iconSlug_not?: Maybe<Scalars['String']>;
  iconSlug_in?: Maybe<Array<Scalars['String']>>;
  iconSlug_not_in?: Maybe<Array<Scalars['String']>>;
  iconSlug_lt?: Maybe<Scalars['String']>;
  iconSlug_lte?: Maybe<Scalars['String']>;
  iconSlug_gt?: Maybe<Scalars['String']>;
  iconSlug_gte?: Maybe<Scalars['String']>;
  iconSlug_contains?: Maybe<Scalars['String']>;
  iconSlug_not_contains?: Maybe<Scalars['String']>;
  iconSlug_starts_with?: Maybe<Scalars['String']>;
  iconSlug_not_starts_with?: Maybe<Scalars['String']>;
  iconSlug_ends_with?: Maybe<Scalars['String']>;
  iconSlug_not_ends_with?: Maybe<Scalars['String']>;
  property?: Maybe<PropertyWhereInput>;
  bids_every?: Maybe<BidWhereInput>;
  bids_some?: Maybe<BidWhereInput>;
  bids_none?: Maybe<BidWhereInput>;
  hiredBid?: Maybe<BidWhereInput>;
  hiredOn?: Maybe<Scalars['DateTime']>;
  hiredOn_not?: Maybe<Scalars['DateTime']>;
  hiredOn_in?: Maybe<Array<Scalars['DateTime']>>;
  hiredOn_not_in?: Maybe<Array<Scalars['DateTime']>>;
  hiredOn_lt?: Maybe<Scalars['DateTime']>;
  hiredOn_lte?: Maybe<Scalars['DateTime']>;
  hiredOn_gt?: Maybe<Scalars['DateTime']>;
  hiredOn_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<JobStatus>;
  status_not?: Maybe<JobStatus>;
  status_in?: Maybe<Array<JobStatus>>;
  status_not_in?: Maybe<Array<JobStatus>>;
  completedOn?: Maybe<Scalars['DateTime']>;
  completedOn_not?: Maybe<Scalars['DateTime']>;
  completedOn_in?: Maybe<Array<Scalars['DateTime']>>;
  completedOn_not_in?: Maybe<Array<Scalars['DateTime']>>;
  completedOn_lt?: Maybe<Scalars['DateTime']>;
  completedOn_lte?: Maybe<Scalars['DateTime']>;
  completedOn_gt?: Maybe<Scalars['DateTime']>;
  completedOn_gte?: Maybe<Scalars['DateTime']>;
  contractorChargeAmount?: Maybe<Scalars['Float']>;
  contractorChargeAmount_not?: Maybe<Scalars['Float']>;
  contractorChargeAmount_in?: Maybe<Array<Scalars['Float']>>;
  contractorChargeAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  contractorChargeAmount_lt?: Maybe<Scalars['Float']>;
  contractorChargeAmount_lte?: Maybe<Scalars['Float']>;
  contractorChargeAmount_gt?: Maybe<Scalars['Float']>;
  contractorChargeAmount_gte?: Maybe<Scalars['Float']>;
  suggestedEmails_every?: Maybe<SuggestedEmailWhereInput>;
  suggestedEmails_some?: Maybe<SuggestedEmailWhereInput>;
  suggestedEmails_none?: Maybe<SuggestedEmailWhereInput>;
  contractorProductSku?: Maybe<Scalars['String']>;
  contractorProductSku_not?: Maybe<Scalars['String']>;
  contractorProductSku_in?: Maybe<Array<Scalars['String']>>;
  contractorProductSku_not_in?: Maybe<Array<Scalars['String']>>;
  contractorProductSku_lt?: Maybe<Scalars['String']>;
  contractorProductSku_lte?: Maybe<Scalars['String']>;
  contractorProductSku_gt?: Maybe<Scalars['String']>;
  contractorProductSku_gte?: Maybe<Scalars['String']>;
  contractorProductSku_contains?: Maybe<Scalars['String']>;
  contractorProductSku_not_contains?: Maybe<Scalars['String']>;
  contractorProductSku_starts_with?: Maybe<Scalars['String']>;
  contractorProductSku_not_starts_with?: Maybe<Scalars['String']>;
  contractorProductSku_ends_with?: Maybe<Scalars['String']>;
  contractorProductSku_not_ends_with?: Maybe<Scalars['String']>;
  dummyProperty?: Maybe<Scalars['Boolean']>;
  dummyProperty_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<JobWhereInput>>;
  OR?: Maybe<Array<JobWhereInput>>;
  NOT?: Maybe<Array<JobWhereInput>>;
};


export type LimitedContractor = {
  __typename?: 'LimitedContractor';
  id?: Maybe<Scalars['ID']>;
  avatar?: Maybe<ContractorAvatar>;
  companyName?: Maybe<Scalars['String']>;
  companyAdminName?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyBio?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
  secondaryWebsite?: Maybe<Scalars['String']>;
  workPictureUrls?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<LimitedContractorUser>;
};

export type LimitedContractorUser = {
  __typename?: 'LimitedContractorUser';
  id?: Maybe<Scalars['ID']>;
  rating?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
};

export type LimitedDealBidder = {
  __typename?: 'LimitedDealBidder';
  id?: Maybe<Scalars['ID']>;
  contractor?: Maybe<LimitedContractor>;
  priceInCents?: Maybe<Scalars['Int']>;
  priceText?: Maybe<Scalars['String']>;
  joinCount?: Maybe<Scalars['Int']>;
};

export type LimitedDealContract = {
  __typename?: 'LimitedDealContract';
  id?: Maybe<Scalars['ID']>;
  property?: Maybe<LimitedProperty>;
  date?: Maybe<Scalars['String']>;
  dateAccepted?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  dealBidder?: Maybe<LimitedDealBidder>;
  proposedBy?: Maybe<ProposedBy>;
  hasUnreadChatWithUser?: Maybe<Scalars['Boolean']>;
};

export type LimitedHomeowner = {
  __typename?: 'LimitedHomeowner';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  user?: Maybe<HomeownerUserData>;
};

export type LimitedProperty = {
  __typename?: 'LimitedProperty';
  id?: Maybe<Scalars['ID']>;
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  county?: Maybe<County>;
  owner?: Maybe<LimitedHomeowner>;
  renoworksId?: Maybe<Scalars['String']>;
  newViewer?: Maybe<Scalars['Boolean']>;
  ready?: Maybe<PropertyStatus>;
  propertySpec?: Maybe<PropertySpec>;
};

export type Line = {
  __typename?: 'Line';
  id: Scalars['ID'];
  refId: Scalars['String'];
  length: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum LineOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type LineWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  length_not?: Maybe<Scalars['Float']>;
  length_in?: Maybe<Array<Scalars['Float']>>;
  length_not_in?: Maybe<Array<Scalars['Float']>>;
  length_lt?: Maybe<Scalars['Float']>;
  length_lte?: Maybe<Scalars['Float']>;
  length_gt?: Maybe<Scalars['Float']>;
  length_gte?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<LineWhereInput>>;
  OR?: Maybe<Array<LineWhereInput>>;
  NOT?: Maybe<Array<LineWhereInput>>;
};

export type Modal = {
  __typename?: 'Modal';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  picture: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type ModalWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  picture_not?: Maybe<Scalars['String']>;
  picture_in?: Maybe<Array<Scalars['String']>>;
  picture_not_in?: Maybe<Array<Scalars['String']>>;
  picture_lt?: Maybe<Scalars['String']>;
  picture_lte?: Maybe<Scalars['String']>;
  picture_gt?: Maybe<Scalars['String']>;
  picture_gte?: Maybe<Scalars['String']>;
  picture_contains?: Maybe<Scalars['String']>;
  picture_not_contains?: Maybe<Scalars['String']>;
  picture_starts_with?: Maybe<Scalars['String']>;
  picture_not_starts_with?: Maybe<Scalars['String']>;
  picture_ends_with?: Maybe<Scalars['String']>;
  picture_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ModalWhereInput>>;
  OR?: Maybe<Array<ModalWhereInput>>;
  NOT?: Maybe<Array<ModalWhereInput>>;
};

export type ModelRelation = {
  __typename?: 'ModelRelation';
  id: Scalars['ID'];
  subOptionId?: Maybe<Scalars['String']>;
  specName3dModel?: Maybe<Spec>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type MonthlyCharge = {
  __typename?: 'MonthlyCharge';
  charges?: Maybe<Array<PropertyCharge>>;
  upcomingCharges?: Maybe<Array<PropertyCharge>>;
  hiredBidCharges?: Maybe<Array<BidCharge>>;
  pendingBidCharges?: Maybe<Array<BidCharge>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword?: Maybe<AuthPayload>;
  cleaHubspotSyncStatus?: Maybe<Scalars['Boolean']>;
  completeJob?: Maybe<Job>;
  completeJobContractor?: Maybe<ContractorJob>;
  createBid?: Maybe<ContractorBid>;
  markBidMessagesAsRead?: Maybe<Scalars['Boolean']>;
  changeBidAmount?: Maybe<ContractorBid>;
  createBidMessage?: Maybe<BidMessage>;
  createJob?: Maybe<Job>;
  createProperty?: Maybe<Property>;
  hireBid?: Maybe<Job>;
  login?: Maybe<AuthPayload>;
  markNotificationAsRead?: Maybe<Scalars['Int']>;
  parseXml?: Maybe<PropertySpec>;
  rejectBid?: Maybe<Job>;
  reopenJob?: Maybe<Job>;
  submitRating?: Maybe<Scalars['Boolean']>;
  savePaymentMethod?: Maybe<PaymentMethod>;
  setContractorAvatar?: Maybe<Contractor>;
  setContractorCounties?: Maybe<Contractor>;
  setContractorField?: Maybe<Contractor>;
  setHomeOwnerField?: Maybe<Homeowner>;
  setContractorPictures?: Maybe<Contractor>;
  signup?: Maybe<AuthPayload>;
  signupContractor?: Maybe<AuthPayloadContractor>;
  createDealBid?: Maybe<Contractor>;
  editDealBid?: Maybe<DealBidder>;
  completeDealContract?: Maybe<LimitedDealContract>;
  suggestJob?: Maybe<SuggestJobPayload>;
  sendOrderedMeasurementToClient?: Maybe<SuggestJobPayload>;
  updateContractorTrades?: Maybe<Contractor>;
  deleteJobPhoto?: Maybe<Job>;
  addJobPhotos?: Maybe<Job>;
  editJob?: Maybe<Job>;
  deleteJob?: Maybe<Scalars['Boolean']>;
  emailNotificationsToggle?: Maybe<User>;
  verifyUser?: Maybe<VerificationPayload>;
  addDealCode?: Maybe<Homeowner>;
  proposeDealContractDate?: Maybe<LimitedDealContract>;
  answerDealContractDate?: Maybe<LimitedDealContract>;
  joinDeal?: Maybe<Property>;
  leaveDeal?: Maybe<Property>;
  adminCancelProperty?: Maybe<Property>;
  adminCreateDeal?: Maybe<Deal>;
  adminCreatePromo?: Maybe<Promo>;
  adminTriggerRenoworksRequest?: Maybe<Scalars['Boolean']>;
  adminTriggerStripePaymentReCalc?: Maybe<Scalars['Boolean']>;
  adminSwitchSubscriptionManually?: Maybe<Scalars['Boolean']>;
  adminSetpromoCredits?: Maybe<Promo>;
  adminResendRenoworksRequest?: Maybe<Property>;
  syncContactsToHubspot?: Maybe<Scalars['String']>;
  adminDataMassager?: Maybe<Scalars['Boolean']>;
  migrateActiveJobs?: Maybe<Array<Scalars['String']>>;
  migratePaymentFrequency?: Maybe<Array<Scalars['String']>>;
  reParsePropertiesXml?: Maybe<Scalars['String']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  completeResetPassword?: Maybe<Scalars['Boolean']>;
  updatePushNotificationConfig?: Maybe<PushNotificationConfig>;
  updatePushTokens?: Maybe<UpdatePushTokenPayload>;
  request3dModel?: Maybe<Property>;
  updateOption?: Maybe<Option>;
  updateSubOption?: Maybe<SubOption>;
  signupJoinDeal?: Maybe<Scalars['Json']>;
};


export type MutationChangePasswordArgs = {
  email?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
};


export type MutationCompleteJobArgs = {
  jobId?: Maybe<Scalars['ID']>;
};


export type MutationCompleteJobContractorArgs = {
  jobId?: Maybe<Scalars['ID']>;
};


export type MutationCreateBidArgs = {
  amount?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['ID']>;
};


export type MutationMarkBidMessagesAsReadArgs = {
  bidChatId?: Maybe<Scalars['ID']>;
};


export type MutationChangeBidAmountArgs = {
  amount?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['ID']>;
  bidId?: Maybe<Scalars['ID']>;
};


export type MutationCreateBidMessageArgs = {
  text?: Maybe<Scalars['String']>;
  bidChatId: Scalars['ID'];
  bidId?: Maybe<Scalars['ID']>;
  dealId?: Maybe<Scalars['ID']>;
  dealContractId?: Maybe<Scalars['ID']>;
  picture?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
};


export type MutationCreateJobArgs = {
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  selections?: Maybe<Array<Scalars['String']>>;
  pictures?: Maybe<Array<Scalars['String']>>;
  propertyId?: Maybe<Scalars['ID']>;
  trades?: Maybe<Array<Scalars['ID']>>;
  propertyMeasurements?: Maybe<Scalars['Json']>;
  subOptionId: Scalars['ID'];
};


export type MutationCreatePropertyArgs = {
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  promoCodeId?: Maybe<Scalars['ID']>;
  intendedUserId?: Maybe<Scalars['ID']>;
};


export type MutationHireBidArgs = {
  bidId?: Maybe<Scalars['ID']>;
  jobId?: Maybe<Scalars['ID']>;
};


export type MutationLoginArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  oneTimeKey?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  appleToken?: Maybe<Scalars['String']>;
  appleNonce?: Maybe<Scalars['String']>;
};


export type MutationMarkNotificationAsReadArgs = {
  id?: Maybe<Scalars['ID']>;
  all?: Maybe<Scalars['Boolean']>;
};


export type MutationParseXmlArgs = {
  xmlFilePath: Scalars['String'];
  responseFilePath: Scalars['String'];
  propertyId?: Maybe<Scalars['ID']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
};


export type MutationRejectBidArgs = {
  jobId?: Maybe<Scalars['ID']>;
  bidId?: Maybe<Scalars['ID']>;
};


export type MutationReopenJobArgs = {
  jobId?: Maybe<Scalars['ID']>;
};


export type MutationSubmitRatingArgs = {
  code: Scalars['String'];
  ratingGiven: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationSavePaymentMethodArgs = {
  paymentMethodId?: Maybe<Scalars['ID']>;
  redactedCard?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  stripeSourceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type MutationSetContractorAvatarArgs = {
  url?: Maybe<Scalars['String']>;
};


export type MutationSetContractorCountiesArgs = {
  counties?: Maybe<Array<Scalars['ID']>>;
};


export type MutationSetContractorFieldArgs = {
  companyName?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyAdminName?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
  secondaryWebsite?: Maybe<Scalars['String']>;
  companyBio?: Maybe<Scalars['String']>;
};


export type MutationSetHomeOwnerFieldArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type MutationSetContractorPicturesArgs = {
  urlList: Array<Scalars['String']>;
};


export type MutationSignupArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  purpose?: Maybe<HomeownerPurpose>;
  skipEmail?: Maybe<Scalars['Boolean']>;
  partner?: Maybe<Partner>;
  partnerToken?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  oauth?: Maybe<Scalars['Boolean']>;
};


export type MutationSignupContractorArgs = {
  companyName?: Maybe<Scalars['String']>;
  companyAdminName?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  trades?: Maybe<Array<Scalars['ID']>>;
  counties?: Maybe<Array<Scalars['ID']>>;
  skipEmail?: Maybe<Scalars['Boolean']>;
  jobId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};


export type MutationCreateDealBidArgs = {
  contractorEmail?: Maybe<Scalars['String']>;
  priceInCents?: Maybe<Scalars['Int']>;
  priceText?: Maybe<Scalars['String']>;
  dealId?: Maybe<Scalars['ID']>;
};


export type MutationEditDealBidArgs = {
  dealBidderId?: Maybe<Scalars['ID']>;
  priceInCents?: Maybe<Scalars['Int']>;
  priceText?: Maybe<Scalars['String']>;
};


export type MutationCompleteDealContractArgs = {
  dealContractId?: Maybe<Scalars['ID']>;
};


export type MutationSuggestJobArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type MutationSendOrderedMeasurementToClientArgs = {
  email: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateContractorTradesArgs = {
  trades?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteJobPhotoArgs = {
  jobId?: Maybe<Scalars['ID']>;
  photoId?: Maybe<Scalars['ID']>;
};


export type MutationAddJobPhotosArgs = {
  jobId: Scalars['ID'];
  urls?: Maybe<Array<Scalars['String']>>;
};


export type MutationEditJobArgs = {
  jobId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationDeleteJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationVerifyUserArgs = {
  userId: Scalars['ID'];
  verificationCode: Scalars['String'];
};


export type MutationAddDealCodeArgs = {
  code?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['ID']>;
};


export type MutationProposeDealContractDateArgs = {
  dealContractId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
};


export type MutationAnswerDealContractDateArgs = {
  dealContractId?: Maybe<Scalars['ID']>;
  answer?: Maybe<Scalars['Boolean']>;
};


export type MutationJoinDealArgs = {
  dealBidderId?: Maybe<Scalars['ID']>;
  propertyId?: Maybe<Scalars['ID']>;
  proposedDate?: Maybe<Scalars['String']>;
};


export type MutationLeaveDealArgs = {
  dealBidderId?: Maybe<Scalars['ID']>;
  propertyId?: Maybe<Scalars['ID']>;
  dealContractId?: Maybe<Scalars['ID']>;
};


export type MutationAdminCancelPropertyArgs = {
  propertyId: Scalars['String'];
  updateStripe?: Maybe<Scalars['Boolean']>;
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>;
  prorate?: Maybe<Scalars['Boolean']>;
  doNotCharge?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminCreateDealArgs = {
  dealId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  zipCodes?: Maybe<Array<Scalars['String']>>;
  national?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Int']>;
  multiplierDescription?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  landingUrl?: Maybe<Scalars['String']>;
  frequency?: Maybe<DealFrequency>;
  visible?: Maybe<Scalars['Boolean']>;
  groupDiscountPercentage?: Maybe<Scalars['Int']>;
};


export type MutationAdminCreatePromoArgs = {
  code: Scalars['String'];
  zipCodes?: Maybe<Array<Scalars['String']>>;
  startingCredits: Scalars['Int'];
  userType: UserRole;
  expiresAt?: Maybe<Scalars['DateTime']>;
  percentageDiscount: Scalars['Int'];
};


export type MutationAdminTriggerRenoworksRequestArgs = {
  propertyId: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  scene_id?: Maybe<Scalars['String']>;
  surfaces_id?: Maybe<Scalars['String']>;
  disableNotifications?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminSwitchSubscriptionManuallyArgs = {
  subscriptionId: Scalars['String'];
  switchTo: Scalars['String'];
  invoiceImmediately?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminSetpromoCreditsArgs = {
  id: Scalars['ID'];
  credits: Scalars['Int'];
};


export type MutationAdminResendRenoworksRequestArgs = {
  propertyId: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCompleteResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdatePushNotificationConfigArgs = {
  chat?: Maybe<Scalars['Boolean']>;
  biddableJobs?: Maybe<Scalars['Boolean']>;
  bidActivity?: Maybe<Scalars['Boolean']>;
  newsAndUpdates?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePushTokensArgs = {
  pushToken: Scalars['String'];
  remove?: Maybe<Scalars['Boolean']>;
};


export type MutationRequest3dModelArgs = {
  propertyId: Scalars['ID'];
  promoCode?: Maybe<Scalars['String']>;
};


export type MutationUpdateOptionArgs = {
  id: Scalars['String'];
  description: Scalars['String'];
};


export type MutationUpdateSubOptionArgs = {
  id: Scalars['String'];
  description: Scalars['String'];
};


export type MutationSignupJoinDealArgs = {
  dealBidderId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  proposedDate?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  read: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  appLink?: Maybe<AppLink>;
};

export enum NotificationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  ReadAsc = 'read_ASC',
  ReadDesc = 'read_DESC',
  SendEmailAsc = 'sendEmail_ASC',
  SendEmailDesc = 'sendEmail_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  ExternalEmailAddressAsc = 'externalEmailAddress_ASC',
  ExternalEmailAddressDesc = 'externalEmailAddress_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type NotificationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  email?: Maybe<EmailWhereInput>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  read_not?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendEmail_not?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  link_not?: Maybe<Scalars['String']>;
  link_in?: Maybe<Array<Scalars['String']>>;
  link_not_in?: Maybe<Array<Scalars['String']>>;
  link_lt?: Maybe<Scalars['String']>;
  link_lte?: Maybe<Scalars['String']>;
  link_gt?: Maybe<Scalars['String']>;
  link_gte?: Maybe<Scalars['String']>;
  link_contains?: Maybe<Scalars['String']>;
  link_not_contains?: Maybe<Scalars['String']>;
  link_starts_with?: Maybe<Scalars['String']>;
  link_not_starts_with?: Maybe<Scalars['String']>;
  link_ends_with?: Maybe<Scalars['String']>;
  link_not_ends_with?: Maybe<Scalars['String']>;
  appLink?: Maybe<AppLinkWhereInput>;
  user?: Maybe<UserWhereInput>;
  externalEmailAddress?: Maybe<Scalars['String']>;
  externalEmailAddress_not?: Maybe<Scalars['String']>;
  externalEmailAddress_in?: Maybe<Array<Scalars['String']>>;
  externalEmailAddress_not_in?: Maybe<Array<Scalars['String']>>;
  externalEmailAddress_lt?: Maybe<Scalars['String']>;
  externalEmailAddress_lte?: Maybe<Scalars['String']>;
  externalEmailAddress_gt?: Maybe<Scalars['String']>;
  externalEmailAddress_gte?: Maybe<Scalars['String']>;
  externalEmailAddress_contains?: Maybe<Scalars['String']>;
  externalEmailAddress_not_contains?: Maybe<Scalars['String']>;
  externalEmailAddress_starts_with?: Maybe<Scalars['String']>;
  externalEmailAddress_not_starts_with?: Maybe<Scalars['String']>;
  externalEmailAddress_ends_with?: Maybe<Scalars['String']>;
  externalEmailAddress_not_ends_with?: Maybe<Scalars['String']>;
  bidChat?: Maybe<BidChatWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  description: Scalars['String'];
  subOptions?: Maybe<Array<SubOption>>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type OptionSubOptionsArgs = {
  where?: Maybe<SubOptionWhereInput>;
  orderBy?: Maybe<SubOptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OptionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  subOptions_every?: Maybe<SubOptionWhereInput>;
  subOptions_some?: Maybe<SubOptionWhereInput>;
  subOptions_none?: Maybe<SubOptionWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OptionWhereInput>>;
  OR?: Maybe<Array<OptionWhereInput>>;
  NOT?: Maybe<Array<OptionWhereInput>>;
};

export type OrderedMeasurement = {
  __typename?: 'OrderedMeasurement';
  id: Scalars['ID'];
  contractor: Contractor;
  property: Property;
  invitedEmails: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum OrderedMeasurementOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type OrderedMeasurementProperty = IPropertyData & {
  __typename?: 'OrderedMeasurementProperty';
  id?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  renoworksId?: Maybe<Scalars['String']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  designCenterImageUrl?: Maybe<Scalars['String']>;
  viewerImageUrl?: Maybe<Scalars['String']>;
  newViewer?: Maybe<Scalars['Boolean']>;
  renoworksDisplayId?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  ready?: Maybe<PropertyStatus>;
  propertySpec?: Maybe<PropertySpec>;
};

export type OrderedMeasurementWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  contractor?: Maybe<ContractorWhereInput>;
  property?: Maybe<PropertyWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderedMeasurementWhereInput>>;
  OR?: Maybe<Array<OrderedMeasurementWhereInput>>;
  NOT?: Maybe<Array<OrderedMeasurementWhereInput>>;
};

export enum OrderStatus {
  Created = 'CREATED',
  Paid = 'PAID',
  Fulfilled = 'FULFILLED',
  Canceled = 'CANCELED'
}

export type OrderWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  stripeOrderId?: Maybe<Scalars['String']>;
  stripeOrderId_not?: Maybe<Scalars['String']>;
  stripeOrderId_in?: Maybe<Array<Scalars['String']>>;
  stripeOrderId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeOrderId_lt?: Maybe<Scalars['String']>;
  stripeOrderId_lte?: Maybe<Scalars['String']>;
  stripeOrderId_gt?: Maybe<Scalars['String']>;
  stripeOrderId_gte?: Maybe<Scalars['String']>;
  stripeOrderId_contains?: Maybe<Scalars['String']>;
  stripeOrderId_not_contains?: Maybe<Scalars['String']>;
  stripeOrderId_starts_with?: Maybe<Scalars['String']>;
  stripeOrderId_not_starts_with?: Maybe<Scalars['String']>;
  stripeOrderId_ends_with?: Maybe<Scalars['String']>;
  stripeOrderId_not_ends_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not?: Maybe<Scalars['String']>;
  stripePaymentIntentId_in?: Maybe<Array<Scalars['String']>>;
  stripePaymentIntentId_not_in?: Maybe<Array<Scalars['String']>>;
  stripePaymentIntentId_lt?: Maybe<Scalars['String']>;
  stripePaymentIntentId_lte?: Maybe<Scalars['String']>;
  stripePaymentIntentId_gt?: Maybe<Scalars['String']>;
  stripePaymentIntentId_gte?: Maybe<Scalars['String']>;
  stripePaymentIntentId_contains?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not_contains?: Maybe<Scalars['String']>;
  stripePaymentIntentId_starts_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not_starts_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId_ends_with?: Maybe<Scalars['String']>;
  stripePaymentIntentId_not_ends_with?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  status_not?: Maybe<OrderStatus>;
  status_in?: Maybe<Array<OrderStatus>>;
  status_not_in?: Maybe<Array<OrderStatus>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Currency>;
  amountCurrency_not?: Maybe<Currency>;
  amountCurrency_in?: Maybe<Array<Currency>>;
  amountCurrency_not_in?: Maybe<Array<Currency>>;
  fulfilledDate?: Maybe<Scalars['DateTime']>;
  fulfilledDate_not?: Maybe<Scalars['DateTime']>;
  fulfilledDate_in?: Maybe<Array<Scalars['DateTime']>>;
  fulfilledDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  fulfilledDate_lt?: Maybe<Scalars['DateTime']>;
  fulfilledDate_lte?: Maybe<Scalars['DateTime']>;
  fulfilledDate_gt?: Maybe<Scalars['DateTime']>;
  fulfilledDate_gte?: Maybe<Scalars['DateTime']>;
  charge?: Maybe<ChargeWhereInput>;
  promoUsed?: Maybe<PromoWhereInput>;
  user?: Maybe<UserWhereInput>;
  property?: Maybe<PropertyWhereInput>;
  bid?: Maybe<BidWhereInput>;
  AND?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
};

export enum Partner {
  Tsq = 'TSQ'
}

export enum PartnerName {
  Tsq = 'TSQ'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  user: User;
  redactedCard: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  stripeSourceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  isDefault: Scalars['Boolean'];
  charges?: Maybe<Array<Charge>>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type PaymentMethodChargesArgs = {
  where?: Maybe<ChargeWhereInput>;
  orderBy?: Maybe<ChargeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum PaymentMethodOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RedactedCardAsc = 'redactedCard_ASC',
  RedactedCardDesc = 'redactedCard_DESC',
  ExpMonthAsc = 'expMonth_ASC',
  ExpMonthDesc = 'expMonth_DESC',
  ExpYearAsc = 'expYear_ASC',
  ExpYearDesc = 'expYear_DESC',
  StripeSourceIdAsc = 'stripeSourceId_ASC',
  StripeSourceIdDesc = 'stripeSourceId_DESC',
  StripePaymentMethodIdAsc = 'stripePaymentMethodId_ASC',
  StripePaymentMethodIdDesc = 'stripePaymentMethodId_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  AddressLine1Asc = 'addressLine1_ASC',
  AddressLine1Desc = 'addressLine1_DESC',
  AddressLine2Asc = 'addressLine2_ASC',
  AddressLine2Desc = 'addressLine2_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
  IsDefaultAsc = 'isDefault_ASC',
  IsDefaultDesc = 'isDefault_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type PaymentMethodWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  user?: Maybe<UserWhereInput>;
  redactedCard?: Maybe<Scalars['String']>;
  redactedCard_not?: Maybe<Scalars['String']>;
  redactedCard_in?: Maybe<Array<Scalars['String']>>;
  redactedCard_not_in?: Maybe<Array<Scalars['String']>>;
  redactedCard_lt?: Maybe<Scalars['String']>;
  redactedCard_lte?: Maybe<Scalars['String']>;
  redactedCard_gt?: Maybe<Scalars['String']>;
  redactedCard_gte?: Maybe<Scalars['String']>;
  redactedCard_contains?: Maybe<Scalars['String']>;
  redactedCard_not_contains?: Maybe<Scalars['String']>;
  redactedCard_starts_with?: Maybe<Scalars['String']>;
  redactedCard_not_starts_with?: Maybe<Scalars['String']>;
  redactedCard_ends_with?: Maybe<Scalars['String']>;
  redactedCard_not_ends_with?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expMonth_not?: Maybe<Scalars['Int']>;
  expMonth_in?: Maybe<Array<Scalars['Int']>>;
  expMonth_not_in?: Maybe<Array<Scalars['Int']>>;
  expMonth_lt?: Maybe<Scalars['Int']>;
  expMonth_lte?: Maybe<Scalars['Int']>;
  expMonth_gt?: Maybe<Scalars['Int']>;
  expMonth_gte?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  expYear_not?: Maybe<Scalars['Int']>;
  expYear_in?: Maybe<Array<Scalars['Int']>>;
  expYear_not_in?: Maybe<Array<Scalars['Int']>>;
  expYear_lt?: Maybe<Scalars['Int']>;
  expYear_lte?: Maybe<Scalars['Int']>;
  expYear_gt?: Maybe<Scalars['Int']>;
  expYear_gte?: Maybe<Scalars['Int']>;
  stripeSourceId?: Maybe<Scalars['String']>;
  stripeSourceId_not?: Maybe<Scalars['String']>;
  stripeSourceId_in?: Maybe<Array<Scalars['String']>>;
  stripeSourceId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeSourceId_lt?: Maybe<Scalars['String']>;
  stripeSourceId_lte?: Maybe<Scalars['String']>;
  stripeSourceId_gt?: Maybe<Scalars['String']>;
  stripeSourceId_gte?: Maybe<Scalars['String']>;
  stripeSourceId_contains?: Maybe<Scalars['String']>;
  stripeSourceId_not_contains?: Maybe<Scalars['String']>;
  stripeSourceId_starts_with?: Maybe<Scalars['String']>;
  stripeSourceId_not_starts_with?: Maybe<Scalars['String']>;
  stripeSourceId_ends_with?: Maybe<Scalars['String']>;
  stripeSourceId_not_ends_with?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentMethodId_not?: Maybe<Scalars['String']>;
  stripePaymentMethodId_in?: Maybe<Array<Scalars['String']>>;
  stripePaymentMethodId_not_in?: Maybe<Array<Scalars['String']>>;
  stripePaymentMethodId_lt?: Maybe<Scalars['String']>;
  stripePaymentMethodId_lte?: Maybe<Scalars['String']>;
  stripePaymentMethodId_gt?: Maybe<Scalars['String']>;
  stripePaymentMethodId_gte?: Maybe<Scalars['String']>;
  stripePaymentMethodId_contains?: Maybe<Scalars['String']>;
  stripePaymentMethodId_not_contains?: Maybe<Scalars['String']>;
  stripePaymentMethodId_starts_with?: Maybe<Scalars['String']>;
  stripePaymentMethodId_not_starts_with?: Maybe<Scalars['String']>;
  stripePaymentMethodId_ends_with?: Maybe<Scalars['String']>;
  stripePaymentMethodId_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine1_not?: Maybe<Scalars['String']>;
  addressLine1_in?: Maybe<Array<Scalars['String']>>;
  addressLine1_not_in?: Maybe<Array<Scalars['String']>>;
  addressLine1_lt?: Maybe<Scalars['String']>;
  addressLine1_lte?: Maybe<Scalars['String']>;
  addressLine1_gt?: Maybe<Scalars['String']>;
  addressLine1_gte?: Maybe<Scalars['String']>;
  addressLine1_contains?: Maybe<Scalars['String']>;
  addressLine1_not_contains?: Maybe<Scalars['String']>;
  addressLine1_starts_with?: Maybe<Scalars['String']>;
  addressLine1_not_starts_with?: Maybe<Scalars['String']>;
  addressLine1_ends_with?: Maybe<Scalars['String']>;
  addressLine1_not_ends_with?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine2_not?: Maybe<Scalars['String']>;
  addressLine2_in?: Maybe<Array<Scalars['String']>>;
  addressLine2_not_in?: Maybe<Array<Scalars['String']>>;
  addressLine2_lt?: Maybe<Scalars['String']>;
  addressLine2_lte?: Maybe<Scalars['String']>;
  addressLine2_gt?: Maybe<Scalars['String']>;
  addressLine2_gte?: Maybe<Scalars['String']>;
  addressLine2_contains?: Maybe<Scalars['String']>;
  addressLine2_not_contains?: Maybe<Scalars['String']>;
  addressLine2_starts_with?: Maybe<Scalars['String']>;
  addressLine2_not_starts_with?: Maybe<Scalars['String']>;
  addressLine2_ends_with?: Maybe<Scalars['String']>;
  addressLine2_not_ends_with?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_not?: Maybe<Scalars['String']>;
  city_in?: Maybe<Array<Scalars['String']>>;
  city_not_in?: Maybe<Array<Scalars['String']>>;
  city_lt?: Maybe<Scalars['String']>;
  city_lte?: Maybe<Scalars['String']>;
  city_gt?: Maybe<Scalars['String']>;
  city_gte?: Maybe<Scalars['String']>;
  city_contains?: Maybe<Scalars['String']>;
  city_not_contains?: Maybe<Scalars['String']>;
  city_starts_with?: Maybe<Scalars['String']>;
  city_not_starts_with?: Maybe<Scalars['String']>;
  city_ends_with?: Maybe<Scalars['String']>;
  city_not_ends_with?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_not?: Maybe<Scalars['String']>;
  state_in?: Maybe<Array<Scalars['String']>>;
  state_not_in?: Maybe<Array<Scalars['String']>>;
  state_lt?: Maybe<Scalars['String']>;
  state_lte?: Maybe<Scalars['String']>;
  state_gt?: Maybe<Scalars['String']>;
  state_gte?: Maybe<Scalars['String']>;
  state_contains?: Maybe<Scalars['String']>;
  state_not_contains?: Maybe<Scalars['String']>;
  state_starts_with?: Maybe<Scalars['String']>;
  state_not_starts_with?: Maybe<Scalars['String']>;
  state_ends_with?: Maybe<Scalars['String']>;
  state_not_ends_with?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  zipCode_not?: Maybe<Scalars['String']>;
  zipCode_in?: Maybe<Array<Scalars['String']>>;
  zipCode_not_in?: Maybe<Array<Scalars['String']>>;
  zipCode_lt?: Maybe<Scalars['String']>;
  zipCode_lte?: Maybe<Scalars['String']>;
  zipCode_gt?: Maybe<Scalars['String']>;
  zipCode_gte?: Maybe<Scalars['String']>;
  zipCode_contains?: Maybe<Scalars['String']>;
  zipCode_not_contains?: Maybe<Scalars['String']>;
  zipCode_starts_with?: Maybe<Scalars['String']>;
  zipCode_not_starts_with?: Maybe<Scalars['String']>;
  zipCode_ends_with?: Maybe<Scalars['String']>;
  zipCode_not_ends_with?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_not?: Maybe<Scalars['Boolean']>;
  charges_every?: Maybe<ChargeWhereInput>;
  charges_some?: Maybe<ChargeWhereInput>;
  charges_none?: Maybe<ChargeWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PaymentMethodWhereInput>>;
  OR?: Maybe<Array<PaymentMethodWhereInput>>;
  NOT?: Maybe<Array<PaymentMethodWhereInput>>;
};

export type Picture = {
  __typename?: 'Picture';
  id: Scalars['ID'];
  url: Scalars['String'];
  mime?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum PictureOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  MimeAsc = 'mime_ASC',
  MimeDesc = 'mime_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type PictureWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Scalars['String']>>;
  url_not_in?: Maybe<Array<Scalars['String']>>;
  url_lt?: Maybe<Scalars['String']>;
  url_lte?: Maybe<Scalars['String']>;
  url_gt?: Maybe<Scalars['String']>;
  url_gte?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  mime_not?: Maybe<Scalars['String']>;
  mime_in?: Maybe<Array<Scalars['String']>>;
  mime_not_in?: Maybe<Array<Scalars['String']>>;
  mime_lt?: Maybe<Scalars['String']>;
  mime_lte?: Maybe<Scalars['String']>;
  mime_gt?: Maybe<Scalars['String']>;
  mime_gte?: Maybe<Scalars['String']>;
  mime_contains?: Maybe<Scalars['String']>;
  mime_not_contains?: Maybe<Scalars['String']>;
  mime_starts_with?: Maybe<Scalars['String']>;
  mime_not_starts_with?: Maybe<Scalars['String']>;
  mime_ends_with?: Maybe<Scalars['String']>;
  mime_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PictureWhereInput>>;
  OR?: Maybe<Array<PictureWhereInput>>;
  NOT?: Maybe<Array<PictureWhereInput>>;
};

export type Porch = {
  __typename?: 'Porch';
  id: Scalars['ID'];
  refId: Scalars['String'];
  area?: Maybe<Scalars['Float']>;
  structure: Structure;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum PorchOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type PorchWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  area_not?: Maybe<Scalars['Float']>;
  area_in?: Maybe<Array<Scalars['Float']>>;
  area_not_in?: Maybe<Array<Scalars['Float']>>;
  area_lt?: Maybe<Scalars['Float']>;
  area_lte?: Maybe<Scalars['Float']>;
  area_gt?: Maybe<Scalars['Float']>;
  area_gte?: Maybe<Scalars['Float']>;
  structure?: Maybe<StructureWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PorchWhereInput>>;
  OR?: Maybe<Array<PorchWhereInput>>;
  NOT?: Maybe<Array<PorchWhereInput>>;
};

export type Promo = {
  __typename?: 'Promo';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  code: Scalars['String'];
  userType: UserRole;
  expiresAt?: Maybe<Scalars['DateTime']>;
  startingCredits: Scalars['Int'];
  creditsLeft: Scalars['Int'];
  zipCodes: Array<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
};

export type PromoWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  userType?: Maybe<UserRole>;
  userType_not?: Maybe<UserRole>;
  userType_in?: Maybe<Array<UserRole>>;
  userType_not_in?: Maybe<Array<UserRole>>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  expiresAt_not?: Maybe<Scalars['DateTime']>;
  expiresAt_in?: Maybe<Array<Scalars['DateTime']>>;
  expiresAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  expiresAt_lt?: Maybe<Scalars['DateTime']>;
  expiresAt_lte?: Maybe<Scalars['DateTime']>;
  expiresAt_gt?: Maybe<Scalars['DateTime']>;
  expiresAt_gte?: Maybe<Scalars['DateTime']>;
  startingCredits?: Maybe<Scalars['Int']>;
  startingCredits_not?: Maybe<Scalars['Int']>;
  startingCredits_in?: Maybe<Array<Scalars['Int']>>;
  startingCredits_not_in?: Maybe<Array<Scalars['Int']>>;
  startingCredits_lt?: Maybe<Scalars['Int']>;
  startingCredits_lte?: Maybe<Scalars['Int']>;
  startingCredits_gt?: Maybe<Scalars['Int']>;
  startingCredits_gte?: Maybe<Scalars['Int']>;
  creditsLeft?: Maybe<Scalars['Int']>;
  creditsLeft_not?: Maybe<Scalars['Int']>;
  creditsLeft_in?: Maybe<Array<Scalars['Int']>>;
  creditsLeft_not_in?: Maybe<Array<Scalars['Int']>>;
  creditsLeft_lt?: Maybe<Scalars['Int']>;
  creditsLeft_lte?: Maybe<Scalars['Int']>;
  creditsLeft_gt?: Maybe<Scalars['Int']>;
  creditsLeft_gte?: Maybe<Scalars['Int']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  percentageDiscount_not?: Maybe<Scalars['Int']>;
  percentageDiscount_in?: Maybe<Array<Scalars['Int']>>;
  percentageDiscount_not_in?: Maybe<Array<Scalars['Int']>>;
  percentageDiscount_lt?: Maybe<Scalars['Int']>;
  percentageDiscount_lte?: Maybe<Scalars['Int']>;
  percentageDiscount_gt?: Maybe<Scalars['Int']>;
  percentageDiscount_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<PromoWhereInput>>;
  OR?: Maybe<Array<PromoWhereInput>>;
  NOT?: Maybe<Array<PromoWhereInput>>;
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['ID'];
  houseNumber: Scalars['String'];
  streetName: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  county: County;
  zipCode: Scalars['String'];
  owner?: Maybe<Homeowner>;
  designCenterImageUrl?: Maybe<Scalars['String']>;
  ready: PropertyStatus;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  propertySpec?: Maybe<PropertySpec>;
  newViewer?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  renoworksId?: Maybe<Scalars['String']>;
  renoworksDisplayId?: Maybe<Scalars['String']>;
  jobs?: Maybe<Array<Job>>;
};


export type PropertyJobsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<JobOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<JobWhereInput>;
};

export type PropertyCharge = {
  __typename?: 'PropertyCharge';
  id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  /** @deprecated Prefer "amountInCents" over "amount". */
  amount?: Maybe<Scalars['Int']>;
  amountInCents?: Maybe<Scalars['Int']>;
  period?: Maybe<Scalars['String']>;
  displayStatus?: Maybe<Scalars['String']>;
};

export enum PropertyOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HouseNumberAsc = 'houseNumber_ASC',
  HouseNumberDesc = 'houseNumber_DESC',
  StreetNameAsc = 'streetName_ASC',
  StreetNameDesc = 'streetName_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
  LatAsc = 'lat_ASC',
  LatDesc = 'lat_DESC',
  LngAsc = 'lng_ASC',
  LngDesc = 'lng_DESC',
  ReadyAsc = 'ready_ASC',
  ReadyDesc = 'ready_DESC',
  RenoworksIdAsc = 'renoworksId_ASC',
  RenoworksIdDesc = 'renoworksId_DESC',
  RenoworksDisplayIdAsc = 'renoworksDisplayId_ASC',
  RenoworksDisplayIdDesc = 'renoworksDisplayId_DESC',
  RenoworksRequestIdAsc = 'renoworksRequestId_ASC',
  RenoworksRequestIdDesc = 'renoworksRequestId_DESC',
  LegacyHomeSpecIdAsc = 'legacyHomeSpecId_ASC',
  LegacyHomeSpecIdDesc = 'legacyHomeSpecId_DESC',
  GeomniUrlAsc = 'geomniUrl_ASC',
  GeomniUrlDesc = 'geomniUrl_DESC',
  DesignCenterImageUrlAsc = 'designCenterImageUrl_ASC',
  DesignCenterImageUrlDesc = 'designCenterImageUrl_DESC',
  ViewerImageUrlAsc = 'viewerImageUrl_ASC',
  ViewerImageUrlDesc = 'viewerImageUrl_DESC',
  NewViewerAsc = 'newViewer_ASC',
  NewViewerDesc = 'newViewer_DESC',
  SubscriptionPlanAsc = 'subscriptionPlan_ASC',
  SubscriptionPlanDesc = 'subscriptionPlan_DESC',
  StripeSubscriptionIdAsc = 'stripeSubscriptionId_ASC',
  StripeSubscriptionIdDesc = 'stripeSubscriptionId_DESC',
  FreePropertyAsc = 'freeProperty_ASC',
  FreePropertyDesc = 'freeProperty_DESC',
  PaymentFrequencyAsc = 'paymentFrequency_ASC',
  PaymentFrequencyDesc = 'paymentFrequency_DESC',
  PaymentAmountAsc = 'paymentAmount_ASC',
  PaymentAmountDesc = 'paymentAmount_DESC',
  StripeSubscriptionPlanIdAsc = 'stripeSubscriptionPlanId_ASC',
  StripeSubscriptionPlanIdDesc = 'stripeSubscriptionPlanId_DESC'
}

export enum PropertyPaymentFrequency {
  Free = 'FREE',
  Subscription = 'SUBSCRIPTION',
  OneTime = 'ONE_TIME'
}

export type PropertySpec = {
  __typename?: 'PropertySpec';
  id: Scalars['ID'];
  predominantRoofPitch?: Maybe<Scalars['String']>;
  renoworksId?: Maybe<Scalars['String']>;
  renoworksDisplayId?: Maybe<Scalars['String']>;
  hipLength?: Maybe<Scalars['Float']>;
  ridgeLength?: Maybe<Scalars['Float']>;
  rakeLength?: Maybe<Scalars['Float']>;
  eaveLength?: Maybe<Scalars['Float']>;
  valleyLength?: Maybe<Scalars['Float']>;
  stepFlashLength?: Maybe<Scalars['Float']>;
  flashLength?: Maybe<Scalars['Float']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  structures?: Maybe<Array<Structure>>;
  property?: Maybe<Property>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type PropertySpecStructuresArgs = {
  where?: Maybe<StructureWhereInput>;
  orderBy?: Maybe<StructureOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PropertySpecWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  predominantRoofPitch?: Maybe<Scalars['String']>;
  predominantRoofPitch_not?: Maybe<Scalars['String']>;
  predominantRoofPitch_in?: Maybe<Array<Scalars['String']>>;
  predominantRoofPitch_not_in?: Maybe<Array<Scalars['String']>>;
  predominantRoofPitch_lt?: Maybe<Scalars['String']>;
  predominantRoofPitch_lte?: Maybe<Scalars['String']>;
  predominantRoofPitch_gt?: Maybe<Scalars['String']>;
  predominantRoofPitch_gte?: Maybe<Scalars['String']>;
  predominantRoofPitch_contains?: Maybe<Scalars['String']>;
  predominantRoofPitch_not_contains?: Maybe<Scalars['String']>;
  predominantRoofPitch_starts_with?: Maybe<Scalars['String']>;
  predominantRoofPitch_not_starts_with?: Maybe<Scalars['String']>;
  predominantRoofPitch_ends_with?: Maybe<Scalars['String']>;
  predominantRoofPitch_not_ends_with?: Maybe<Scalars['String']>;
  renoworksId?: Maybe<Scalars['String']>;
  renoworksId_not?: Maybe<Scalars['String']>;
  renoworksId_in?: Maybe<Array<Scalars['String']>>;
  renoworksId_not_in?: Maybe<Array<Scalars['String']>>;
  renoworksId_lt?: Maybe<Scalars['String']>;
  renoworksId_lte?: Maybe<Scalars['String']>;
  renoworksId_gt?: Maybe<Scalars['String']>;
  renoworksId_gte?: Maybe<Scalars['String']>;
  renoworksId_contains?: Maybe<Scalars['String']>;
  renoworksId_not_contains?: Maybe<Scalars['String']>;
  renoworksId_starts_with?: Maybe<Scalars['String']>;
  renoworksId_not_starts_with?: Maybe<Scalars['String']>;
  renoworksId_ends_with?: Maybe<Scalars['String']>;
  renoworksId_not_ends_with?: Maybe<Scalars['String']>;
  renoworksDisplayId?: Maybe<Scalars['String']>;
  renoworksDisplayId_not?: Maybe<Scalars['String']>;
  renoworksDisplayId_in?: Maybe<Array<Scalars['String']>>;
  renoworksDisplayId_not_in?: Maybe<Array<Scalars['String']>>;
  renoworksDisplayId_lt?: Maybe<Scalars['String']>;
  renoworksDisplayId_lte?: Maybe<Scalars['String']>;
  renoworksDisplayId_gt?: Maybe<Scalars['String']>;
  renoworksDisplayId_gte?: Maybe<Scalars['String']>;
  renoworksDisplayId_contains?: Maybe<Scalars['String']>;
  renoworksDisplayId_not_contains?: Maybe<Scalars['String']>;
  renoworksDisplayId_starts_with?: Maybe<Scalars['String']>;
  renoworksDisplayId_not_starts_with?: Maybe<Scalars['String']>;
  renoworksDisplayId_ends_with?: Maybe<Scalars['String']>;
  renoworksDisplayId_not_ends_with?: Maybe<Scalars['String']>;
  hipLength?: Maybe<Scalars['Float']>;
  hipLength_not?: Maybe<Scalars['Float']>;
  hipLength_in?: Maybe<Array<Scalars['Float']>>;
  hipLength_not_in?: Maybe<Array<Scalars['Float']>>;
  hipLength_lt?: Maybe<Scalars['Float']>;
  hipLength_lte?: Maybe<Scalars['Float']>;
  hipLength_gt?: Maybe<Scalars['Float']>;
  hipLength_gte?: Maybe<Scalars['Float']>;
  ridgeLength?: Maybe<Scalars['Float']>;
  ridgeLength_not?: Maybe<Scalars['Float']>;
  ridgeLength_in?: Maybe<Array<Scalars['Float']>>;
  ridgeLength_not_in?: Maybe<Array<Scalars['Float']>>;
  ridgeLength_lt?: Maybe<Scalars['Float']>;
  ridgeLength_lte?: Maybe<Scalars['Float']>;
  ridgeLength_gt?: Maybe<Scalars['Float']>;
  ridgeLength_gte?: Maybe<Scalars['Float']>;
  rakeLength?: Maybe<Scalars['Float']>;
  rakeLength_not?: Maybe<Scalars['Float']>;
  rakeLength_in?: Maybe<Array<Scalars['Float']>>;
  rakeLength_not_in?: Maybe<Array<Scalars['Float']>>;
  rakeLength_lt?: Maybe<Scalars['Float']>;
  rakeLength_lte?: Maybe<Scalars['Float']>;
  rakeLength_gt?: Maybe<Scalars['Float']>;
  rakeLength_gte?: Maybe<Scalars['Float']>;
  eaveLength?: Maybe<Scalars['Float']>;
  eaveLength_not?: Maybe<Scalars['Float']>;
  eaveLength_in?: Maybe<Array<Scalars['Float']>>;
  eaveLength_not_in?: Maybe<Array<Scalars['Float']>>;
  eaveLength_lt?: Maybe<Scalars['Float']>;
  eaveLength_lte?: Maybe<Scalars['Float']>;
  eaveLength_gt?: Maybe<Scalars['Float']>;
  eaveLength_gte?: Maybe<Scalars['Float']>;
  valleyLength?: Maybe<Scalars['Float']>;
  valleyLength_not?: Maybe<Scalars['Float']>;
  valleyLength_in?: Maybe<Array<Scalars['Float']>>;
  valleyLength_not_in?: Maybe<Array<Scalars['Float']>>;
  valleyLength_lt?: Maybe<Scalars['Float']>;
  valleyLength_lte?: Maybe<Scalars['Float']>;
  valleyLength_gt?: Maybe<Scalars['Float']>;
  valleyLength_gte?: Maybe<Scalars['Float']>;
  stepFlashLength?: Maybe<Scalars['Float']>;
  stepFlashLength_not?: Maybe<Scalars['Float']>;
  stepFlashLength_in?: Maybe<Array<Scalars['Float']>>;
  stepFlashLength_not_in?: Maybe<Array<Scalars['Float']>>;
  stepFlashLength_lt?: Maybe<Scalars['Float']>;
  stepFlashLength_lte?: Maybe<Scalars['Float']>;
  stepFlashLength_gt?: Maybe<Scalars['Float']>;
  stepFlashLength_gte?: Maybe<Scalars['Float']>;
  flashLength?: Maybe<Scalars['Float']>;
  flashLength_not?: Maybe<Scalars['Float']>;
  flashLength_in?: Maybe<Array<Scalars['Float']>>;
  flashLength_not_in?: Maybe<Array<Scalars['Float']>>;
  flashLength_lt?: Maybe<Scalars['Float']>;
  flashLength_lte?: Maybe<Scalars['Float']>;
  flashLength_gt?: Maybe<Scalars['Float']>;
  flashLength_gte?: Maybe<Scalars['Float']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_not?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_in?: Maybe<Array<Scalars['Int']>>;
  legacyHomeSpecId_not_in?: Maybe<Array<Scalars['Int']>>;
  legacyHomeSpecId_lt?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_lte?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_gt?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_gte?: Maybe<Scalars['Int']>;
  structures_every?: Maybe<StructureWhereInput>;
  structures_some?: Maybe<StructureWhereInput>;
  structures_none?: Maybe<StructureWhereInput>;
  property?: Maybe<PropertyWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PropertySpecWhereInput>>;
  OR?: Maybe<Array<PropertySpecWhereInput>>;
  NOT?: Maybe<Array<PropertySpecWhereInput>>;
};

export enum PropertyStatus {
  InProgress = 'IN_PROGRESS',
  DataReady = 'DATA_READY',
  Ready = 'READY',
  Initial = 'INITIAL',
  Denied = 'DENIED',
  PaymentFailed = 'PAYMENT_FAILED',
  Canceled = 'CANCELED'
}

/** Any container type that can be rendered into the feed */
export type PropertyType = BiddableProperty | HiredProperty;

export type PropertyWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  houseNumber?: Maybe<Scalars['String']>;
  houseNumber_not?: Maybe<Scalars['String']>;
  houseNumber_in?: Maybe<Array<Scalars['String']>>;
  houseNumber_not_in?: Maybe<Array<Scalars['String']>>;
  houseNumber_lt?: Maybe<Scalars['String']>;
  houseNumber_lte?: Maybe<Scalars['String']>;
  houseNumber_gt?: Maybe<Scalars['String']>;
  houseNumber_gte?: Maybe<Scalars['String']>;
  houseNumber_contains?: Maybe<Scalars['String']>;
  houseNumber_not_contains?: Maybe<Scalars['String']>;
  houseNumber_starts_with?: Maybe<Scalars['String']>;
  houseNumber_not_starts_with?: Maybe<Scalars['String']>;
  houseNumber_ends_with?: Maybe<Scalars['String']>;
  houseNumber_not_ends_with?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetName_not?: Maybe<Scalars['String']>;
  streetName_in?: Maybe<Array<Scalars['String']>>;
  streetName_not_in?: Maybe<Array<Scalars['String']>>;
  streetName_lt?: Maybe<Scalars['String']>;
  streetName_lte?: Maybe<Scalars['String']>;
  streetName_gt?: Maybe<Scalars['String']>;
  streetName_gte?: Maybe<Scalars['String']>;
  streetName_contains?: Maybe<Scalars['String']>;
  streetName_not_contains?: Maybe<Scalars['String']>;
  streetName_starts_with?: Maybe<Scalars['String']>;
  streetName_not_starts_with?: Maybe<Scalars['String']>;
  streetName_ends_with?: Maybe<Scalars['String']>;
  streetName_not_ends_with?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_not?: Maybe<Scalars['String']>;
  city_in?: Maybe<Array<Scalars['String']>>;
  city_not_in?: Maybe<Array<Scalars['String']>>;
  city_lt?: Maybe<Scalars['String']>;
  city_lte?: Maybe<Scalars['String']>;
  city_gt?: Maybe<Scalars['String']>;
  city_gte?: Maybe<Scalars['String']>;
  city_contains?: Maybe<Scalars['String']>;
  city_not_contains?: Maybe<Scalars['String']>;
  city_starts_with?: Maybe<Scalars['String']>;
  city_not_starts_with?: Maybe<Scalars['String']>;
  city_ends_with?: Maybe<Scalars['String']>;
  city_not_ends_with?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_not?: Maybe<Scalars['String']>;
  state_in?: Maybe<Array<Scalars['String']>>;
  state_not_in?: Maybe<Array<Scalars['String']>>;
  state_lt?: Maybe<Scalars['String']>;
  state_lte?: Maybe<Scalars['String']>;
  state_gt?: Maybe<Scalars['String']>;
  state_gte?: Maybe<Scalars['String']>;
  state_contains?: Maybe<Scalars['String']>;
  state_not_contains?: Maybe<Scalars['String']>;
  state_starts_with?: Maybe<Scalars['String']>;
  state_not_starts_with?: Maybe<Scalars['String']>;
  state_ends_with?: Maybe<Scalars['String']>;
  state_not_ends_with?: Maybe<Scalars['String']>;
  county?: Maybe<CountyWhereInput>;
  zipCode?: Maybe<Scalars['String']>;
  zipCode_not?: Maybe<Scalars['String']>;
  zipCode_in?: Maybe<Array<Scalars['String']>>;
  zipCode_not_in?: Maybe<Array<Scalars['String']>>;
  zipCode_lt?: Maybe<Scalars['String']>;
  zipCode_lte?: Maybe<Scalars['String']>;
  zipCode_gt?: Maybe<Scalars['String']>;
  zipCode_gte?: Maybe<Scalars['String']>;
  zipCode_contains?: Maybe<Scalars['String']>;
  zipCode_not_contains?: Maybe<Scalars['String']>;
  zipCode_starts_with?: Maybe<Scalars['String']>;
  zipCode_not_starts_with?: Maybe<Scalars['String']>;
  zipCode_ends_with?: Maybe<Scalars['String']>;
  zipCode_not_ends_with?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lat_not?: Maybe<Scalars['String']>;
  lat_in?: Maybe<Array<Scalars['String']>>;
  lat_not_in?: Maybe<Array<Scalars['String']>>;
  lat_lt?: Maybe<Scalars['String']>;
  lat_lte?: Maybe<Scalars['String']>;
  lat_gt?: Maybe<Scalars['String']>;
  lat_gte?: Maybe<Scalars['String']>;
  lat_contains?: Maybe<Scalars['String']>;
  lat_not_contains?: Maybe<Scalars['String']>;
  lat_starts_with?: Maybe<Scalars['String']>;
  lat_not_starts_with?: Maybe<Scalars['String']>;
  lat_ends_with?: Maybe<Scalars['String']>;
  lat_not_ends_with?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  lng_not?: Maybe<Scalars['String']>;
  lng_in?: Maybe<Array<Scalars['String']>>;
  lng_not_in?: Maybe<Array<Scalars['String']>>;
  lng_lt?: Maybe<Scalars['String']>;
  lng_lte?: Maybe<Scalars['String']>;
  lng_gt?: Maybe<Scalars['String']>;
  lng_gte?: Maybe<Scalars['String']>;
  lng_contains?: Maybe<Scalars['String']>;
  lng_not_contains?: Maybe<Scalars['String']>;
  lng_starts_with?: Maybe<Scalars['String']>;
  lng_not_starts_with?: Maybe<Scalars['String']>;
  lng_ends_with?: Maybe<Scalars['String']>;
  lng_not_ends_with?: Maybe<Scalars['String']>;
  owner?: Maybe<HomeownerWhereInput>;
  orderedMeasurements_every?: Maybe<OrderedMeasurementWhereInput>;
  orderedMeasurements_some?: Maybe<OrderedMeasurementWhereInput>;
  orderedMeasurements_none?: Maybe<OrderedMeasurementWhereInput>;
  jobs_every?: Maybe<JobWhereInput>;
  jobs_some?: Maybe<JobWhereInput>;
  jobs_none?: Maybe<JobWhereInput>;
  activeJobs_every?: Maybe<JobWhereInput>;
  activeJobs_some?: Maybe<JobWhereInput>;
  activeJobs_none?: Maybe<JobWhereInput>;
  ready?: Maybe<PropertyStatus>;
  ready_not?: Maybe<PropertyStatus>;
  ready_in?: Maybe<Array<PropertyStatus>>;
  ready_not_in?: Maybe<Array<PropertyStatus>>;
  renoworksId?: Maybe<Scalars['String']>;
  renoworksId_not?: Maybe<Scalars['String']>;
  renoworksId_in?: Maybe<Array<Scalars['String']>>;
  renoworksId_not_in?: Maybe<Array<Scalars['String']>>;
  renoworksId_lt?: Maybe<Scalars['String']>;
  renoworksId_lte?: Maybe<Scalars['String']>;
  renoworksId_gt?: Maybe<Scalars['String']>;
  renoworksId_gte?: Maybe<Scalars['String']>;
  renoworksId_contains?: Maybe<Scalars['String']>;
  renoworksId_not_contains?: Maybe<Scalars['String']>;
  renoworksId_starts_with?: Maybe<Scalars['String']>;
  renoworksId_not_starts_with?: Maybe<Scalars['String']>;
  renoworksId_ends_with?: Maybe<Scalars['String']>;
  renoworksId_not_ends_with?: Maybe<Scalars['String']>;
  renoworksDisplayId?: Maybe<Scalars['String']>;
  renoworksDisplayId_not?: Maybe<Scalars['String']>;
  renoworksDisplayId_in?: Maybe<Array<Scalars['String']>>;
  renoworksDisplayId_not_in?: Maybe<Array<Scalars['String']>>;
  renoworksDisplayId_lt?: Maybe<Scalars['String']>;
  renoworksDisplayId_lte?: Maybe<Scalars['String']>;
  renoworksDisplayId_gt?: Maybe<Scalars['String']>;
  renoworksDisplayId_gte?: Maybe<Scalars['String']>;
  renoworksDisplayId_contains?: Maybe<Scalars['String']>;
  renoworksDisplayId_not_contains?: Maybe<Scalars['String']>;
  renoworksDisplayId_starts_with?: Maybe<Scalars['String']>;
  renoworksDisplayId_not_starts_with?: Maybe<Scalars['String']>;
  renoworksDisplayId_ends_with?: Maybe<Scalars['String']>;
  renoworksDisplayId_not_ends_with?: Maybe<Scalars['String']>;
  renoworksRequestId?: Maybe<Scalars['String']>;
  renoworksRequestId_not?: Maybe<Scalars['String']>;
  renoworksRequestId_in?: Maybe<Array<Scalars['String']>>;
  renoworksRequestId_not_in?: Maybe<Array<Scalars['String']>>;
  renoworksRequestId_lt?: Maybe<Scalars['String']>;
  renoworksRequestId_lte?: Maybe<Scalars['String']>;
  renoworksRequestId_gt?: Maybe<Scalars['String']>;
  renoworksRequestId_gte?: Maybe<Scalars['String']>;
  renoworksRequestId_contains?: Maybe<Scalars['String']>;
  renoworksRequestId_not_contains?: Maybe<Scalars['String']>;
  renoworksRequestId_starts_with?: Maybe<Scalars['String']>;
  renoworksRequestId_not_starts_with?: Maybe<Scalars['String']>;
  renoworksRequestId_ends_with?: Maybe<Scalars['String']>;
  renoworksRequestId_not_ends_with?: Maybe<Scalars['String']>;
  legacyHomeSpecId?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_not?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_in?: Maybe<Array<Scalars['Int']>>;
  legacyHomeSpecId_not_in?: Maybe<Array<Scalars['Int']>>;
  legacyHomeSpecId_lt?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_lte?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_gt?: Maybe<Scalars['Int']>;
  legacyHomeSpecId_gte?: Maybe<Scalars['Int']>;
  geomniUrl?: Maybe<Scalars['String']>;
  geomniUrl_not?: Maybe<Scalars['String']>;
  geomniUrl_in?: Maybe<Array<Scalars['String']>>;
  geomniUrl_not_in?: Maybe<Array<Scalars['String']>>;
  geomniUrl_lt?: Maybe<Scalars['String']>;
  geomniUrl_lte?: Maybe<Scalars['String']>;
  geomniUrl_gt?: Maybe<Scalars['String']>;
  geomniUrl_gte?: Maybe<Scalars['String']>;
  geomniUrl_contains?: Maybe<Scalars['String']>;
  geomniUrl_not_contains?: Maybe<Scalars['String']>;
  geomniUrl_starts_with?: Maybe<Scalars['String']>;
  geomniUrl_not_starts_with?: Maybe<Scalars['String']>;
  geomniUrl_ends_with?: Maybe<Scalars['String']>;
  geomniUrl_not_ends_with?: Maybe<Scalars['String']>;
  pictures_every?: Maybe<PictureWhereInput>;
  pictures_some?: Maybe<PictureWhereInput>;
  pictures_none?: Maybe<PictureWhereInput>;
  order?: Maybe<OrderWhereInput>;
  propertySpec?: Maybe<PropertySpecWhereInput>;
  designCenterImageUrl?: Maybe<Scalars['String']>;
  designCenterImageUrl_not?: Maybe<Scalars['String']>;
  designCenterImageUrl_in?: Maybe<Array<Scalars['String']>>;
  designCenterImageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  designCenterImageUrl_lt?: Maybe<Scalars['String']>;
  designCenterImageUrl_lte?: Maybe<Scalars['String']>;
  designCenterImageUrl_gt?: Maybe<Scalars['String']>;
  designCenterImageUrl_gte?: Maybe<Scalars['String']>;
  designCenterImageUrl_contains?: Maybe<Scalars['String']>;
  designCenterImageUrl_not_contains?: Maybe<Scalars['String']>;
  designCenterImageUrl_starts_with?: Maybe<Scalars['String']>;
  designCenterImageUrl_not_starts_with?: Maybe<Scalars['String']>;
  designCenterImageUrl_ends_with?: Maybe<Scalars['String']>;
  designCenterImageUrl_not_ends_with?: Maybe<Scalars['String']>;
  viewerImageUrl?: Maybe<Scalars['String']>;
  viewerImageUrl_not?: Maybe<Scalars['String']>;
  viewerImageUrl_in?: Maybe<Array<Scalars['String']>>;
  viewerImageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  viewerImageUrl_lt?: Maybe<Scalars['String']>;
  viewerImageUrl_lte?: Maybe<Scalars['String']>;
  viewerImageUrl_gt?: Maybe<Scalars['String']>;
  viewerImageUrl_gte?: Maybe<Scalars['String']>;
  viewerImageUrl_contains?: Maybe<Scalars['String']>;
  viewerImageUrl_not_contains?: Maybe<Scalars['String']>;
  viewerImageUrl_starts_with?: Maybe<Scalars['String']>;
  viewerImageUrl_not_starts_with?: Maybe<Scalars['String']>;
  viewerImageUrl_ends_with?: Maybe<Scalars['String']>;
  viewerImageUrl_not_ends_with?: Maybe<Scalars['String']>;
  newViewer?: Maybe<Scalars['Boolean']>;
  newViewer_not?: Maybe<Scalars['Boolean']>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscriptionPlan_not?: Maybe<SubscriptionPlan>;
  subscriptionPlan_in?: Maybe<Array<SubscriptionPlan>>;
  subscriptionPlan_not_in?: Maybe<Array<SubscriptionPlan>>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not?: Maybe<Scalars['String']>;
  stripeSubscriptionId_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionId_lt?: Maybe<Scalars['String']>;
  stripeSubscriptionId_lte?: Maybe<Scalars['String']>;
  stripeSubscriptionId_gt?: Maybe<Scalars['String']>;
  stripeSubscriptionId_gte?: Maybe<Scalars['String']>;
  stripeSubscriptionId_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionId_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_ends_with?: Maybe<Scalars['String']>;
  freeProperty?: Maybe<Scalars['Boolean']>;
  freeProperty_not?: Maybe<Scalars['Boolean']>;
  paymentFrequency?: Maybe<PropertyPaymentFrequency>;
  paymentFrequency_not?: Maybe<PropertyPaymentFrequency>;
  paymentFrequency_in?: Maybe<Array<PropertyPaymentFrequency>>;
  paymentFrequency_not_in?: Maybe<Array<PropertyPaymentFrequency>>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentAmount_not?: Maybe<Scalars['Float']>;
  paymentAmount_in?: Maybe<Array<Scalars['Float']>>;
  paymentAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  paymentAmount_lt?: Maybe<Scalars['Float']>;
  paymentAmount_lte?: Maybe<Scalars['Float']>;
  paymentAmount_gt?: Maybe<Scalars['Float']>;
  paymentAmount_gte?: Maybe<Scalars['Float']>;
  stripeSubscriptionPlanId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_not?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionPlanId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionPlanId_lt?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_lte?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_gt?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_gte?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_not_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_not_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionPlanId_not_ends_with?: Maybe<Scalars['String']>;
  selectedDealBidders_every?: Maybe<DealBidderWhereInput>;
  selectedDealBidders_some?: Maybe<DealBidderWhereInput>;
  selectedDealBidders_none?: Maybe<DealBidderWhereInput>;
  dealChats_every?: Maybe<BidChatWhereInput>;
  dealChats_some?: Maybe<BidChatWhereInput>;
  dealChats_none?: Maybe<BidChatWhereInput>;
  AND?: Maybe<Array<PropertyWhereInput>>;
  OR?: Maybe<Array<PropertyWhereInput>>;
  NOT?: Maybe<Array<PropertyWhereInput>>;
};

export enum ProposedBy {
  Contractor = 'CONTRACTOR',
  Homeowner = 'HOMEOWNER'
}

export type PushNotificationConfig = {
  __typename?: 'PushNotificationConfig';
  id: Scalars['ID'];
  user: User;
  pushTokens?: Maybe<Array<PushToken>>;
  biddableJobs: Scalars['Boolean'];
  chat: Scalars['Boolean'];
  bidActivity: Scalars['Boolean'];
  newsAndUpdates: Scalars['Boolean'];
  lastNotification?: Maybe<Scalars['String']>;
  dealActivity: Scalars['Boolean'];
};


export type PushNotificationConfigPushTokensArgs = {
  where?: Maybe<PushTokenWhereInput>;
  orderBy?: Maybe<PushTokenOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PushNotificationConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  user?: Maybe<UserWhereInput>;
  pushTokens_every?: Maybe<PushTokenWhereInput>;
  pushTokens_some?: Maybe<PushTokenWhereInput>;
  pushTokens_none?: Maybe<PushTokenWhereInput>;
  biddableJobs?: Maybe<Scalars['Boolean']>;
  biddableJobs_not?: Maybe<Scalars['Boolean']>;
  chat?: Maybe<Scalars['Boolean']>;
  chat_not?: Maybe<Scalars['Boolean']>;
  bidActivity?: Maybe<Scalars['Boolean']>;
  bidActivity_not?: Maybe<Scalars['Boolean']>;
  newsAndUpdates?: Maybe<Scalars['Boolean']>;
  newsAndUpdates_not?: Maybe<Scalars['Boolean']>;
  lastNotification?: Maybe<Scalars['String']>;
  lastNotification_not?: Maybe<Scalars['String']>;
  lastNotification_in?: Maybe<Array<Scalars['String']>>;
  lastNotification_not_in?: Maybe<Array<Scalars['String']>>;
  lastNotification_lt?: Maybe<Scalars['String']>;
  lastNotification_lte?: Maybe<Scalars['String']>;
  lastNotification_gt?: Maybe<Scalars['String']>;
  lastNotification_gte?: Maybe<Scalars['String']>;
  lastNotification_contains?: Maybe<Scalars['String']>;
  lastNotification_not_contains?: Maybe<Scalars['String']>;
  lastNotification_starts_with?: Maybe<Scalars['String']>;
  lastNotification_not_starts_with?: Maybe<Scalars['String']>;
  lastNotification_ends_with?: Maybe<Scalars['String']>;
  lastNotification_not_ends_with?: Maybe<Scalars['String']>;
  dealActivity?: Maybe<Scalars['Boolean']>;
  dealActivity_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<PushNotificationConfigWhereInput>>;
  OR?: Maybe<Array<PushNotificationConfigWhereInput>>;
  NOT?: Maybe<Array<PushNotificationConfigWhereInput>>;
};

export type PushToken = {
  __typename?: 'PushToken';
  id: Scalars['ID'];
  value: Scalars['String'];
  pushNotificationConfig: PushNotificationConfig;
};

export enum PushTokenOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PushTokenWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_lt?: Maybe<Scalars['String']>;
  value_lte?: Maybe<Scalars['String']>;
  value_gt?: Maybe<Scalars['String']>;
  value_gte?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
  pushNotificationConfig?: Maybe<PushNotificationConfigWhereInput>;
  AND?: Maybe<Array<PushTokenWhereInput>>;
  OR?: Maybe<Array<PushTokenWhereInput>>;
  NOT?: Maybe<Array<PushTokenWhereInput>>;
};

export type Query = {
  __typename?: 'Query';
  options?: Maybe<Array<Option>>;
  states?: Maybe<Array<State>>;
  countiesWithState?: Maybe<Array<County>>;
  modelRelations?: Maybe<Array<ModelRelation>>;
  trades?: Maybe<Array<Trade>>;
  myBidMessages?: Maybe<Array<BidMessage>>;
  chargeAmounts?: Maybe<ChargeAmounts>;
  charges?: Maybe<Array<Charge>>;
  contractor?: Maybe<Contractor>;
  contractorJobs?: Maybe<Array<ContractorJob>>;
  contractorOngoingJobsCount?: Maybe<Scalars['Int']>;
  contractorPendingBidsCount?: Maybe<Scalars['Int']>;
  contractorBiddableJobsCount?: Maybe<Scalars['Int']>;
  contractorClosedJobsCount?: Maybe<Scalars['Int']>;
  contractorHiredJobs?: Maybe<Array<ContractorJob>>;
  homeowner?: Maybe<Homeowner>;
  homeownerProperty?: Maybe<Property>;
  homeownerOngoingJobsCount?: Maybe<Scalars['Int']>;
  homeownerArchivedJobsCount?: Maybe<Scalars['Int']>;
  homeownerJobs?: Maybe<Array<Job>>;
  paymentMethod?: Maybe<PaymentMethod>;
  dealChat?: Maybe<BidChat>;
  user?: Maybe<User>;
  contractorOrderedMeasurements?: Maybe<Array<ContractorOrderedMeasurement>>;
  monthlyCharges?: Maybe<MonthlyCharge>;
  retryCharges?: Maybe<RetryCharges>;
  validatePromo?: Maybe<ValidatePromoPayload>;
  homeownerDeals?: Maybe<Array<HomeownerDeal>>;
  deals?: Maybe<Array<HomeownerDeal>>;
  contractorDeals?: Maybe<Array<ContractorDeal>>;
  dealContracts?: Maybe<Array<LimitedDealContract>>;
  adminGetBiddingJobsCount?: Maybe<Scalars['Int']>;
  adminGetCompletedJobsCount?: Maybe<Scalars['Int']>;
  adminGetJobs?: Maybe<Array<Job>>;
  adminGetJobSpecifications?: Maybe<Array<JobSpecification>>;
  adminGetJobsCount?: Maybe<Scalars['Int']>;
  adminGetOngoingJobsCount?: Maybe<Scalars['Int']>;
  adminGetProperty?: Maybe<Property>;
  adminGetSlippingJobsCount?: Maybe<Scalars['Int']>;
  adminGetUsers?: Maybe<Array<User>>;
  adminGetUsersCount?: Maybe<Scalars['Int']>;
  adminPromos?: Maybe<Array<Promo>>;
  adminDeals?: Maybe<Array<AdminDeal>>;
  parser?: Maybe<Scalars['Json']>;
  reParseRequest?: Maybe<ReParseRequest>;
};


export type QueryMyBidMessagesArgs = {
  bidChatId: Scalars['ID'];
};


export type QueryContractorJobsArgs = {
  jobId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  view?: Maybe<Scalars['String']>;
  trades?: Maybe<Array<TradeGroup>>;
};


export type QueryContractorOngoingJobsCountArgs = {
  trades?: Maybe<Array<TradeGroup>>;
};


export type QueryContractorPendingBidsCountArgs = {
  trades?: Maybe<Array<TradeGroup>>;
};


export type QueryContractorBiddableJobsCountArgs = {
  trades?: Maybe<Array<TradeGroup>>;
};


export type QueryContractorClosedJobsCountArgs = {
  trades?: Maybe<Array<TradeGroup>>;
};


export type QueryContractorHiredJobsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryHomeownerPropertyArgs = {
  propertyId: Scalars['ID'];
};


export type QueryHomeownerOngoingJobsCountArgs = {
  propertyIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryHomeownerArchivedJobsCountArgs = {
  propertyIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryHomeownerJobsArgs = {
  jobId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  view?: Maybe<Scalars['String']>;
  propertyIds?: Maybe<Array<Scalars['ID']>>;
  trades?: Maybe<Array<TradeGroup>>;
};


export type QueryDealChatArgs = {
  dealId: Scalars['ID'];
  contractorId: Scalars['ID'];
  homeownerId: Scalars['ID'];
};


export type QueryContractorOrderedMeasurementsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryValidatePromoArgs = {
  propertyId: Scalars['ID'];
  promoCode: Scalars['String'];
};


export type QueryHomeownerDealsArgs = {
  joined: Scalars['Boolean'];
  propertyId?: Maybe<Scalars['ID']>;
  dealId?: Maybe<Scalars['ID']>;
};


export type QueryDealsArgs = {
  dealId?: Maybe<Scalars['ID']>;
  dealBidderId?: Maybe<Scalars['ID']>;
};


export type QueryContractorDealsArgs = {
  dealId?: Maybe<Scalars['ID']>;
};


export type QueryDealContractsArgs = {
  dealId: Scalars['ID'];
  filter: DealFilter;
  deleted?: Maybe<Scalars['Boolean']>;
};


export type QueryAdminGetBiddingJobsCountArgs = {
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  jobType?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
};


export type QueryAdminGetCompletedJobsCountArgs = {
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  jobType?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
};


export type QueryAdminGetJobsArgs = {
  page: Scalars['Int'];
  view?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  jobType?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
};


export type QueryAdminGetJobSpecificationsArgs = {
  view?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};


export type QueryAdminGetJobsCountArgs = {
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  jobType?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
};


export type QueryAdminGetOngoingJobsCountArgs = {
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  jobType?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
};


export type QueryAdminGetPropertyArgs = {
  id: Scalars['ID'];
};


export type QueryAdminGetSlippingJobsCountArgs = {
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  jobType?: Maybe<Array<Scalars['String']>>;
  county?: Maybe<Scalars['String']>;
};


export type QueryAdminGetUsersArgs = {
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
};


export type QueryAdminGetUsersCountArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryParserArgs = {
  xmlFilePath?: Maybe<Scalars['String']>;
  responseFilePath?: Maybe<Scalars['String']>;
};


export type QueryReParseRequestArgs = {
  reParseRequestId: Scalars['ID'];
};

export type RainGutter = {
  __typename?: 'RainGutter';
  id: Scalars['ID'];
  refId: Scalars['String'];
  length?: Maybe<Scalars['Float']>;
  materialCode?: Maybe<Scalars['String']>;
  roofFace: RoofFace;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum RainGutterOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  MaterialCodeAsc = 'materialCode_ASC',
  MaterialCodeDesc = 'materialCode_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type RainGutterWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  length_not?: Maybe<Scalars['Float']>;
  length_in?: Maybe<Array<Scalars['Float']>>;
  length_not_in?: Maybe<Array<Scalars['Float']>>;
  length_lt?: Maybe<Scalars['Float']>;
  length_lte?: Maybe<Scalars['Float']>;
  length_gt?: Maybe<Scalars['Float']>;
  length_gte?: Maybe<Scalars['Float']>;
  materialCode?: Maybe<Scalars['String']>;
  materialCode_not?: Maybe<Scalars['String']>;
  materialCode_in?: Maybe<Array<Scalars['String']>>;
  materialCode_not_in?: Maybe<Array<Scalars['String']>>;
  materialCode_lt?: Maybe<Scalars['String']>;
  materialCode_lte?: Maybe<Scalars['String']>;
  materialCode_gt?: Maybe<Scalars['String']>;
  materialCode_gte?: Maybe<Scalars['String']>;
  materialCode_contains?: Maybe<Scalars['String']>;
  materialCode_not_contains?: Maybe<Scalars['String']>;
  materialCode_starts_with?: Maybe<Scalars['String']>;
  materialCode_not_starts_with?: Maybe<Scalars['String']>;
  materialCode_ends_with?: Maybe<Scalars['String']>;
  materialCode_not_ends_with?: Maybe<Scalars['String']>;
  roofFace?: Maybe<RoofFaceWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RainGutterWhereInput>>;
  OR?: Maybe<Array<RainGutterWhereInput>>;
  NOT?: Maybe<Array<RainGutterWhereInput>>;
};

export type ReParseRequest = {
  __typename?: 'ReParseRequest';
  id: Scalars['ID'];
  successfulProperties: Array<Scalars['String']>;
  failedProperties: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type RetryCharges = {
  __typename?: 'RetryCharges';
  propertyCharges?: Maybe<Array<PropertyCharge>>;
  bidCharges?: Maybe<Array<BidCharge>>;
  propertyTotalInCents?: Maybe<Scalars['Float']>;
  bidTotalInCents?: Maybe<Scalars['Float']>;
};

export type RoofFace = {
  __typename?: 'RoofFace';
  id: Scalars['ID'];
  refId: Scalars['String'];
  area?: Maybe<Scalars['Float']>;
  holeArea?: Maybe<Scalars['Float']>;
  slope?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  hipLength?: Maybe<Scalars['Float']>;
  ridgeLength?: Maybe<Scalars['Float']>;
  rakeLength?: Maybe<Scalars['Float']>;
  eaveLength?: Maybe<Scalars['Float']>;
  valleyLength?: Maybe<Scalars['Float']>;
  stepFlashLength?: Maybe<Scalars['Float']>;
  flashLength?: Maybe<Scalars['Float']>;
  trims?: Maybe<Array<RoofTrim>>;
  gutters?: Maybe<Array<RainGutter>>;
  vents?: Maybe<Array<RoofVent>>;
  structure: Structure;
  ridgeLines?: Maybe<Array<Line>>;
  hipLines?: Maybe<Array<Line>>;
  valleyLines?: Maybe<Array<Line>>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type RoofFaceTrimsArgs = {
  where?: Maybe<RoofTrimWhereInput>;
  orderBy?: Maybe<RoofTrimOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RoofFaceGuttersArgs = {
  where?: Maybe<RainGutterWhereInput>;
  orderBy?: Maybe<RainGutterOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RoofFaceVentsArgs = {
  where?: Maybe<RoofVentWhereInput>;
  orderBy?: Maybe<RoofVentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RoofFaceRidgeLinesArgs = {
  where?: Maybe<LineWhereInput>;
  orderBy?: Maybe<LineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RoofFaceHipLinesArgs = {
  where?: Maybe<LineWhereInput>;
  orderBy?: Maybe<LineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RoofFaceValleyLinesArgs = {
  where?: Maybe<LineWhereInput>;
  orderBy?: Maybe<LineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum RoofFaceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  HoleAreaAsc = 'holeArea_ASC',
  HoleAreaDesc = 'holeArea_DESC',
  SlopeAsc = 'slope_ASC',
  SlopeDesc = 'slope_DESC',
  OrientationAsc = 'orientation_ASC',
  OrientationDesc = 'orientation_DESC',
  MaterialAsc = 'material_ASC',
  MaterialDesc = 'material_DESC',
  MaterialCodeAsc = 'materialCode_ASC',
  MaterialCodeDesc = 'materialCode_DESC',
  HipLengthAsc = 'hipLength_ASC',
  HipLengthDesc = 'hipLength_DESC',
  RidgeLengthAsc = 'ridgeLength_ASC',
  RidgeLengthDesc = 'ridgeLength_DESC',
  RakeLengthAsc = 'rakeLength_ASC',
  RakeLengthDesc = 'rakeLength_DESC',
  EaveLengthAsc = 'eaveLength_ASC',
  EaveLengthDesc = 'eaveLength_DESC',
  ValleyLengthAsc = 'valleyLength_ASC',
  ValleyLengthDesc = 'valleyLength_DESC',
  StepFlashLengthAsc = 'stepFlashLength_ASC',
  StepFlashLengthDesc = 'stepFlashLength_DESC',
  FlashLengthAsc = 'flashLength_ASC',
  FlashLengthDesc = 'flashLength_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type RoofFaceWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  area_not?: Maybe<Scalars['Float']>;
  area_in?: Maybe<Array<Scalars['Float']>>;
  area_not_in?: Maybe<Array<Scalars['Float']>>;
  area_lt?: Maybe<Scalars['Float']>;
  area_lte?: Maybe<Scalars['Float']>;
  area_gt?: Maybe<Scalars['Float']>;
  area_gte?: Maybe<Scalars['Float']>;
  holeArea?: Maybe<Scalars['Float']>;
  holeArea_not?: Maybe<Scalars['Float']>;
  holeArea_in?: Maybe<Array<Scalars['Float']>>;
  holeArea_not_in?: Maybe<Array<Scalars['Float']>>;
  holeArea_lt?: Maybe<Scalars['Float']>;
  holeArea_lte?: Maybe<Scalars['Float']>;
  holeArea_gt?: Maybe<Scalars['Float']>;
  holeArea_gte?: Maybe<Scalars['Float']>;
  slope?: Maybe<Scalars['Float']>;
  slope_not?: Maybe<Scalars['Float']>;
  slope_in?: Maybe<Array<Scalars['Float']>>;
  slope_not_in?: Maybe<Array<Scalars['Float']>>;
  slope_lt?: Maybe<Scalars['Float']>;
  slope_lte?: Maybe<Scalars['Float']>;
  slope_gt?: Maybe<Scalars['Float']>;
  slope_gte?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Float']>;
  orientation_not?: Maybe<Scalars['Float']>;
  orientation_in?: Maybe<Array<Scalars['Float']>>;
  orientation_not_in?: Maybe<Array<Scalars['Float']>>;
  orientation_lt?: Maybe<Scalars['Float']>;
  orientation_lte?: Maybe<Scalars['Float']>;
  orientation_gt?: Maybe<Scalars['Float']>;
  orientation_gte?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  material_not?: Maybe<Scalars['String']>;
  material_in?: Maybe<Array<Scalars['String']>>;
  material_not_in?: Maybe<Array<Scalars['String']>>;
  material_lt?: Maybe<Scalars['String']>;
  material_lte?: Maybe<Scalars['String']>;
  material_gt?: Maybe<Scalars['String']>;
  material_gte?: Maybe<Scalars['String']>;
  material_contains?: Maybe<Scalars['String']>;
  material_not_contains?: Maybe<Scalars['String']>;
  material_starts_with?: Maybe<Scalars['String']>;
  material_not_starts_with?: Maybe<Scalars['String']>;
  material_ends_with?: Maybe<Scalars['String']>;
  material_not_ends_with?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  materialCode_not?: Maybe<Scalars['String']>;
  materialCode_in?: Maybe<Array<Scalars['String']>>;
  materialCode_not_in?: Maybe<Array<Scalars['String']>>;
  materialCode_lt?: Maybe<Scalars['String']>;
  materialCode_lte?: Maybe<Scalars['String']>;
  materialCode_gt?: Maybe<Scalars['String']>;
  materialCode_gte?: Maybe<Scalars['String']>;
  materialCode_contains?: Maybe<Scalars['String']>;
  materialCode_not_contains?: Maybe<Scalars['String']>;
  materialCode_starts_with?: Maybe<Scalars['String']>;
  materialCode_not_starts_with?: Maybe<Scalars['String']>;
  materialCode_ends_with?: Maybe<Scalars['String']>;
  materialCode_not_ends_with?: Maybe<Scalars['String']>;
  hipLength?: Maybe<Scalars['Float']>;
  hipLength_not?: Maybe<Scalars['Float']>;
  hipLength_in?: Maybe<Array<Scalars['Float']>>;
  hipLength_not_in?: Maybe<Array<Scalars['Float']>>;
  hipLength_lt?: Maybe<Scalars['Float']>;
  hipLength_lte?: Maybe<Scalars['Float']>;
  hipLength_gt?: Maybe<Scalars['Float']>;
  hipLength_gte?: Maybe<Scalars['Float']>;
  ridgeLength?: Maybe<Scalars['Float']>;
  ridgeLength_not?: Maybe<Scalars['Float']>;
  ridgeLength_in?: Maybe<Array<Scalars['Float']>>;
  ridgeLength_not_in?: Maybe<Array<Scalars['Float']>>;
  ridgeLength_lt?: Maybe<Scalars['Float']>;
  ridgeLength_lte?: Maybe<Scalars['Float']>;
  ridgeLength_gt?: Maybe<Scalars['Float']>;
  ridgeLength_gte?: Maybe<Scalars['Float']>;
  rakeLength?: Maybe<Scalars['Float']>;
  rakeLength_not?: Maybe<Scalars['Float']>;
  rakeLength_in?: Maybe<Array<Scalars['Float']>>;
  rakeLength_not_in?: Maybe<Array<Scalars['Float']>>;
  rakeLength_lt?: Maybe<Scalars['Float']>;
  rakeLength_lte?: Maybe<Scalars['Float']>;
  rakeLength_gt?: Maybe<Scalars['Float']>;
  rakeLength_gte?: Maybe<Scalars['Float']>;
  eaveLength?: Maybe<Scalars['Float']>;
  eaveLength_not?: Maybe<Scalars['Float']>;
  eaveLength_in?: Maybe<Array<Scalars['Float']>>;
  eaveLength_not_in?: Maybe<Array<Scalars['Float']>>;
  eaveLength_lt?: Maybe<Scalars['Float']>;
  eaveLength_lte?: Maybe<Scalars['Float']>;
  eaveLength_gt?: Maybe<Scalars['Float']>;
  eaveLength_gte?: Maybe<Scalars['Float']>;
  valleyLength?: Maybe<Scalars['Float']>;
  valleyLength_not?: Maybe<Scalars['Float']>;
  valleyLength_in?: Maybe<Array<Scalars['Float']>>;
  valleyLength_not_in?: Maybe<Array<Scalars['Float']>>;
  valleyLength_lt?: Maybe<Scalars['Float']>;
  valleyLength_lte?: Maybe<Scalars['Float']>;
  valleyLength_gt?: Maybe<Scalars['Float']>;
  valleyLength_gte?: Maybe<Scalars['Float']>;
  stepFlashLength?: Maybe<Scalars['Float']>;
  stepFlashLength_not?: Maybe<Scalars['Float']>;
  stepFlashLength_in?: Maybe<Array<Scalars['Float']>>;
  stepFlashLength_not_in?: Maybe<Array<Scalars['Float']>>;
  stepFlashLength_lt?: Maybe<Scalars['Float']>;
  stepFlashLength_lte?: Maybe<Scalars['Float']>;
  stepFlashLength_gt?: Maybe<Scalars['Float']>;
  stepFlashLength_gte?: Maybe<Scalars['Float']>;
  flashLength?: Maybe<Scalars['Float']>;
  flashLength_not?: Maybe<Scalars['Float']>;
  flashLength_in?: Maybe<Array<Scalars['Float']>>;
  flashLength_not_in?: Maybe<Array<Scalars['Float']>>;
  flashLength_lt?: Maybe<Scalars['Float']>;
  flashLength_lte?: Maybe<Scalars['Float']>;
  flashLength_gt?: Maybe<Scalars['Float']>;
  flashLength_gte?: Maybe<Scalars['Float']>;
  trims_every?: Maybe<RoofTrimWhereInput>;
  trims_some?: Maybe<RoofTrimWhereInput>;
  trims_none?: Maybe<RoofTrimWhereInput>;
  gutters_every?: Maybe<RainGutterWhereInput>;
  gutters_some?: Maybe<RainGutterWhereInput>;
  gutters_none?: Maybe<RainGutterWhereInput>;
  vents_every?: Maybe<RoofVentWhereInput>;
  vents_some?: Maybe<RoofVentWhereInput>;
  vents_none?: Maybe<RoofVentWhereInput>;
  structure?: Maybe<StructureWhereInput>;
  ridgeLines_every?: Maybe<LineWhereInput>;
  ridgeLines_some?: Maybe<LineWhereInput>;
  ridgeLines_none?: Maybe<LineWhereInput>;
  hipLines_every?: Maybe<LineWhereInput>;
  hipLines_some?: Maybe<LineWhereInput>;
  hipLines_none?: Maybe<LineWhereInput>;
  valleyLines_every?: Maybe<LineWhereInput>;
  valleyLines_some?: Maybe<LineWhereInput>;
  valleyLines_none?: Maybe<LineWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RoofFaceWhereInput>>;
  OR?: Maybe<Array<RoofFaceWhereInput>>;
  NOT?: Maybe<Array<RoofFaceWhereInput>>;
};

export type RoofTrim = {
  __typename?: 'RoofTrim';
  id: Scalars['ID'];
  refId: Scalars['String'];
  length: Scalars['Float'];
  overhang: Scalars['Float'];
  type: RoofTrimType;
  roofFace: RoofFace;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum RoofTrimOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  OverhangAsc = 'overhang_ASC',
  OverhangDesc = 'overhang_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export enum RoofTrimType {
  Rake = 'RAKE',
  Eave = 'EAVE'
}

export type RoofTrimWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  length_not?: Maybe<Scalars['Float']>;
  length_in?: Maybe<Array<Scalars['Float']>>;
  length_not_in?: Maybe<Array<Scalars['Float']>>;
  length_lt?: Maybe<Scalars['Float']>;
  length_lte?: Maybe<Scalars['Float']>;
  length_gt?: Maybe<Scalars['Float']>;
  length_gte?: Maybe<Scalars['Float']>;
  overhang?: Maybe<Scalars['Float']>;
  overhang_not?: Maybe<Scalars['Float']>;
  overhang_in?: Maybe<Array<Scalars['Float']>>;
  overhang_not_in?: Maybe<Array<Scalars['Float']>>;
  overhang_lt?: Maybe<Scalars['Float']>;
  overhang_lte?: Maybe<Scalars['Float']>;
  overhang_gt?: Maybe<Scalars['Float']>;
  overhang_gte?: Maybe<Scalars['Float']>;
  type?: Maybe<RoofTrimType>;
  type_not?: Maybe<RoofTrimType>;
  type_in?: Maybe<Array<RoofTrimType>>;
  type_not_in?: Maybe<Array<RoofTrimType>>;
  roofFace?: Maybe<RoofFaceWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RoofTrimWhereInput>>;
  OR?: Maybe<Array<RoofTrimWhereInput>>;
  NOT?: Maybe<Array<RoofTrimWhereInput>>;
};

export type RoofVent = {
  __typename?: 'RoofVent';
  id: Scalars['ID'];
  refId: Scalars['String'];
  materialCode?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  additionalData?: Maybe<Scalars['Json']>;
  roofFace: RoofFace;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum RoofVentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  MaterialCodeAsc = 'materialCode_ASC',
  MaterialCodeDesc = 'materialCode_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  AdditionalDataAsc = 'additionalData_ASC',
  AdditionalDataDesc = 'additionalData_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type RoofVentWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  materialCode_not?: Maybe<Scalars['String']>;
  materialCode_in?: Maybe<Array<Scalars['String']>>;
  materialCode_not_in?: Maybe<Array<Scalars['String']>>;
  materialCode_lt?: Maybe<Scalars['String']>;
  materialCode_lte?: Maybe<Scalars['String']>;
  materialCode_gt?: Maybe<Scalars['String']>;
  materialCode_gte?: Maybe<Scalars['String']>;
  materialCode_contains?: Maybe<Scalars['String']>;
  materialCode_not_contains?: Maybe<Scalars['String']>;
  materialCode_starts_with?: Maybe<Scalars['String']>;
  materialCode_not_starts_with?: Maybe<Scalars['String']>;
  materialCode_ends_with?: Maybe<Scalars['String']>;
  materialCode_not_ends_with?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Scalars['String']>>;
  category_not_in?: Maybe<Array<Scalars['String']>>;
  category_lt?: Maybe<Scalars['String']>;
  category_lte?: Maybe<Scalars['String']>;
  category_gt?: Maybe<Scalars['String']>;
  category_gte?: Maybe<Scalars['String']>;
  category_contains?: Maybe<Scalars['String']>;
  category_not_contains?: Maybe<Scalars['String']>;
  category_starts_with?: Maybe<Scalars['String']>;
  category_not_starts_with?: Maybe<Scalars['String']>;
  category_ends_with?: Maybe<Scalars['String']>;
  category_not_ends_with?: Maybe<Scalars['String']>;
  roofFace?: Maybe<RoofFaceWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RoofVentWhereInput>>;
  OR?: Maybe<Array<RoofVentWhereInput>>;
  NOT?: Maybe<Array<RoofVentWhereInput>>;
};

export type Selection = {
  __typename?: 'Selection';
  id: Scalars['ID'];
  question: Scalars['String'];
  answer: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum SelectionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  AnswerAsc = 'answer_ASC',
  AnswerDesc = 'answer_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type SelectionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  question?: Maybe<Scalars['String']>;
  question_not?: Maybe<Scalars['String']>;
  question_in?: Maybe<Array<Scalars['String']>>;
  question_not_in?: Maybe<Array<Scalars['String']>>;
  question_lt?: Maybe<Scalars['String']>;
  question_lte?: Maybe<Scalars['String']>;
  question_gt?: Maybe<Scalars['String']>;
  question_gte?: Maybe<Scalars['String']>;
  question_contains?: Maybe<Scalars['String']>;
  question_not_contains?: Maybe<Scalars['String']>;
  question_starts_with?: Maybe<Scalars['String']>;
  question_not_starts_with?: Maybe<Scalars['String']>;
  question_ends_with?: Maybe<Scalars['String']>;
  question_not_ends_with?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  answer_not?: Maybe<Scalars['String']>;
  answer_in?: Maybe<Array<Scalars['String']>>;
  answer_not_in?: Maybe<Array<Scalars['String']>>;
  answer_lt?: Maybe<Scalars['String']>;
  answer_lte?: Maybe<Scalars['String']>;
  answer_gt?: Maybe<Scalars['String']>;
  answer_gte?: Maybe<Scalars['String']>;
  answer_contains?: Maybe<Scalars['String']>;
  answer_not_contains?: Maybe<Scalars['String']>;
  answer_starts_with?: Maybe<Scalars['String']>;
  answer_not_starts_with?: Maybe<Scalars['String']>;
  answer_ends_with?: Maybe<Scalars['String']>;
  answer_not_ends_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SelectionWhereInput>>;
  OR?: Maybe<Array<SelectionWhereInput>>;
  NOT?: Maybe<Array<SelectionWhereInput>>;
};

export type Spec = {
  __typename?: 'Spec';
  id: Scalars['ID'];
  name: Scalars['String'];
  materials: Array<Scalars['String']>;
  reference?: Maybe<SpecReference>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum SpecReference {
  Walls = 'WALLS',
  Trims = 'TRIMS',
  Windows = 'WINDOWS',
  Soffits = 'SOFFITS',
  Roofs = 'ROOFS',
  Doors = 'DOORS',
  GarageDoors = 'GARAGE_DOORS',
  RoofVents = 'ROOF_VENTS',
  RoofFaces = 'ROOF_FACES',
  RoofTrims = 'ROOF_TRIMS',
  Porches = 'PORCHES',
  Chimneys = 'CHIMNEYS'
}

export type SpecWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  reference?: Maybe<SpecReference>;
  reference_not?: Maybe<SpecReference>;
  reference_in?: Maybe<Array<SpecReference>>;
  reference_not_in?: Maybe<Array<SpecReference>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SpecWhereInput>>;
  OR?: Maybe<Array<SpecWhereInput>>;
  NOT?: Maybe<Array<SpecWhereInput>>;
};

export type State = {
  __typename?: 'State';
  id: Scalars['ID'];
  name: Scalars['String'];
  counties?: Maybe<Array<County>>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type StateCountiesArgs = {
  where?: Maybe<CountyWhereInput>;
  orderBy?: Maybe<CountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StateWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  counties_every?: Maybe<CountyWhereInput>;
  counties_some?: Maybe<CountyWhereInput>;
  counties_none?: Maybe<CountyWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<StateWhereInput>>;
  OR?: Maybe<Array<StateWhereInput>>;
  NOT?: Maybe<Array<StateWhereInput>>;
};

export type Structure = {
  __typename?: 'Structure';
  id: Scalars['ID'];
  refId: Scalars['String'];
  roofRefId: Scalars['String'];
  roofAdditionalData?: Maybe<Scalars['Json']>;
  roofArea?: Maybe<Scalars['Float']>;
  roofHoleArea?: Maybe<Scalars['Float']>;
  hipLength?: Maybe<Scalars['Float']>;
  ridgeLength?: Maybe<Scalars['Float']>;
  rakeLength?: Maybe<Scalars['Float']>;
  eaveLength?: Maybe<Scalars['Float']>;
  valleyLength?: Maybe<Scalars['Float']>;
  stepFlashLength?: Maybe<Scalars['Float']>;
  flashLength?: Maybe<Scalars['Float']>;
  roofFaces?: Maybe<Array<RoofFace>>;
  walls?: Maybe<Array<Wall>>;
  porches?: Maybe<Array<Porch>>;
  chimneys?: Maybe<Array<Chimney>>;
  propertySpec: PropertySpec;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type StructureRoofFacesArgs = {
  where?: Maybe<RoofFaceWhereInput>;
  orderBy?: Maybe<RoofFaceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StructureWallsArgs = {
  where?: Maybe<WallWhereInput>;
  orderBy?: Maybe<WallOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StructurePorchesArgs = {
  where?: Maybe<PorchWhereInput>;
  orderBy?: Maybe<PorchOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StructureChimneysArgs = {
  where?: Maybe<ChimneyWhereInput>;
  orderBy?: Maybe<ChimneyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum StructureOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  RoofRefIdAsc = 'roofRefId_ASC',
  RoofRefIdDesc = 'roofRefId_DESC',
  RoofAdditionalDataAsc = 'roofAdditionalData_ASC',
  RoofAdditionalDataDesc = 'roofAdditionalData_DESC',
  RoofAreaAsc = 'roofArea_ASC',
  RoofAreaDesc = 'roofArea_DESC',
  RoofHoleAreaAsc = 'roofHoleArea_ASC',
  RoofHoleAreaDesc = 'roofHoleArea_DESC',
  HipLengthAsc = 'hipLength_ASC',
  HipLengthDesc = 'hipLength_DESC',
  RidgeLengthAsc = 'ridgeLength_ASC',
  RidgeLengthDesc = 'ridgeLength_DESC',
  RakeLengthAsc = 'rakeLength_ASC',
  RakeLengthDesc = 'rakeLength_DESC',
  EaveLengthAsc = 'eaveLength_ASC',
  EaveLengthDesc = 'eaveLength_DESC',
  ValleyLengthAsc = 'valleyLength_ASC',
  ValleyLengthDesc = 'valleyLength_DESC',
  StepFlashLengthAsc = 'stepFlashLength_ASC',
  StepFlashLengthDesc = 'stepFlashLength_DESC',
  FlashLengthAsc = 'flashLength_ASC',
  FlashLengthDesc = 'flashLength_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type StructureWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  roofRefId?: Maybe<Scalars['String']>;
  roofRefId_not?: Maybe<Scalars['String']>;
  roofRefId_in?: Maybe<Array<Scalars['String']>>;
  roofRefId_not_in?: Maybe<Array<Scalars['String']>>;
  roofRefId_lt?: Maybe<Scalars['String']>;
  roofRefId_lte?: Maybe<Scalars['String']>;
  roofRefId_gt?: Maybe<Scalars['String']>;
  roofRefId_gte?: Maybe<Scalars['String']>;
  roofRefId_contains?: Maybe<Scalars['String']>;
  roofRefId_not_contains?: Maybe<Scalars['String']>;
  roofRefId_starts_with?: Maybe<Scalars['String']>;
  roofRefId_not_starts_with?: Maybe<Scalars['String']>;
  roofRefId_ends_with?: Maybe<Scalars['String']>;
  roofRefId_not_ends_with?: Maybe<Scalars['String']>;
  roofArea?: Maybe<Scalars['Float']>;
  roofArea_not?: Maybe<Scalars['Float']>;
  roofArea_in?: Maybe<Array<Scalars['Float']>>;
  roofArea_not_in?: Maybe<Array<Scalars['Float']>>;
  roofArea_lt?: Maybe<Scalars['Float']>;
  roofArea_lte?: Maybe<Scalars['Float']>;
  roofArea_gt?: Maybe<Scalars['Float']>;
  roofArea_gte?: Maybe<Scalars['Float']>;
  roofHoleArea?: Maybe<Scalars['Float']>;
  roofHoleArea_not?: Maybe<Scalars['Float']>;
  roofHoleArea_in?: Maybe<Array<Scalars['Float']>>;
  roofHoleArea_not_in?: Maybe<Array<Scalars['Float']>>;
  roofHoleArea_lt?: Maybe<Scalars['Float']>;
  roofHoleArea_lte?: Maybe<Scalars['Float']>;
  roofHoleArea_gt?: Maybe<Scalars['Float']>;
  roofHoleArea_gte?: Maybe<Scalars['Float']>;
  hipLength?: Maybe<Scalars['Float']>;
  hipLength_not?: Maybe<Scalars['Float']>;
  hipLength_in?: Maybe<Array<Scalars['Float']>>;
  hipLength_not_in?: Maybe<Array<Scalars['Float']>>;
  hipLength_lt?: Maybe<Scalars['Float']>;
  hipLength_lte?: Maybe<Scalars['Float']>;
  hipLength_gt?: Maybe<Scalars['Float']>;
  hipLength_gte?: Maybe<Scalars['Float']>;
  ridgeLength?: Maybe<Scalars['Float']>;
  ridgeLength_not?: Maybe<Scalars['Float']>;
  ridgeLength_in?: Maybe<Array<Scalars['Float']>>;
  ridgeLength_not_in?: Maybe<Array<Scalars['Float']>>;
  ridgeLength_lt?: Maybe<Scalars['Float']>;
  ridgeLength_lte?: Maybe<Scalars['Float']>;
  ridgeLength_gt?: Maybe<Scalars['Float']>;
  ridgeLength_gte?: Maybe<Scalars['Float']>;
  rakeLength?: Maybe<Scalars['Float']>;
  rakeLength_not?: Maybe<Scalars['Float']>;
  rakeLength_in?: Maybe<Array<Scalars['Float']>>;
  rakeLength_not_in?: Maybe<Array<Scalars['Float']>>;
  rakeLength_lt?: Maybe<Scalars['Float']>;
  rakeLength_lte?: Maybe<Scalars['Float']>;
  rakeLength_gt?: Maybe<Scalars['Float']>;
  rakeLength_gte?: Maybe<Scalars['Float']>;
  eaveLength?: Maybe<Scalars['Float']>;
  eaveLength_not?: Maybe<Scalars['Float']>;
  eaveLength_in?: Maybe<Array<Scalars['Float']>>;
  eaveLength_not_in?: Maybe<Array<Scalars['Float']>>;
  eaveLength_lt?: Maybe<Scalars['Float']>;
  eaveLength_lte?: Maybe<Scalars['Float']>;
  eaveLength_gt?: Maybe<Scalars['Float']>;
  eaveLength_gte?: Maybe<Scalars['Float']>;
  valleyLength?: Maybe<Scalars['Float']>;
  valleyLength_not?: Maybe<Scalars['Float']>;
  valleyLength_in?: Maybe<Array<Scalars['Float']>>;
  valleyLength_not_in?: Maybe<Array<Scalars['Float']>>;
  valleyLength_lt?: Maybe<Scalars['Float']>;
  valleyLength_lte?: Maybe<Scalars['Float']>;
  valleyLength_gt?: Maybe<Scalars['Float']>;
  valleyLength_gte?: Maybe<Scalars['Float']>;
  stepFlashLength?: Maybe<Scalars['Float']>;
  stepFlashLength_not?: Maybe<Scalars['Float']>;
  stepFlashLength_in?: Maybe<Array<Scalars['Float']>>;
  stepFlashLength_not_in?: Maybe<Array<Scalars['Float']>>;
  stepFlashLength_lt?: Maybe<Scalars['Float']>;
  stepFlashLength_lte?: Maybe<Scalars['Float']>;
  stepFlashLength_gt?: Maybe<Scalars['Float']>;
  stepFlashLength_gte?: Maybe<Scalars['Float']>;
  flashLength?: Maybe<Scalars['Float']>;
  flashLength_not?: Maybe<Scalars['Float']>;
  flashLength_in?: Maybe<Array<Scalars['Float']>>;
  flashLength_not_in?: Maybe<Array<Scalars['Float']>>;
  flashLength_lt?: Maybe<Scalars['Float']>;
  flashLength_lte?: Maybe<Scalars['Float']>;
  flashLength_gt?: Maybe<Scalars['Float']>;
  flashLength_gte?: Maybe<Scalars['Float']>;
  roofFaces_every?: Maybe<RoofFaceWhereInput>;
  roofFaces_some?: Maybe<RoofFaceWhereInput>;
  roofFaces_none?: Maybe<RoofFaceWhereInput>;
  walls_every?: Maybe<WallWhereInput>;
  walls_some?: Maybe<WallWhereInput>;
  walls_none?: Maybe<WallWhereInput>;
  porches_every?: Maybe<PorchWhereInput>;
  porches_some?: Maybe<PorchWhereInput>;
  porches_none?: Maybe<PorchWhereInput>;
  chimneys_every?: Maybe<ChimneyWhereInput>;
  chimneys_some?: Maybe<ChimneyWhereInput>;
  chimneys_none?: Maybe<ChimneyWhereInput>;
  propertySpec?: Maybe<PropertySpecWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<StructureWhereInput>>;
  OR?: Maybe<Array<StructureWhereInput>>;
  NOT?: Maybe<Array<StructureWhereInput>>;
};

export type SubOption = {
  __typename?: 'SubOption';
  id: Scalars['ID'];
  description: Scalars['String'];
  option?: Maybe<Option>;
  linksTo?: Maybe<Scalars['ID']>;
  specName3dModel?: Maybe<Spec>;
  trades?: Maybe<Array<Trade>>;
  jobSpecification?: Maybe<JobSpecification>;
  modal?: Maybe<Modal>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type SubOptionTradesArgs = {
  where?: Maybe<TradeWhereInput>;
  orderBy?: Maybe<TradeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum SubOptionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  LinksToAsc = 'linksTo_ASC',
  LinksToDesc = 'linksTo_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type SubOptionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  option?: Maybe<OptionWhereInput>;
  linksTo?: Maybe<Scalars['ID']>;
  linksTo_not?: Maybe<Scalars['ID']>;
  linksTo_in?: Maybe<Array<Scalars['ID']>>;
  linksTo_not_in?: Maybe<Array<Scalars['ID']>>;
  linksTo_lt?: Maybe<Scalars['ID']>;
  linksTo_lte?: Maybe<Scalars['ID']>;
  linksTo_gt?: Maybe<Scalars['ID']>;
  linksTo_gte?: Maybe<Scalars['ID']>;
  linksTo_contains?: Maybe<Scalars['ID']>;
  linksTo_not_contains?: Maybe<Scalars['ID']>;
  linksTo_starts_with?: Maybe<Scalars['ID']>;
  linksTo_not_starts_with?: Maybe<Scalars['ID']>;
  linksTo_ends_with?: Maybe<Scalars['ID']>;
  linksTo_not_ends_with?: Maybe<Scalars['ID']>;
  specName3dModel?: Maybe<SpecWhereInput>;
  trades_every?: Maybe<TradeWhereInput>;
  trades_some?: Maybe<TradeWhereInput>;
  trades_none?: Maybe<TradeWhereInput>;
  jobSpecification?: Maybe<JobSpecificationWhereInput>;
  modal?: Maybe<ModalWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SubOptionWhereInput>>;
  OR?: Maybe<Array<SubOptionWhereInput>>;
  NOT?: Maybe<Array<SubOptionWhereInput>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated?: Maybe<Notification>;
  bidMessageCreated?: Maybe<BidMessage>;
};

export enum SubscriptionPlan {
  Free = 'FREE',
  Paid = 'PAID'
}

export type SuggestedEmail = {
  __typename?: 'suggestedEmail';
  id: Scalars['ID'];
  email: Scalars['String'];
  job: Job;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum SuggestedEmailOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type SuggestedEmailWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  job?: Maybe<JobWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SuggestedEmailWhereInput>>;
  OR?: Maybe<Array<SuggestedEmailWhereInput>>;
  NOT?: Maybe<Array<SuggestedEmailWhereInput>>;
};

export type SuggestJobPayload = {
  __typename?: 'SuggestJobPayload';
  email?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type Trade = {
  __typename?: 'Trade';
  id: Scalars['ID'];
  group?: Maybe<TradeGroup>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum TradeGroup {
  Roofing = 'ROOFING',
  Windows = 'WINDOWS',
  Doors = 'DOORS',
  Chimney = 'CHIMNEY',
  GarageDoors = 'GARAGE_DOORS',
  Fencing = 'FENCING',
  TreesAndShrubs = 'TREES_AND_SHRUBS',
  Lawn = 'LAWN',
  Solar = 'SOLAR',
  Insulation = 'INSULATION',
  PressureWashing = 'PRESSURE_WASHING',
  Painting = 'PAINTING',
  Siding = 'SIDING',
  WindowCoverings = 'WINDOW_COVERINGS',
  WindowScreens = 'WINDOW_SCREENS',
  WindowWashing = 'WINDOW_WASHING',
  SprinklersAndIrrigation = 'SPRINKLERS_AND_IRRIGATION',
  PestControl = 'PEST_CONTROL',
  Pool = 'POOL',
  OutdoorFlooring = 'OUTDOOR_FLOORING',
  Gutters = 'GUTTERS',
  HouseCleaning = 'HOUSE_CLEANING',
  CarpetCleaning = 'CARPET_CLEANING',
  HandymanRepair = 'HANDYMAN_REPAIR',
  HeatingAndAirConditioning = 'HEATING_AND_AIR_CONDITIONING',
  Electrical = 'ELECTRICAL',
  Plumbing = 'PLUMBING',
  InteriorPaintAndDrywall = 'INTERIOR_PAINT_AND_DRYWALL',
  Flooring = 'FLOORING'
}

export enum TradeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type TradeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  group?: Maybe<TradeGroup>;
  group_not?: Maybe<TradeGroup>;
  group_in?: Maybe<Array<TradeGroup>>;
  group_not_in?: Maybe<Array<TradeGroup>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<TradeWhereInput>>;
  OR?: Maybe<Array<TradeWhereInput>>;
  NOT?: Maybe<Array<TradeWhereInput>>;
};

export type UpdatePushTokenPayload = {
  __typename?: 'UpdatePushTokenPayload';
  pushTokens?: Maybe<Array<PushToken>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  role: UserRole;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  billingStatus: BillingStatus;
  billedMonthly?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Array<Notification>>;
  createdAt: Scalars['DateTime'];
  receiveEmailNotifications: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  oauth?: Maybe<Scalars['Boolean']>;
  pushNotificationConfig?: Maybe<PushNotificationConfig>;
  fullName?: Maybe<Scalars['String']>;
  oneTimeKey?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Property>>;
  notificationCount?: Maybe<Scalars['Int']>;
  ongoingJobCount?: Maybe<Scalars['Int']>;
  biddableJobCount?: Maybe<Scalars['Int']>;
};


export type UserPaymentMethodsArgs = {
  where?: Maybe<PaymentMethodWhereInput>;
  orderBy?: Maybe<PaymentMethodOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserNotificationsArgs = {
  where?: Maybe<NotificationWhereInput>;
  orderBy?: Maybe<NotificationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  OauthAsc = 'oauth_ASC',
  OauthDesc = 'oauth_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  ReceiveEmailNotificationsAsc = 'receiveEmailNotifications_ASC',
  ReceiveEmailNotificationsDesc = 'receiveEmailNotifications_DESC',
  StripeCustomerIdAsc = 'stripeCustomerId_ASC',
  StripeCustomerIdDesc = 'stripeCustomerId_DESC',
  StripeSubscriptionIdAsc = 'stripeSubscriptionId_ASC',
  StripeSubscriptionIdDesc = 'stripeSubscriptionId_DESC',
  BillingStatusAsc = 'billingStatus_ASC',
  BillingStatusDesc = 'billingStatus_DESC',
  BilledMonthlyAsc = 'billedMonthly_ASC',
  BilledMonthlyDesc = 'billedMonthly_DESC',
  VerifiedAsc = 'verified_ASC',
  VerifiedDesc = 'verified_DESC',
  VerificationCodeAsc = 'verificationCode_ASC',
  VerificationCodeDesc = 'verificationCode_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  PartnerAsc = 'partner_ASC',
  PartnerDesc = 'partner_DESC',
  OneTimeKeyAsc = 'oneTimeKey_ASC',
  OneTimeKeyDesc = 'oneTimeKey_DESC'
}

export enum UserRole {
  Homeowner = 'HOMEOWNER',
  Contractor = 'CONTRACTOR',
  Admin = 'ADMIN'
}

export type UserWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_not?: Maybe<Scalars['String']>;
  password_in?: Maybe<Array<Scalars['String']>>;
  password_not_in?: Maybe<Array<Scalars['String']>>;
  password_lt?: Maybe<Scalars['String']>;
  password_lte?: Maybe<Scalars['String']>;
  password_gt?: Maybe<Scalars['String']>;
  password_gte?: Maybe<Scalars['String']>;
  password_contains?: Maybe<Scalars['String']>;
  password_not_contains?: Maybe<Scalars['String']>;
  password_starts_with?: Maybe<Scalars['String']>;
  password_not_starts_with?: Maybe<Scalars['String']>;
  password_ends_with?: Maybe<Scalars['String']>;
  password_not_ends_with?: Maybe<Scalars['String']>;
  oauth?: Maybe<Scalars['Boolean']>;
  oauth_not?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UserRole>;
  role_not?: Maybe<UserRole>;
  role_in?: Maybe<Array<UserRole>>;
  role_not_in?: Maybe<Array<UserRole>>;
  notifications_every?: Maybe<NotificationWhereInput>;
  notifications_some?: Maybe<NotificationWhereInput>;
  notifications_none?: Maybe<NotificationWhereInput>;
  pushNotificationConfig?: Maybe<PushNotificationConfigWhereInput>;
  receiveEmailNotifications?: Maybe<Scalars['Boolean']>;
  receiveEmailNotifications_not?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomerId_not?: Maybe<Scalars['String']>;
  stripeCustomerId_in?: Maybe<Array<Scalars['String']>>;
  stripeCustomerId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeCustomerId_lt?: Maybe<Scalars['String']>;
  stripeCustomerId_lte?: Maybe<Scalars['String']>;
  stripeCustomerId_gt?: Maybe<Scalars['String']>;
  stripeCustomerId_gte?: Maybe<Scalars['String']>;
  stripeCustomerId_contains?: Maybe<Scalars['String']>;
  stripeCustomerId_not_contains?: Maybe<Scalars['String']>;
  stripeCustomerId_starts_with?: Maybe<Scalars['String']>;
  stripeCustomerId_not_starts_with?: Maybe<Scalars['String']>;
  stripeCustomerId_ends_with?: Maybe<Scalars['String']>;
  stripeCustomerId_not_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not?: Maybe<Scalars['String']>;
  stripeSubscriptionId_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionId_lt?: Maybe<Scalars['String']>;
  stripeSubscriptionId_lte?: Maybe<Scalars['String']>;
  stripeSubscriptionId_gt?: Maybe<Scalars['String']>;
  stripeSubscriptionId_gte?: Maybe<Scalars['String']>;
  stripeSubscriptionId_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionId_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_ends_with?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<BillingStatus>;
  billingStatus_not?: Maybe<BillingStatus>;
  billingStatus_in?: Maybe<Array<BillingStatus>>;
  billingStatus_not_in?: Maybe<Array<BillingStatus>>;
  billedMonthly?: Maybe<Scalars['Boolean']>;
  billedMonthly_not?: Maybe<Scalars['Boolean']>;
  homeowner?: Maybe<HomeownerWhereInput>;
  contractor?: Maybe<ContractorWhereInput>;
  paymentMethods_every?: Maybe<PaymentMethodWhereInput>;
  paymentMethods_some?: Maybe<PaymentMethodWhereInput>;
  paymentMethods_none?: Maybe<PaymentMethodWhereInput>;
  charges_every?: Maybe<ChargeWhereInput>;
  charges_some?: Maybe<ChargeWhereInput>;
  charges_none?: Maybe<ChargeWhereInput>;
  orders_every?: Maybe<OrderWhereInput>;
  orders_some?: Maybe<OrderWhereInput>;
  orders_none?: Maybe<OrderWhereInput>;
  verified?: Maybe<Scalars['Boolean']>;
  verified_not?: Maybe<Scalars['Boolean']>;
  verificationCode?: Maybe<Scalars['String']>;
  verificationCode_not?: Maybe<Scalars['String']>;
  verificationCode_in?: Maybe<Array<Scalars['String']>>;
  verificationCode_not_in?: Maybe<Array<Scalars['String']>>;
  verificationCode_lt?: Maybe<Scalars['String']>;
  verificationCode_lte?: Maybe<Scalars['String']>;
  verificationCode_gt?: Maybe<Scalars['String']>;
  verificationCode_gte?: Maybe<Scalars['String']>;
  verificationCode_contains?: Maybe<Scalars['String']>;
  verificationCode_not_contains?: Maybe<Scalars['String']>;
  verificationCode_starts_with?: Maybe<Scalars['String']>;
  verificationCode_not_starts_with?: Maybe<Scalars['String']>;
  verificationCode_ends_with?: Maybe<Scalars['String']>;
  verificationCode_not_ends_with?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  rating_not?: Maybe<Scalars['Float']>;
  rating_in?: Maybe<Array<Scalars['Float']>>;
  rating_not_in?: Maybe<Array<Scalars['Float']>>;
  rating_lt?: Maybe<Scalars['Float']>;
  rating_lte?: Maybe<Scalars['Float']>;
  rating_gt?: Maybe<Scalars['Float']>;
  rating_gte?: Maybe<Scalars['Float']>;
  partner?: Maybe<PartnerName>;
  partner_not?: Maybe<PartnerName>;
  partner_in?: Maybe<Array<PartnerName>>;
  partner_not_in?: Maybe<Array<PartnerName>>;
  oneTimeKey?: Maybe<Scalars['String']>;
  oneTimeKey_not?: Maybe<Scalars['String']>;
  oneTimeKey_in?: Maybe<Array<Scalars['String']>>;
  oneTimeKey_not_in?: Maybe<Array<Scalars['String']>>;
  oneTimeKey_lt?: Maybe<Scalars['String']>;
  oneTimeKey_lte?: Maybe<Scalars['String']>;
  oneTimeKey_gt?: Maybe<Scalars['String']>;
  oneTimeKey_gte?: Maybe<Scalars['String']>;
  oneTimeKey_contains?: Maybe<Scalars['String']>;
  oneTimeKey_not_contains?: Maybe<Scalars['String']>;
  oneTimeKey_starts_with?: Maybe<Scalars['String']>;
  oneTimeKey_not_starts_with?: Maybe<Scalars['String']>;
  oneTimeKey_ends_with?: Maybe<Scalars['String']>;
  oneTimeKey_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
};

export type ValidatePromoPayload = {
  __typename?: 'ValidatePromoPayload';
  finalPrice?: Maybe<Scalars['String']>;
};

export type VerificationPayload = {
  __typename?: 'VerificationPayload';
  verified?: Maybe<Scalars['Boolean']>;
};

export type Wall = {
  __typename?: 'Wall';
  id: Scalars['ID'];
  refId: Scalars['String'];
  area?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  maxWidth?: Maybe<Scalars['Float']>;
  maxHeight?: Maybe<Scalars['Float']>;
  openingArea?: Maybe<Scalars['Float']>;
  openingPerimeter?: Maybe<Scalars['Float']>;
  isChimney: Scalars['Boolean'];
  windows?: Maybe<Array<Window>>;
  doors?: Maybe<Array<Door>>;
  structure: Structure;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};


export type WallWindowsArgs = {
  where?: Maybe<WindowWhereInput>;
  orderBy?: Maybe<WindowOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type WallDoorsArgs = {
  where?: Maybe<DoorWhereInput>;
  orderBy?: Maybe<DoorOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum WallOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  MaterialAsc = 'material_ASC',
  MaterialDesc = 'material_DESC',
  MaterialCodeAsc = 'materialCode_ASC',
  MaterialCodeDesc = 'materialCode_DESC',
  MaxWidthAsc = 'maxWidth_ASC',
  MaxWidthDesc = 'maxWidth_DESC',
  MaxHeightAsc = 'maxHeight_ASC',
  MaxHeightDesc = 'maxHeight_DESC',
  OpeningAreaAsc = 'openingArea_ASC',
  OpeningAreaDesc = 'openingArea_DESC',
  OpeningPerimeterAsc = 'openingPerimeter_ASC',
  OpeningPerimeterDesc = 'openingPerimeter_DESC',
  IsChimneyAsc = 'isChimney_ASC',
  IsChimneyDesc = 'isChimney_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type WallWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  area_not?: Maybe<Scalars['Float']>;
  area_in?: Maybe<Array<Scalars['Float']>>;
  area_not_in?: Maybe<Array<Scalars['Float']>>;
  area_lt?: Maybe<Scalars['Float']>;
  area_lte?: Maybe<Scalars['Float']>;
  area_gt?: Maybe<Scalars['Float']>;
  area_gte?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['String']>;
  material_not?: Maybe<Scalars['String']>;
  material_in?: Maybe<Array<Scalars['String']>>;
  material_not_in?: Maybe<Array<Scalars['String']>>;
  material_lt?: Maybe<Scalars['String']>;
  material_lte?: Maybe<Scalars['String']>;
  material_gt?: Maybe<Scalars['String']>;
  material_gte?: Maybe<Scalars['String']>;
  material_contains?: Maybe<Scalars['String']>;
  material_not_contains?: Maybe<Scalars['String']>;
  material_starts_with?: Maybe<Scalars['String']>;
  material_not_starts_with?: Maybe<Scalars['String']>;
  material_ends_with?: Maybe<Scalars['String']>;
  material_not_ends_with?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  materialCode_not?: Maybe<Scalars['String']>;
  materialCode_in?: Maybe<Array<Scalars['String']>>;
  materialCode_not_in?: Maybe<Array<Scalars['String']>>;
  materialCode_lt?: Maybe<Scalars['String']>;
  materialCode_lte?: Maybe<Scalars['String']>;
  materialCode_gt?: Maybe<Scalars['String']>;
  materialCode_gte?: Maybe<Scalars['String']>;
  materialCode_contains?: Maybe<Scalars['String']>;
  materialCode_not_contains?: Maybe<Scalars['String']>;
  materialCode_starts_with?: Maybe<Scalars['String']>;
  materialCode_not_starts_with?: Maybe<Scalars['String']>;
  materialCode_ends_with?: Maybe<Scalars['String']>;
  materialCode_not_ends_with?: Maybe<Scalars['String']>;
  maxWidth?: Maybe<Scalars['Float']>;
  maxWidth_not?: Maybe<Scalars['Float']>;
  maxWidth_in?: Maybe<Array<Scalars['Float']>>;
  maxWidth_not_in?: Maybe<Array<Scalars['Float']>>;
  maxWidth_lt?: Maybe<Scalars['Float']>;
  maxWidth_lte?: Maybe<Scalars['Float']>;
  maxWidth_gt?: Maybe<Scalars['Float']>;
  maxWidth_gte?: Maybe<Scalars['Float']>;
  maxHeight?: Maybe<Scalars['Float']>;
  maxHeight_not?: Maybe<Scalars['Float']>;
  maxHeight_in?: Maybe<Array<Scalars['Float']>>;
  maxHeight_not_in?: Maybe<Array<Scalars['Float']>>;
  maxHeight_lt?: Maybe<Scalars['Float']>;
  maxHeight_lte?: Maybe<Scalars['Float']>;
  maxHeight_gt?: Maybe<Scalars['Float']>;
  maxHeight_gte?: Maybe<Scalars['Float']>;
  openingArea?: Maybe<Scalars['Float']>;
  openingArea_not?: Maybe<Scalars['Float']>;
  openingArea_in?: Maybe<Array<Scalars['Float']>>;
  openingArea_not_in?: Maybe<Array<Scalars['Float']>>;
  openingArea_lt?: Maybe<Scalars['Float']>;
  openingArea_lte?: Maybe<Scalars['Float']>;
  openingArea_gt?: Maybe<Scalars['Float']>;
  openingArea_gte?: Maybe<Scalars['Float']>;
  openingPerimeter?: Maybe<Scalars['Float']>;
  openingPerimeter_not?: Maybe<Scalars['Float']>;
  openingPerimeter_in?: Maybe<Array<Scalars['Float']>>;
  openingPerimeter_not_in?: Maybe<Array<Scalars['Float']>>;
  openingPerimeter_lt?: Maybe<Scalars['Float']>;
  openingPerimeter_lte?: Maybe<Scalars['Float']>;
  openingPerimeter_gt?: Maybe<Scalars['Float']>;
  openingPerimeter_gte?: Maybe<Scalars['Float']>;
  isChimney?: Maybe<Scalars['Boolean']>;
  isChimney_not?: Maybe<Scalars['Boolean']>;
  windows_every?: Maybe<WindowWhereInput>;
  windows_some?: Maybe<WindowWhereInput>;
  windows_none?: Maybe<WindowWhereInput>;
  doors_every?: Maybe<DoorWhereInput>;
  doors_some?: Maybe<DoorWhereInput>;
  doors_none?: Maybe<DoorWhereInput>;
  structure?: Maybe<StructureWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<WallWhereInput>>;
  OR?: Maybe<Array<WallWhereInput>>;
  NOT?: Maybe<Array<WallWhereInput>>;
};

export type Window = {
  __typename?: 'Window';
  id: Scalars['ID'];
  refId: Scalars['String'];
  widthInInches: Scalars['Float'];
  heightInInches: Scalars['Float'];
  shape?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  perimeter?: Maybe<Scalars['Float']>;
  wall: Wall;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum WindowOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RefIdAsc = 'refId_ASC',
  RefIdDesc = 'refId_DESC',
  WidthInInchesAsc = 'widthInInches_ASC',
  WidthInInchesDesc = 'widthInInches_DESC',
  HeightInInchesAsc = 'heightInInches_ASC',
  HeightInInchesDesc = 'heightInInches_DESC',
  ShapeAsc = 'shape_ASC',
  ShapeDesc = 'shape_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  PerimeterAsc = 'perimeter_ASC',
  PerimeterDesc = 'perimeter_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type WindowWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  refId_not?: Maybe<Scalars['String']>;
  refId_in?: Maybe<Array<Scalars['String']>>;
  refId_not_in?: Maybe<Array<Scalars['String']>>;
  refId_lt?: Maybe<Scalars['String']>;
  refId_lte?: Maybe<Scalars['String']>;
  refId_gt?: Maybe<Scalars['String']>;
  refId_gte?: Maybe<Scalars['String']>;
  refId_contains?: Maybe<Scalars['String']>;
  refId_not_contains?: Maybe<Scalars['String']>;
  refId_starts_with?: Maybe<Scalars['String']>;
  refId_not_starts_with?: Maybe<Scalars['String']>;
  refId_ends_with?: Maybe<Scalars['String']>;
  refId_not_ends_with?: Maybe<Scalars['String']>;
  widthInInches?: Maybe<Scalars['Float']>;
  widthInInches_not?: Maybe<Scalars['Float']>;
  widthInInches_in?: Maybe<Array<Scalars['Float']>>;
  widthInInches_not_in?: Maybe<Array<Scalars['Float']>>;
  widthInInches_lt?: Maybe<Scalars['Float']>;
  widthInInches_lte?: Maybe<Scalars['Float']>;
  widthInInches_gt?: Maybe<Scalars['Float']>;
  widthInInches_gte?: Maybe<Scalars['Float']>;
  heightInInches?: Maybe<Scalars['Float']>;
  heightInInches_not?: Maybe<Scalars['Float']>;
  heightInInches_in?: Maybe<Array<Scalars['Float']>>;
  heightInInches_not_in?: Maybe<Array<Scalars['Float']>>;
  heightInInches_lt?: Maybe<Scalars['Float']>;
  heightInInches_lte?: Maybe<Scalars['Float']>;
  heightInInches_gt?: Maybe<Scalars['Float']>;
  heightInInches_gte?: Maybe<Scalars['Float']>;
  shape?: Maybe<Scalars['String']>;
  shape_not?: Maybe<Scalars['String']>;
  shape_in?: Maybe<Array<Scalars['String']>>;
  shape_not_in?: Maybe<Array<Scalars['String']>>;
  shape_lt?: Maybe<Scalars['String']>;
  shape_lte?: Maybe<Scalars['String']>;
  shape_gt?: Maybe<Scalars['String']>;
  shape_gte?: Maybe<Scalars['String']>;
  shape_contains?: Maybe<Scalars['String']>;
  shape_not_contains?: Maybe<Scalars['String']>;
  shape_starts_with?: Maybe<Scalars['String']>;
  shape_not_starts_with?: Maybe<Scalars['String']>;
  shape_ends_with?: Maybe<Scalars['String']>;
  shape_not_ends_with?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  area_not?: Maybe<Scalars['Float']>;
  area_in?: Maybe<Array<Scalars['Float']>>;
  area_not_in?: Maybe<Array<Scalars['Float']>>;
  area_lt?: Maybe<Scalars['Float']>;
  area_lte?: Maybe<Scalars['Float']>;
  area_gt?: Maybe<Scalars['Float']>;
  area_gte?: Maybe<Scalars['Float']>;
  perimeter?: Maybe<Scalars['Float']>;
  perimeter_not?: Maybe<Scalars['Float']>;
  perimeter_in?: Maybe<Array<Scalars['Float']>>;
  perimeter_not_in?: Maybe<Array<Scalars['Float']>>;
  perimeter_lt?: Maybe<Scalars['Float']>;
  perimeter_lte?: Maybe<Scalars['Float']>;
  perimeter_gt?: Maybe<Scalars['Float']>;
  perimeter_gte?: Maybe<Scalars['Float']>;
  wall?: Maybe<WallWhereInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<WindowWhereInput>>;
  OR?: Maybe<Array<WindowWhereInput>>;
  NOT?: Maybe<Array<WindowWhereInput>>;
};

export type HomeownerDashboardFragFragment = (
  { __typename?: 'Homeowner' }
  & Pick<Homeowner, 'id'>
  & { properties?: Maybe<Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'designCenterImageUrl' | 'houseNumber' | 'streetName' | 'ready'>
    & { jobs?: Maybe<Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'date' | 'thumbnailSlug' | 'hiredOn' | 'title'>
      & { hiredBid?: Maybe<(
        { __typename?: 'Bid' }
        & Pick<Bid, 'id'>
      )>, bids?: Maybe<Array<(
        { __typename?: 'Bid' }
        & Pick<Bid, 'id' | 'status'>
      )>> }
    )>> }
  )>> }
);

export type HomeownerDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeownerDashboardQuery = (
  { __typename?: 'Query' }
  & { homeowner?: Maybe<(
    { __typename?: 'Homeowner' }
    & HomeownerDashboardFragFragment
  )> }
);

export type ContractorDashboardFragFragment = (
  { __typename?: 'ContractorJob' }
  & Pick<ContractorJob, 'id' | 'date' | 'thumbnailSlug' | 'title'>
  & { property?: Maybe<(
    { __typename?: 'BiddableProperty' }
    & Pick<BiddableProperty, 'id' | 'zipCode' | 'city' | 'state' | 'designCenterImageUrl' | 'viewerImageUrl'>
  ) | (
    { __typename?: 'HiredProperty' }
    & Pick<HiredProperty, 'id' | 'zipCode' | 'city' | 'state' | 'designCenterImageUrl' | 'viewerImageUrl'>
  )> }
);

export type ContractorDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractorDashboardQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractorOngoingJobsCount' | 'contractorPendingBidsCount' | 'contractorBiddableJobsCount'>
  & { biddableJobs?: Maybe<Array<(
    { __typename?: 'ContractorJob' }
    & ContractorDashboardFragFragment
  )>>, ongoingJobs?: Maybe<Array<(
    { __typename?: 'ContractorJob' }
    & ContractorDashboardFragFragment
  )>> }
);

export type ContractorOrderedMeasurementsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractorOrderedMeasurementsQuery = (
  { __typename?: 'Query' }
  & { contractorOrderedMeasurements?: Maybe<Array<(
    { __typename?: 'ContractorOrderedMeasurement' }
    & Pick<ContractorOrderedMeasurement, 'id' | 'invitedEmails' | 'createdAt'>
    & { property?: Maybe<(
      { __typename?: 'OrderedMeasurementProperty' }
      & Pick<OrderedMeasurementProperty, 'id' | 'houseNumber' | 'streetName' | 'city' | 'state' | 'zipCode' | 'ready' | 'renoworksId' | 'legacyHomeSpecId' | 'renoworksDisplayId' | 'newViewer'>
      & { propertySpec?: Maybe<(
        { __typename?: 'PropertySpec' }
        & PropertySpecsFragmentFragment
      )> }
    )> }
  )>> }
);

export type AdminDealsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDealsQuery = (
  { __typename?: 'Query' }
  & { adminDeals?: Maybe<Array<(
    { __typename?: 'AdminDeal' }
    & Pick<AdminDeal, 'id' | 'name' | 'national' | 'code' | 'multiplier' | 'multiplierDescription' | 'priceInCents' | 'image' | 'title' | 'description' | 'frequency' | 'landingUrl' | 'visible' | 'groupDiscountPercentage'>
    & { zipCodes?: Maybe<Array<(
      { __typename?: 'DealZipCode' }
      & Pick<DealZipCode, 'id' | 'code'>
    )>>, bidders?: Maybe<Array<(
      { __typename?: 'DealBidder' }
      & Pick<DealBidder, 'id' | 'priceInCents' | 'priceText'>
      & { contractor: (
        { __typename?: 'Contractor' }
        & Pick<Contractor, 'id'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email'>
        ) }
      ), dealContracts?: Maybe<Array<(
        { __typename?: 'DealContract' }
        & Pick<DealContract, 'id'>
        & { property: (
          { __typename?: 'Property' }
          & Pick<Property, 'id' | 'streetName' | 'houseNumber'>
          & { owner?: Maybe<(
            { __typename?: 'Homeowner' }
            & Pick<Homeowner, 'id' | 'firstName' | 'lastName'>
            & { user: (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'email'>
            ) }
          )> }
        ) }
      )>> }
    )>> }
  )>> }
);

export type CreateAdminDealsMutationVariables = Exact<{
  dealId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  zipCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  national: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  multiplier: Scalars['Int'];
  multiplierDescription: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
  landingUrl: Scalars['String'];
  description: Scalars['String'];
  frequency?: Maybe<DealFrequency>;
  groupDiscountPercentage: Scalars['Int'];
  visible?: Maybe<Scalars['Boolean']>;
}>;


export type CreateAdminDealsMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateDeal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id'>
  )> }
);

export type CreateDealBidMutationVariables = Exact<{
  contractorEmail: Scalars['String'];
  priceInCents: Scalars['Int'];
  priceText?: Maybe<Scalars['String']>;
  dealId: Scalars['ID'];
}>;


export type CreateDealBidMutation = (
  { __typename?: 'Mutation' }
  & { createDealBid?: Maybe<(
    { __typename?: 'Contractor' }
    & Pick<Contractor, 'id'>
  )> }
);

export type EditDealBidMutationVariables = Exact<{
  dealBidderId: Scalars['ID'];
  priceInCents?: Maybe<Scalars['Int']>;
  priceText?: Maybe<Scalars['String']>;
}>;


export type EditDealBidMutation = (
  { __typename?: 'Mutation' }
  & { editDealBid?: Maybe<(
    { __typename?: 'DealBidder' }
    & Pick<DealBidder, 'id'>
  )> }
);

export type AddDealCodeMutationVariables = Exact<{
  code: Scalars['String'];
  propertyId: Scalars['ID'];
}>;


export type AddDealCodeMutation = (
  { __typename?: 'Mutation' }
  & { addDealCode?: Maybe<(
    { __typename?: 'Homeowner' }
    & Pick<Homeowner, 'id'>
  )> }
);

export type PublicDealsQueryVariables = Exact<{
  dealBidderId?: Maybe<Scalars['ID']>;
}>;


export type PublicDealsQuery = (
  { __typename?: 'Query' }
  & { deals?: Maybe<Array<(
    { __typename?: 'HomeownerDeal' }
    & Pick<HomeownerDeal, 'id' | 'title' | 'description' | 'frequency' | 'joinCount'>
    & { bidders?: Maybe<Array<(
      { __typename?: 'LimitedDealBidder' }
      & Pick<LimitedDealBidder, 'id' | 'priceInCents' | 'priceText' | 'joinCount'>
      & { contractor?: Maybe<(
        { __typename?: 'LimitedContractor' }
        & Pick<LimitedContractor, 'id'>
        & { user?: Maybe<(
          { __typename?: 'LimitedContractorUser' }
          & Pick<LimitedContractorUser, 'id' | 'email'>
        )> }
      )> }
    )>> }
  )>> }
);

export type HomeownerDealsQueryVariables = Exact<{
  active: Scalars['Boolean'];
  properyId?: Maybe<Scalars['ID']>;
}>;


export type HomeownerDealsQuery = (
  { __typename?: 'Query' }
  & { homeownerDeals?: Maybe<Array<(
    { __typename?: 'HomeownerDeal' }
    & Pick<HomeownerDeal, 'id' | 'title' | 'description' | 'frequency' | 'joinCount'>
    & { bidders?: Maybe<Array<(
      { __typename?: 'LimitedDealBidder' }
      & Pick<LimitedDealBidder, 'id' | 'priceInCents' | 'priceText' | 'joinCount'>
      & { contractor?: Maybe<(
        { __typename?: 'LimitedContractor' }
        & Pick<LimitedContractor, 'id'>
        & { user?: Maybe<(
          { __typename?: 'LimitedContractorUser' }
          & Pick<LimitedContractorUser, 'id' | 'email'>
        )> }
      )> }
    )>> }
  )>> }
);

export type JoinDealMutationVariables = Exact<{
  dealBidderId: Scalars['ID'];
  propertyId: Scalars['ID'];
}>;


export type JoinDealMutation = (
  { __typename?: 'Mutation' }
  & { joinDeal?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'id'>
  )> }
);

export type PropertiesIdListQueryVariables = Exact<{ [key: string]: never; }>;


export type PropertiesIdListQuery = (
  { __typename?: 'Query' }
  & { homeowner?: Maybe<(
    { __typename?: 'Homeowner' }
    & Pick<Homeowner, 'id'>
    & { properties?: Maybe<Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'designCenterImageUrl' | 'houseNumber' | 'streetName' | 'ready'>
    )>> }
  )> }
);

export type UserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRoleQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role'>
  )> }
);

export type UserBilledMonthlyQueryVariables = Exact<{ [key: string]: never; }>;


export type UserBilledMonthlyQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'billedMonthly'>
  )> }
);

export type NotificationCountQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationCountQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'notificationCount'>
  )> }
);

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'notificationCount'>
    & { notifications?: Maybe<Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'title' | 'message' | 'read' | 'link' | 'createdAt'>
    )>> }
  )> }
);

export type HomeownerJobsQueryVariables = Exact<{
  page: Scalars['Int'];
  view: Scalars['String'];
  propertyIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  jobId?: Maybe<Scalars['ID']>;
}>;


export type HomeownerJobsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'homeownerOngoingJobsCount' | 'homeownerArchivedJobsCount'>
  & { homeowner?: Maybe<(
    { __typename?: 'Homeowner' }
    & Pick<Homeowner, 'id'>
    & { properties?: Maybe<Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'ready'>
    )>> }
  )>, homeownerJobs?: Maybe<Array<(
    { __typename?: 'Job' }
    & { property: (
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'houseNumber' | 'streetName' | 'city' | 'state' | 'zipCode' | 'ready' | 'renoworksId' | 'legacyHomeSpecId' | 'renoworksDisplayId' | 'newViewer'>
      & { propertySpec?: Maybe<(
        { __typename?: 'PropertySpec' }
        & PropertySpecsFragmentFragment
      )> }
    ) }
    & HomeownerJobFragmentFragment
  )>> }
);

export type SendOrderedMeasurementToClientMutationVariables = Exact<{
  email: Scalars['String'];
  id: Scalars['ID'];
}>;


export type SendOrderedMeasurementToClientMutation = (
  { __typename?: 'Mutation' }
  & { sendOrderedMeasurementToClient?: Maybe<(
    { __typename?: 'SuggestJobPayload' }
    & Pick<SuggestJobPayload, 'email' | 'success'>
  )> }
);

export type EditJobHomeownerMutationVariables = Exact<{
  jobId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
}>;


export type EditJobHomeownerMutation = (
  { __typename?: 'Mutation' }
  & { editJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  )> }
);

export type DeleteJobHomeownerMutationVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type DeleteJobHomeownerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJob'>
);

export type ChangeBidAmountMutationVariables = Exact<{
  jobId: Scalars['ID'];
  bidId: Scalars['ID'];
  amount: Scalars['Float'];
}>;


export type ChangeBidAmountMutation = (
  { __typename?: 'Mutation' }
  & { changeBidAmount?: Maybe<(
    { __typename?: 'ContractorBid' }
    & Pick<ContractorBid, 'id'>
  )> }
);

export type Request3dModelMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  promoCode?: Maybe<Scalars['String']>;
}>;


export type Request3dModelMutation = (
  { __typename?: 'Mutation' }
  & { request3dModel?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'id'>
  )> }
);

export type PropertySpecsFragmentFragment = (
  { __typename?: 'PropertySpec' }
  & Pick<PropertySpec, 'id' | 'rakeLength' | 'eaveLength'>
  & { structures?: Maybe<Array<(
    { __typename?: 'Structure' }
    & Pick<Structure, 'id'>
    & { porches?: Maybe<Array<(
      { __typename?: 'Porch' }
      & Pick<Porch, 'id' | 'refId' | 'area'>
    )>>, chimneys?: Maybe<Array<(
      { __typename?: 'Chimney' }
      & Pick<Chimney, 'refId'>
    )>>, walls?: Maybe<Array<(
      { __typename?: 'Wall' }
      & Pick<Wall, 'id' | 'refId' | 'area' | 'maxWidth' | 'maxHeight' | 'isChimney'>
      & { windows?: Maybe<Array<(
        { __typename?: 'Window' }
        & Pick<Window, 'id' | 'refId' | 'area' | 'widthInInches' | 'heightInInches'>
      )>>, doors?: Maybe<Array<(
        { __typename?: 'Door' }
        & Pick<Door, 'id' | 'refId' | 'type' | 'area' | 'widthInInches' | 'heightInInches'>
      )>> }
    )>>, roofFaces?: Maybe<Array<(
      { __typename?: 'RoofFace' }
      & Pick<RoofFace, 'id' | 'refId' | 'area' | 'slope' | 'ridgeLength' | 'hipLength' | 'valleyLength' | 'rakeLength' | 'eaveLength' | 'flashLength' | 'stepFlashLength'>
      & { vents?: Maybe<Array<(
        { __typename?: 'RoofVent' }
        & Pick<RoofVent, 'id' | 'refId'>
      )>>, trims?: Maybe<Array<(
        { __typename?: 'RoofTrim' }
        & Pick<RoofTrim, 'id' | 'length' | 'overhang' | 'type'>
      )>>, ridgeLines?: Maybe<Array<(
        { __typename?: 'Line' }
        & Pick<Line, 'id' | 'refId' | 'length'>
      )>>, hipLines?: Maybe<Array<(
        { __typename?: 'Line' }
        & Pick<Line, 'id' | 'refId' | 'length'>
      )>>, valleyLines?: Maybe<Array<(
        { __typename?: 'Line' }
        & Pick<Line, 'id' | 'refId' | 'length'>
      )>> }
    )>> }
  )>> }
);

export type AdminPromosQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminPromosQuery = (
  { __typename?: 'Query' }
  & { adminPromos?: Maybe<Array<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'id' | 'code' | 'zipCodes' | 'creditsLeft' | 'startingCredits' | 'createdAt' | 'userType' | 'expiresAt' | 'percentageDiscount'>
  )>> }
);

export type AdminCreatePromoMutationVariables = Exact<{
  code: Scalars['String'];
  zipCodes: Array<Scalars['String']> | Scalars['String'];
  startingCredits: Scalars['Int'];
  userType: UserRole;
  expiresAt?: Maybe<Scalars['DateTime']>;
  percentageDiscount: Scalars['Int'];
}>;


export type AdminCreatePromoMutation = (
  { __typename?: 'Mutation' }
  & { adminCreatePromo?: Maybe<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'id'>
  )> }
);

export type AdminSetpromoCreditsMutationVariables = Exact<{
  id: Scalars['ID'];
  credits: Scalars['Int'];
}>;


export type AdminSetpromoCreditsMutation = (
  { __typename?: 'Mutation' }
  & { adminSetpromoCredits?: Maybe<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'id'>
  )> }
);

export type ValidatePromoQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  promoCode: Scalars['String'];
}>;


export type ValidatePromoQuery = (
  { __typename?: 'Query' }
  & { validatePromo?: Maybe<(
    { __typename?: 'ValidatePromoPayload' }
    & Pick<ValidatePromoPayload, 'finalPrice'>
  )> }
);

export type AdminGetUsersQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
}>;


export type AdminGetUsersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'adminGetUsersCount'>
  & { adminGetUsers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role' | 'email' | 'createdAt' | 'ongoingJobCount' | 'biddableJobCount' | 'fullName'>
    & { properties?: Maybe<Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'houseNumber' | 'streetName' | 'city' | 'state' | 'zipCode' | 'ready'>
    )>> }
  )>> }
);

export type AdminGetPropertyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminGetPropertyQuery = (
  { __typename?: 'Query' }
  & { adminGetProperty?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'createdAt' | 'houseNumber' | 'streetName' | 'city' | 'state' | 'zipCode' | 'ready' | 'renoworksId' | 'legacyHomeSpecId' | 'renoworksDisplayId'>
    & { propertySpec?: Maybe<(
      { __typename?: 'PropertySpec' }
      & PropertySpecsFragmentFragment
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type CompleteResetPasswordMutationVariables = Exact<{
  code: Scalars['String'];
  password: Scalars['String'];
}>;


export type CompleteResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeResetPassword'>
);

export type SubmitRatingMutationVariables = Exact<{
  code: Scalars['String'];
  ratingGiven: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
}>;


export type SubmitRatingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitRating'>
);

export type HomeownerJobFragmentFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'date' | 'title' | 'description' | 'hiredOn' | 'createdAt' | 'completedOn' | 'iconSlug' | 'dummyProperty' | 'thumbnailSlug' | 'propertyMeasurements'>
  & { bids?: Maybe<Array<(
    { __typename?: 'Bid' }
    & JobBidFragmentFragment
  )>>, hiredBid?: Maybe<(
    { __typename?: 'Bid' }
    & JobBidFragmentFragment
  )>, pictures?: Maybe<Array<(
    { __typename?: 'Picture' }
    & PictureFragmentFragment
  )>>, selections?: Maybe<Array<(
    { __typename?: 'Selection' }
    & Pick<Selection, 'id' | 'question' | 'answer'>
  )>> }
);

export type GetContractorDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractorDetailsQuery = (
  { __typename?: 'Query' }
  & { contractor?: Maybe<(
    { __typename?: 'Contractor' }
    & ContractorFragmentFragment
  )> }
);

export type GetContractorJobsQueryVariables = Exact<{
  page: Scalars['Int'];
  view: Scalars['String'];
  trades?: Maybe<Array<TradeGroup> | TradeGroup>;
  jobId?: Maybe<Scalars['ID']>;
}>;


export type GetContractorJobsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractorOngoingJobsCount' | 'contractorPendingBidsCount' | 'contractorBiddableJobsCount' | 'contractorClosedJobsCount'>
  & { contractor?: Maybe<(
    { __typename?: 'Contractor' }
    & Pick<Contractor, 'id'>
    & { trades?: Maybe<Array<(
      { __typename?: 'Trade' }
      & Pick<Trade, 'id' | 'group'>
    )>> }
  )>, contractorJobs?: Maybe<Array<(
    { __typename?: 'ContractorJob' }
    & ContractorJobFragmentFragment
  )>> }
);

export type ContractorBidFragmentFragment = (
  { __typename?: 'ContractorBid' }
  & Pick<ContractorBid, 'id' | 'amount' | 'amountCurrency' | 'status'>
  & { bidChat?: Maybe<(
    { __typename?: 'BidChat' }
    & Pick<BidChat, 'id'>
  )> }
);

export type ContractorJobFragmentFragment = (
  { __typename?: 'ContractorJob' }
  & Pick<ContractorJob, 'id' | 'date' | 'title' | 'description' | 'contractorChargeAmount' | 'dummyProperty' | 'bidCount' | 'completedOn' | 'hasSelectedBid' | 'propertyMeasurements' | 'iconSlug' | 'hasUnreadMessages' | 'isSuggested' | 'createdAt'>
  & { selections?: Maybe<Array<(
    { __typename?: 'Selection' }
    & Pick<Selection, 'id' | 'question' | 'answer'>
  )>>, pictures?: Maybe<Array<(
    { __typename?: 'Picture' }
    & PictureFragmentFragment
  )>>, myBid?: Maybe<(
    { __typename?: 'ContractorBid' }
    & ContractorBidFragmentFragment
  )>, trades?: Maybe<Array<(
    { __typename?: 'Trade' }
    & Pick<Trade, 'id' | 'group'>
  )>>, property?: Maybe<(
    { __typename?: 'BiddableProperty' }
    & Pick<BiddableProperty, 'id' | 'houseNumber' | 'streetName' | 'city' | 'state' | 'zipCode' | 'renoworksId' | 'legacyHomeSpecId'>
    & { owner?: Maybe<(
      { __typename?: 'BiddableOwner' }
      & Pick<BiddableOwner, 'firstName' | 'lastName'>
    )> }
  ) | (
    { __typename?: 'HiredProperty' }
    & Pick<HiredProperty, 'id' | 'houseNumber' | 'streetName' | 'city' | 'state' | 'zipCode' | 'renoworksId' | 'legacyHomeSpecId'>
    & { owner?: Maybe<(
      { __typename?: 'HiredOwner' }
      & Pick<HiredOwner, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type JobBidFragmentFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'amount' | 'amountCurrency' | 'status' | 'hasUnreadMessages'>
  & { bidChat: (
    { __typename?: 'BidChat' }
    & Pick<BidChat, 'id'>
  ), contractor?: Maybe<(
    { __typename?: 'LimitedContractor' }
    & Pick<LimitedContractor, 'id' | 'companyName' | 'companyAddress' | 'companyPhone' | 'companyAdminName' | 'companyBio' | 'companyPosition' | 'primaryWebsite' | 'secondaryWebsite' | 'workPictureUrls'>
    & { user?: Maybe<(
      { __typename?: 'LimitedContractorUser' }
      & Pick<LimitedContractorUser, 'id' | 'rating'>
    )>, avatar?: Maybe<(
      { __typename?: 'ContractorAvatar' }
      & Pick<ContractorAvatar, 'id' | 'url' | 'mime'>
    )> }
  )> }
);

export type ContractorFragmentFragment = (
  { __typename?: 'Contractor' }
  & Pick<Contractor, 'id' | 'companyName' | 'companyAddress' | 'companyPhone' | 'companyAdminName' | 'companyBio' | 'companyPosition' | 'primaryWebsite' | 'secondaryWebsite' | 'workPictureUrls'>
  & { user: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ), avatar?: Maybe<(
    { __typename?: 'Picture' }
    & PictureFragmentFragment
  )>, counties?: Maybe<Array<(
    { __typename?: 'County' }
    & Pick<County, 'id' | 'name'>
    & { state: (
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name'>
    ) }
  )>>, trades?: Maybe<Array<(
    { __typename?: 'Trade' }
    & Pick<Trade, 'id' | 'group'>
  )>> }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'rating' | 'role' | 'billingStatus' | 'billedMonthly' | 'notificationCount' | 'ongoingJobCount' | 'biddableJobCount'>
  & { notifications?: Maybe<Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'title' | 'message' | 'read' | 'link' | 'createdAt'>
  )>> }
);

export type PictureFragmentFragment = (
  { __typename?: 'Picture' }
  & Pick<Picture, 'id' | 'url' | 'mime'>
);

export type AdminGetJobSpecificationsQueryVariables = Exact<{
  jobTitle?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
}>;


export type AdminGetJobSpecificationsQuery = (
  { __typename?: 'Query' }
  & { adminGetJobSpecifications?: Maybe<Array<(
    { __typename?: 'JobSpecification' }
    & Pick<JobSpecification, 'id' | 'title'>
  )>> }
);

export type SignupJoinDealMutationVariables = Exact<{
  dealBidderId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  houseNumber: Scalars['String'];
  streetName: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  county: Scalars['ID'];
  zipCode: Scalars['String'];
  lat: Scalars['String'];
  lng: Scalars['String'];
  proposedDate?: Maybe<Scalars['String']>;
}>;


export type SignupJoinDealMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signupJoinDeal'>
);

export type VerifyUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  verificationCode: Scalars['String'];
}>;


export type VerifyUserMutation = (
  { __typename?: 'Mutation' }
  & { verifyUser?: Maybe<(
    { __typename?: 'VerificationPayload' }
    & Pick<VerificationPayload, 'verified'>
  )> }
);

export const HomeownerDashboardFragFragmentDoc = gql`
    fragment HomeownerDashboardFrag on Homeowner {
  id
  properties {
    id
    designCenterImageUrl
    houseNumber
    streetName
    ready
    jobs(where: {completedOn: null}, first: 5) {
      id
      date
      thumbnailSlug
      hiredOn
      title
      hiredBid {
        id
      }
      bids {
        id
        status
      }
    }
  }
}
    `;
export const ContractorDashboardFragFragmentDoc = gql`
    fragment ContractorDashboardFrag on ContractorJob {
  id
  date
  thumbnailSlug
  title
  property {
    ... on IPropertyData {
      id
      zipCode
      city
      state
      designCenterImageUrl
      viewerImageUrl
    }
  }
}
    `;
export const PropertySpecsFragmentFragmentDoc = gql`
    fragment PropertySpecsFragment on PropertySpec {
  id
  rakeLength
  eaveLength
  structures {
    id
    porches {
      id
      refId
      area
    }
    chimneys {
      refId
    }
    walls {
      id
      refId
      area
      maxWidth
      maxHeight
      windows {
        id
        refId
        area
        widthInInches
        heightInInches
      }
      doors {
        id
        refId
        type
        area
        widthInInches
        heightInInches
      }
      isChimney
    }
    roofFaces {
      id
      refId
      area
      slope
      ridgeLength
      hipLength
      valleyLength
      rakeLength
      eaveLength
      flashLength
      stepFlashLength
      vents {
        id
        refId
      }
      trims {
        id
        length
        overhang
        type
      }
      ridgeLines {
        id
        refId
        length
      }
      hipLines {
        id
        refId
        length
      }
      valleyLines {
        id
        refId
        length
      }
    }
  }
}
    `;
export const JobBidFragmentFragmentDoc = gql`
    fragment JobBidFragment on Bid {
  id
  amount
  amountCurrency
  status
  hasUnreadMessages
  bidChat {
    id
  }
  contractor {
    id
    companyName
    companyAddress
    companyPhone
    companyAdminName
    companyBio
    companyPosition
    primaryWebsite
    secondaryWebsite
    workPictureUrls
    user {
      id
      rating
    }
    avatar {
      id
      url
      mime
    }
  }
}
    `;
export const PictureFragmentFragmentDoc = gql`
    fragment PictureFragment on Picture {
  id
  url
  mime
}
    `;
export const HomeownerJobFragmentFragmentDoc = gql`
    fragment HomeownerJobFragment on Job {
  id
  date
  title
  description
  hiredOn
  createdAt
  completedOn
  iconSlug
  dummyProperty
  bids {
    ...JobBidFragment
  }
  hiredBid {
    ...JobBidFragment
  }
  pictures {
    ...PictureFragment
  }
  thumbnailSlug
  selections {
    id
    question
    answer
  }
  propertyMeasurements
}
    ${JobBidFragmentFragmentDoc}
${PictureFragmentFragmentDoc}`;
export const ContractorBidFragmentFragmentDoc = gql`
    fragment ContractorBidFragment on ContractorBid {
  id
  amount
  amountCurrency
  status
  bidChat {
    id
  }
}
    `;
export const ContractorJobFragmentFragmentDoc = gql`
    fragment ContractorJobFragment on ContractorJob {
  id
  date
  title
  description
  selections {
    id
    question
    answer
  }
  pictures {
    ...PictureFragment
  }
  myBid {
    ...ContractorBidFragment
  }
  trades {
    id
    group
  }
  contractorChargeAmount
  dummyProperty
  property {
    ... on BiddableProperty {
      id
      houseNumber
      streetName
      city
      state
      zipCode
      owner {
        firstName
        lastName
      }
      renoworksId
      legacyHomeSpecId
    }
    ... on HiredProperty {
      id
      houseNumber
      streetName
      city
      state
      zipCode
      owner {
        firstName
        lastName
      }
      renoworksId
      legacyHomeSpecId
    }
  }
  bidCount
  completedOn
  hasSelectedBid
  propertyMeasurements
  iconSlug
  hasUnreadMessages
  isSuggested
  createdAt
}
    ${PictureFragmentFragmentDoc}
${ContractorBidFragmentFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  rating
  role
  billingStatus
  billedMonthly
  notificationCount
  ongoingJobCount
  biddableJobCount
  notifications(orderBy: createdAt_DESC) {
    id
    title
    message
    read
    link
    createdAt
  }
}
    `;
export const ContractorFragmentFragmentDoc = gql`
    fragment ContractorFragment on Contractor {
  id
  companyName
  companyAddress
  companyPhone
  companyAdminName
  companyBio
  companyPosition
  primaryWebsite
  secondaryWebsite
  workPictureUrls
  user {
    ...UserFragment
  }
  avatar {
    ...PictureFragment
  }
  counties {
    id
    name
    state {
      id
      name
    }
  }
  trades {
    id
    group
  }
}
    ${UserFragmentFragmentDoc}
${PictureFragmentFragmentDoc}`;
export const HomeownerDashboardDocument = gql`
    query HomeownerDashboard {
  homeowner {
    ...HomeownerDashboardFrag
  }
}
    ${HomeownerDashboardFragFragmentDoc}`;
export type HomeownerDashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomeownerDashboardQuery, HomeownerDashboardQueryVariables>, 'query'>;

    export const HomeownerDashboardComponent = (props: HomeownerDashboardComponentProps) => (
      <ApolloReactComponents.Query<HomeownerDashboardQuery, HomeownerDashboardQueryVariables> query={HomeownerDashboardDocument} {...props} />
    );
    

/**
 * __useHomeownerDashboardQuery__
 *
 * To run a query within a React component, call `useHomeownerDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeownerDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeownerDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeownerDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomeownerDashboardQuery, HomeownerDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<HomeownerDashboardQuery, HomeownerDashboardQueryVariables>(HomeownerDashboardDocument, baseOptions);
      }
export function useHomeownerDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomeownerDashboardQuery, HomeownerDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomeownerDashboardQuery, HomeownerDashboardQueryVariables>(HomeownerDashboardDocument, baseOptions);
        }
export type HomeownerDashboardQueryHookResult = ReturnType<typeof useHomeownerDashboardQuery>;
export type HomeownerDashboardLazyQueryHookResult = ReturnType<typeof useHomeownerDashboardLazyQuery>;
export type HomeownerDashboardQueryResult = ApolloReactCommon.QueryResult<HomeownerDashboardQuery, HomeownerDashboardQueryVariables>;
export function refetchHomeownerDashboardQuery(variables?: HomeownerDashboardQueryVariables) {
      return { query: HomeownerDashboardDocument, variables: variables }
    }
export const ContractorDashboardDocument = gql`
    query ContractorDashboard {
  contractorOngoingJobsCount
  contractorPendingBidsCount
  contractorBiddableJobsCount
  biddableJobs: contractorJobs(page: 0, perPage: 5, view: "BIDDABLE") {
    ...ContractorDashboardFrag
  }
  ongoingJobs: contractorJobs(page: 0, perPage: 5, view: "OPEN") {
    ...ContractorDashboardFrag
  }
}
    ${ContractorDashboardFragFragmentDoc}`;
export type ContractorDashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ContractorDashboardQuery, ContractorDashboardQueryVariables>, 'query'>;

    export const ContractorDashboardComponent = (props: ContractorDashboardComponentProps) => (
      <ApolloReactComponents.Query<ContractorDashboardQuery, ContractorDashboardQueryVariables> query={ContractorDashboardDocument} {...props} />
    );
    

/**
 * __useContractorDashboardQuery__
 *
 * To run a query within a React component, call `useContractorDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractorDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractorDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractorDashboardQuery, ContractorDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractorDashboardQuery, ContractorDashboardQueryVariables>(ContractorDashboardDocument, baseOptions);
      }
export function useContractorDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractorDashboardQuery, ContractorDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractorDashboardQuery, ContractorDashboardQueryVariables>(ContractorDashboardDocument, baseOptions);
        }
export type ContractorDashboardQueryHookResult = ReturnType<typeof useContractorDashboardQuery>;
export type ContractorDashboardLazyQueryHookResult = ReturnType<typeof useContractorDashboardLazyQuery>;
export type ContractorDashboardQueryResult = ApolloReactCommon.QueryResult<ContractorDashboardQuery, ContractorDashboardQueryVariables>;
export function refetchContractorDashboardQuery(variables?: ContractorDashboardQueryVariables) {
      return { query: ContractorDashboardDocument, variables: variables }
    }
export const ContractorOrderedMeasurementsDocument = gql`
    query ContractorOrderedMeasurements {
  contractorOrderedMeasurements {
    id
    invitedEmails
    createdAt
    property {
      id
      houseNumber
      streetName
      city
      state
      zipCode
      ready
      renoworksId
      legacyHomeSpecId
      renoworksDisplayId
      newViewer
      propertySpec {
        ...PropertySpecsFragment
      }
    }
  }
}
    ${PropertySpecsFragmentFragmentDoc}`;
export type ContractorOrderedMeasurementsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables>, 'query'>;

    export const ContractorOrderedMeasurementsComponent = (props: ContractorOrderedMeasurementsComponentProps) => (
      <ApolloReactComponents.Query<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables> query={ContractorOrderedMeasurementsDocument} {...props} />
    );
    

/**
 * __useContractorOrderedMeasurementsQuery__
 *
 * To run a query within a React component, call `useContractorOrderedMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractorOrderedMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorOrderedMeasurementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractorOrderedMeasurementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables>(ContractorOrderedMeasurementsDocument, baseOptions);
      }
export function useContractorOrderedMeasurementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables>(ContractorOrderedMeasurementsDocument, baseOptions);
        }
export type ContractorOrderedMeasurementsQueryHookResult = ReturnType<typeof useContractorOrderedMeasurementsQuery>;
export type ContractorOrderedMeasurementsLazyQueryHookResult = ReturnType<typeof useContractorOrderedMeasurementsLazyQuery>;
export type ContractorOrderedMeasurementsQueryResult = ApolloReactCommon.QueryResult<ContractorOrderedMeasurementsQuery, ContractorOrderedMeasurementsQueryVariables>;
export function refetchContractorOrderedMeasurementsQuery(variables?: ContractorOrderedMeasurementsQueryVariables) {
      return { query: ContractorOrderedMeasurementsDocument, variables: variables }
    }
export const AdminDealsDocument = gql`
    query AdminDeals {
  adminDeals {
    id
    name
    zipCodes {
      id
      code
    }
    national
    code
    bidders {
      id
      contractor {
        id
        user {
          id
          email
        }
      }
      priceInCents
      priceText
      dealContracts {
        id
        property {
          id
          streetName
          houseNumber
          owner {
            id
            firstName
            lastName
            user {
              id
              email
            }
          }
        }
      }
    }
    multiplier
    multiplierDescription
    priceInCents
    image
    title
    description
    frequency
    landingUrl
    visible
    groupDiscountPercentage
  }
}
    `;
export type AdminDealsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminDealsQuery, AdminDealsQueryVariables>, 'query'>;

    export const AdminDealsComponent = (props: AdminDealsComponentProps) => (
      <ApolloReactComponents.Query<AdminDealsQuery, AdminDealsQueryVariables> query={AdminDealsDocument} {...props} />
    );
    

/**
 * __useAdminDealsQuery__
 *
 * To run a query within a React component, call `useAdminDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDealsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminDealsQuery, AdminDealsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminDealsQuery, AdminDealsQueryVariables>(AdminDealsDocument, baseOptions);
      }
export function useAdminDealsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminDealsQuery, AdminDealsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminDealsQuery, AdminDealsQueryVariables>(AdminDealsDocument, baseOptions);
        }
export type AdminDealsQueryHookResult = ReturnType<typeof useAdminDealsQuery>;
export type AdminDealsLazyQueryHookResult = ReturnType<typeof useAdminDealsLazyQuery>;
export type AdminDealsQueryResult = ApolloReactCommon.QueryResult<AdminDealsQuery, AdminDealsQueryVariables>;
export function refetchAdminDealsQuery(variables?: AdminDealsQueryVariables) {
      return { query: AdminDealsDocument, variables: variables }
    }
export const CreateAdminDealsDocument = gql`
    mutation CreateAdminDeals($dealId: ID, $name: String!, $zipCodes: [String!], $national: Boolean!, $code: String, $multiplier: Int!, $multiplierDescription: String!, $image: String!, $title: String!, $landingUrl: String!, $description: String!, $frequency: DealFrequency, $groupDiscountPercentage: Int!, $visible: Boolean) {
  adminCreateDeal(dealId: $dealId, name: $name, zipCodes: $zipCodes, national: $national, code: $code, multiplier: $multiplier, multiplierDescription: $multiplierDescription, image: $image, title: $title, description: $description, landingUrl: $landingUrl, frequency: $frequency, groupDiscountPercentage: $groupDiscountPercentage, visible: $visible) {
    id
  }
}
    `;
export type CreateAdminDealsMutationFn = ApolloReactCommon.MutationFunction<CreateAdminDealsMutation, CreateAdminDealsMutationVariables>;
export type CreateAdminDealsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAdminDealsMutation, CreateAdminDealsMutationVariables>, 'mutation'>;

    export const CreateAdminDealsComponent = (props: CreateAdminDealsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAdminDealsMutation, CreateAdminDealsMutationVariables> mutation={CreateAdminDealsDocument} {...props} />
    );
    

/**
 * __useCreateAdminDealsMutation__
 *
 * To run a mutation, you first call `useCreateAdminDealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminDealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminDealsMutation, { data, loading, error }] = useCreateAdminDealsMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      name: // value for 'name'
 *      zipCodes: // value for 'zipCodes'
 *      national: // value for 'national'
 *      code: // value for 'code'
 *      multiplier: // value for 'multiplier'
 *      multiplierDescription: // value for 'multiplierDescription'
 *      image: // value for 'image'
 *      title: // value for 'title'
 *      landingUrl: // value for 'landingUrl'
 *      description: // value for 'description'
 *      frequency: // value for 'frequency'
 *      groupDiscountPercentage: // value for 'groupDiscountPercentage'
 *      visible: // value for 'visible'
 *   },
 * });
 */
export function useCreateAdminDealsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAdminDealsMutation, CreateAdminDealsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAdminDealsMutation, CreateAdminDealsMutationVariables>(CreateAdminDealsDocument, baseOptions);
      }
export type CreateAdminDealsMutationHookResult = ReturnType<typeof useCreateAdminDealsMutation>;
export type CreateAdminDealsMutationResult = ApolloReactCommon.MutationResult<CreateAdminDealsMutation>;
export type CreateAdminDealsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAdminDealsMutation, CreateAdminDealsMutationVariables>;
export const CreateDealBidDocument = gql`
    mutation CreateDealBid($contractorEmail: String!, $priceInCents: Int!, $priceText: String, $dealId: ID!) {
  createDealBid(contractorEmail: $contractorEmail, priceInCents: $priceInCents, priceText: $priceText, dealId: $dealId) {
    id
  }
}
    `;
export type CreateDealBidMutationFn = ApolloReactCommon.MutationFunction<CreateDealBidMutation, CreateDealBidMutationVariables>;
export type CreateDealBidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDealBidMutation, CreateDealBidMutationVariables>, 'mutation'>;

    export const CreateDealBidComponent = (props: CreateDealBidComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDealBidMutation, CreateDealBidMutationVariables> mutation={CreateDealBidDocument} {...props} />
    );
    

/**
 * __useCreateDealBidMutation__
 *
 * To run a mutation, you first call `useCreateDealBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealBidMutation, { data, loading, error }] = useCreateDealBidMutation({
 *   variables: {
 *      contractorEmail: // value for 'contractorEmail'
 *      priceInCents: // value for 'priceInCents'
 *      priceText: // value for 'priceText'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useCreateDealBidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDealBidMutation, CreateDealBidMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDealBidMutation, CreateDealBidMutationVariables>(CreateDealBidDocument, baseOptions);
      }
export type CreateDealBidMutationHookResult = ReturnType<typeof useCreateDealBidMutation>;
export type CreateDealBidMutationResult = ApolloReactCommon.MutationResult<CreateDealBidMutation>;
export type CreateDealBidMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDealBidMutation, CreateDealBidMutationVariables>;
export const EditDealBidDocument = gql`
    mutation EditDealBid($dealBidderId: ID!, $priceInCents: Int, $priceText: String) {
  editDealBid(dealBidderId: $dealBidderId, priceInCents: $priceInCents, priceText: $priceText) {
    id
  }
}
    `;
export type EditDealBidMutationFn = ApolloReactCommon.MutationFunction<EditDealBidMutation, EditDealBidMutationVariables>;
export type EditDealBidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditDealBidMutation, EditDealBidMutationVariables>, 'mutation'>;

    export const EditDealBidComponent = (props: EditDealBidComponentProps) => (
      <ApolloReactComponents.Mutation<EditDealBidMutation, EditDealBidMutationVariables> mutation={EditDealBidDocument} {...props} />
    );
    

/**
 * __useEditDealBidMutation__
 *
 * To run a mutation, you first call `useEditDealBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDealBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDealBidMutation, { data, loading, error }] = useEditDealBidMutation({
 *   variables: {
 *      dealBidderId: // value for 'dealBidderId'
 *      priceInCents: // value for 'priceInCents'
 *      priceText: // value for 'priceText'
 *   },
 * });
 */
export function useEditDealBidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDealBidMutation, EditDealBidMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDealBidMutation, EditDealBidMutationVariables>(EditDealBidDocument, baseOptions);
      }
export type EditDealBidMutationHookResult = ReturnType<typeof useEditDealBidMutation>;
export type EditDealBidMutationResult = ApolloReactCommon.MutationResult<EditDealBidMutation>;
export type EditDealBidMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDealBidMutation, EditDealBidMutationVariables>;
export const AddDealCodeDocument = gql`
    mutation AddDealCode($code: String!, $propertyId: ID!) {
  addDealCode(code: $code, propertyId: $propertyId) {
    id
  }
}
    `;
export type AddDealCodeMutationFn = ApolloReactCommon.MutationFunction<AddDealCodeMutation, AddDealCodeMutationVariables>;
export type AddDealCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddDealCodeMutation, AddDealCodeMutationVariables>, 'mutation'>;

    export const AddDealCodeComponent = (props: AddDealCodeComponentProps) => (
      <ApolloReactComponents.Mutation<AddDealCodeMutation, AddDealCodeMutationVariables> mutation={AddDealCodeDocument} {...props} />
    );
    

/**
 * __useAddDealCodeMutation__
 *
 * To run a mutation, you first call `useAddDealCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDealCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDealCodeMutation, { data, loading, error }] = useAddDealCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useAddDealCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDealCodeMutation, AddDealCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDealCodeMutation, AddDealCodeMutationVariables>(AddDealCodeDocument, baseOptions);
      }
export type AddDealCodeMutationHookResult = ReturnType<typeof useAddDealCodeMutation>;
export type AddDealCodeMutationResult = ApolloReactCommon.MutationResult<AddDealCodeMutation>;
export type AddDealCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDealCodeMutation, AddDealCodeMutationVariables>;
export const PublicDealsDocument = gql`
    query PublicDeals($dealBidderId: ID) {
  deals(dealBidderId: $dealBidderId) {
    id
    title
    description
    frequency
    joinCount
    bidders {
      id
      priceInCents
      priceText
      joinCount
      contractor {
        id
        user {
          id
          email
        }
      }
    }
  }
}
    `;
export type PublicDealsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PublicDealsQuery, PublicDealsQueryVariables>, 'query'>;

    export const PublicDealsComponent = (props: PublicDealsComponentProps) => (
      <ApolloReactComponents.Query<PublicDealsQuery, PublicDealsQueryVariables> query={PublicDealsDocument} {...props} />
    );
    

/**
 * __usePublicDealsQuery__
 *
 * To run a query within a React component, call `usePublicDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicDealsQuery({
 *   variables: {
 *      dealBidderId: // value for 'dealBidderId'
 *   },
 * });
 */
export function usePublicDealsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublicDealsQuery, PublicDealsQueryVariables>) {
        return ApolloReactHooks.useQuery<PublicDealsQuery, PublicDealsQueryVariables>(PublicDealsDocument, baseOptions);
      }
export function usePublicDealsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicDealsQuery, PublicDealsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublicDealsQuery, PublicDealsQueryVariables>(PublicDealsDocument, baseOptions);
        }
export type PublicDealsQueryHookResult = ReturnType<typeof usePublicDealsQuery>;
export type PublicDealsLazyQueryHookResult = ReturnType<typeof usePublicDealsLazyQuery>;
export type PublicDealsQueryResult = ApolloReactCommon.QueryResult<PublicDealsQuery, PublicDealsQueryVariables>;
export function refetchPublicDealsQuery(variables?: PublicDealsQueryVariables) {
      return { query: PublicDealsDocument, variables: variables }
    }
export const HomeownerDealsDocument = gql`
    query HomeownerDeals($active: Boolean!, $properyId: ID) {
  homeownerDeals(joined: $active, propertyId: $properyId) {
    id
    title
    description
    frequency
    joinCount
    bidders {
      id
      priceInCents
      priceText
      joinCount
      contractor {
        id
        user {
          id
          email
        }
      }
    }
  }
}
    `;
export type HomeownerDealsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomeownerDealsQuery, HomeownerDealsQueryVariables>, 'query'> & ({ variables: HomeownerDealsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HomeownerDealsComponent = (props: HomeownerDealsComponentProps) => (
      <ApolloReactComponents.Query<HomeownerDealsQuery, HomeownerDealsQueryVariables> query={HomeownerDealsDocument} {...props} />
    );
    

/**
 * __useHomeownerDealsQuery__
 *
 * To run a query within a React component, call `useHomeownerDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeownerDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeownerDealsQuery({
 *   variables: {
 *      active: // value for 'active'
 *      properyId: // value for 'properyId'
 *   },
 * });
 */
export function useHomeownerDealsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomeownerDealsQuery, HomeownerDealsQueryVariables>) {
        return ApolloReactHooks.useQuery<HomeownerDealsQuery, HomeownerDealsQueryVariables>(HomeownerDealsDocument, baseOptions);
      }
export function useHomeownerDealsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomeownerDealsQuery, HomeownerDealsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomeownerDealsQuery, HomeownerDealsQueryVariables>(HomeownerDealsDocument, baseOptions);
        }
export type HomeownerDealsQueryHookResult = ReturnType<typeof useHomeownerDealsQuery>;
export type HomeownerDealsLazyQueryHookResult = ReturnType<typeof useHomeownerDealsLazyQuery>;
export type HomeownerDealsQueryResult = ApolloReactCommon.QueryResult<HomeownerDealsQuery, HomeownerDealsQueryVariables>;
export function refetchHomeownerDealsQuery(variables?: HomeownerDealsQueryVariables) {
      return { query: HomeownerDealsDocument, variables: variables }
    }
export const JoinDealDocument = gql`
    mutation JoinDeal($dealBidderId: ID!, $propertyId: ID!) {
  joinDeal(dealBidderId: $dealBidderId, propertyId: $propertyId) {
    id
  }
}
    `;
export type JoinDealMutationFn = ApolloReactCommon.MutationFunction<JoinDealMutation, JoinDealMutationVariables>;
export type JoinDealComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<JoinDealMutation, JoinDealMutationVariables>, 'mutation'>;

    export const JoinDealComponent = (props: JoinDealComponentProps) => (
      <ApolloReactComponents.Mutation<JoinDealMutation, JoinDealMutationVariables> mutation={JoinDealDocument} {...props} />
    );
    

/**
 * __useJoinDealMutation__
 *
 * To run a mutation, you first call `useJoinDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinDealMutation, { data, loading, error }] = useJoinDealMutation({
 *   variables: {
 *      dealBidderId: // value for 'dealBidderId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useJoinDealMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinDealMutation, JoinDealMutationVariables>) {
        return ApolloReactHooks.useMutation<JoinDealMutation, JoinDealMutationVariables>(JoinDealDocument, baseOptions);
      }
export type JoinDealMutationHookResult = ReturnType<typeof useJoinDealMutation>;
export type JoinDealMutationResult = ApolloReactCommon.MutationResult<JoinDealMutation>;
export type JoinDealMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinDealMutation, JoinDealMutationVariables>;
export const PropertiesIdListDocument = gql`
    query PropertiesIdList {
  homeowner {
    id
    properties {
      id
      designCenterImageUrl
      houseNumber
      streetName
      ready
    }
  }
}
    `;
export type PropertiesIdListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PropertiesIdListQuery, PropertiesIdListQueryVariables>, 'query'>;

    export const PropertiesIdListComponent = (props: PropertiesIdListComponentProps) => (
      <ApolloReactComponents.Query<PropertiesIdListQuery, PropertiesIdListQueryVariables> query={PropertiesIdListDocument} {...props} />
    );
    

/**
 * __usePropertiesIdListQuery__
 *
 * To run a query within a React component, call `usePropertiesIdListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesIdListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesIdListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePropertiesIdListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PropertiesIdListQuery, PropertiesIdListQueryVariables>) {
        return ApolloReactHooks.useQuery<PropertiesIdListQuery, PropertiesIdListQueryVariables>(PropertiesIdListDocument, baseOptions);
      }
export function usePropertiesIdListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PropertiesIdListQuery, PropertiesIdListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PropertiesIdListQuery, PropertiesIdListQueryVariables>(PropertiesIdListDocument, baseOptions);
        }
export type PropertiesIdListQueryHookResult = ReturnType<typeof usePropertiesIdListQuery>;
export type PropertiesIdListLazyQueryHookResult = ReturnType<typeof usePropertiesIdListLazyQuery>;
export type PropertiesIdListQueryResult = ApolloReactCommon.QueryResult<PropertiesIdListQuery, PropertiesIdListQueryVariables>;
export function refetchPropertiesIdListQuery(variables?: PropertiesIdListQueryVariables) {
      return { query: PropertiesIdListDocument, variables: variables }
    }
export const UserRoleDocument = gql`
    query UserRole {
  user {
    id
    role
  }
}
    `;
export type UserRoleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserRoleQuery, UserRoleQueryVariables>, 'query'>;

    export const UserRoleComponent = (props: UserRoleComponentProps) => (
      <ApolloReactComponents.Query<UserRoleQuery, UserRoleQueryVariables> query={UserRoleDocument} {...props} />
    );
    

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, baseOptions);
      }
export function useUserRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, baseOptions);
        }
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = ApolloReactCommon.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export function refetchUserRoleQuery(variables?: UserRoleQueryVariables) {
      return { query: UserRoleDocument, variables: variables }
    }
export const UserBilledMonthlyDocument = gql`
    query UserBilledMonthly {
  user {
    id
    billedMonthly
  }
}
    `;
export type UserBilledMonthlyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables>, 'query'>;

    export const UserBilledMonthlyComponent = (props: UserBilledMonthlyComponentProps) => (
      <ApolloReactComponents.Query<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables> query={UserBilledMonthlyDocument} {...props} />
    );
    

/**
 * __useUserBilledMonthlyQuery__
 *
 * To run a query within a React component, call `useUserBilledMonthlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBilledMonthlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBilledMonthlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBilledMonthlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables>) {
        return ApolloReactHooks.useQuery<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables>(UserBilledMonthlyDocument, baseOptions);
      }
export function useUserBilledMonthlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables>(UserBilledMonthlyDocument, baseOptions);
        }
export type UserBilledMonthlyQueryHookResult = ReturnType<typeof useUserBilledMonthlyQuery>;
export type UserBilledMonthlyLazyQueryHookResult = ReturnType<typeof useUserBilledMonthlyLazyQuery>;
export type UserBilledMonthlyQueryResult = ApolloReactCommon.QueryResult<UserBilledMonthlyQuery, UserBilledMonthlyQueryVariables>;
export function refetchUserBilledMonthlyQuery(variables?: UserBilledMonthlyQueryVariables) {
      return { query: UserBilledMonthlyDocument, variables: variables }
    }
export const NotificationCountDocument = gql`
    query NotificationCount {
  user {
    id
    notificationCount
  }
}
    `;
export type NotificationCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotificationCountQuery, NotificationCountQueryVariables>, 'query'>;

    export const NotificationCountComponent = (props: NotificationCountComponentProps) => (
      <ApolloReactComponents.Query<NotificationCountQuery, NotificationCountQueryVariables> query={NotificationCountDocument} {...props} />
    );
    

/**
 * __useNotificationCountQuery__
 *
 * To run a query within a React component, call `useNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationCountQuery, NotificationCountQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationCountQuery, NotificationCountQueryVariables>(NotificationCountDocument, baseOptions);
      }
export function useNotificationCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationCountQuery, NotificationCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationCountQuery, NotificationCountQueryVariables>(NotificationCountDocument, baseOptions);
        }
export type NotificationCountQueryHookResult = ReturnType<typeof useNotificationCountQuery>;
export type NotificationCountLazyQueryHookResult = ReturnType<typeof useNotificationCountLazyQuery>;
export type NotificationCountQueryResult = ApolloReactCommon.QueryResult<NotificationCountQuery, NotificationCountQueryVariables>;
export function refetchNotificationCountQuery(variables?: NotificationCountQueryVariables) {
      return { query: NotificationCountDocument, variables: variables }
    }
export const NotificationsDocument = gql`
    query Notifications {
  user {
    notificationCount
    notifications(orderBy: createdAt_DESC) {
      id
      title
      message
      read
      link
      createdAt
    }
  }
}
    `;
export type NotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotificationsQuery, NotificationsQueryVariables>, 'query'>;

    export const NotificationsComponent = (props: NotificationsComponentProps) => (
      <ApolloReactComponents.Query<NotificationsQuery, NotificationsQueryVariables> query={NotificationsDocument} {...props} />
    );
    

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
      }
export function useNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export function refetchNotificationsQuery(variables?: NotificationsQueryVariables) {
      return { query: NotificationsDocument, variables: variables }
    }
export const HomeownerJobsDocument = gql`
    query HomeownerJobs($page: Int!, $view: String!, $propertyIds: [ID!], $jobId: ID) {
  homeownerOngoingJobsCount(propertyIds: $propertyIds)
  homeownerArchivedJobsCount(propertyIds: $propertyIds)
  homeowner {
    id
    properties {
      id
      ready
    }
  }
  homeownerJobs(page: $page, view: $view, propertyIds: $propertyIds, jobId: $jobId) {
    ...HomeownerJobFragment
    property {
      id
      houseNumber
      streetName
      city
      state
      zipCode
      ready
      renoworksId
      legacyHomeSpecId
      renoworksDisplayId
      newViewer
      propertySpec {
        ...PropertySpecsFragment
      }
    }
  }
}
    ${HomeownerJobFragmentFragmentDoc}
${PropertySpecsFragmentFragmentDoc}`;
export type HomeownerJobsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomeownerJobsQuery, HomeownerJobsQueryVariables>, 'query'> & ({ variables: HomeownerJobsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HomeownerJobsComponent = (props: HomeownerJobsComponentProps) => (
      <ApolloReactComponents.Query<HomeownerJobsQuery, HomeownerJobsQueryVariables> query={HomeownerJobsDocument} {...props} />
    );
    

/**
 * __useHomeownerJobsQuery__
 *
 * To run a query within a React component, call `useHomeownerJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeownerJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeownerJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      view: // value for 'view'
 *      propertyIds: // value for 'propertyIds'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useHomeownerJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomeownerJobsQuery, HomeownerJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<HomeownerJobsQuery, HomeownerJobsQueryVariables>(HomeownerJobsDocument, baseOptions);
      }
export function useHomeownerJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomeownerJobsQuery, HomeownerJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomeownerJobsQuery, HomeownerJobsQueryVariables>(HomeownerJobsDocument, baseOptions);
        }
export type HomeownerJobsQueryHookResult = ReturnType<typeof useHomeownerJobsQuery>;
export type HomeownerJobsLazyQueryHookResult = ReturnType<typeof useHomeownerJobsLazyQuery>;
export type HomeownerJobsQueryResult = ApolloReactCommon.QueryResult<HomeownerJobsQuery, HomeownerJobsQueryVariables>;
export function refetchHomeownerJobsQuery(variables?: HomeownerJobsQueryVariables) {
      return { query: HomeownerJobsDocument, variables: variables }
    }
export const SendOrderedMeasurementToClientDocument = gql`
    mutation SendOrderedMeasurementToClient($email: String!, $id: ID!) {
  sendOrderedMeasurementToClient(email: $email, id: $id) {
    email
    success
  }
}
    `;
export type SendOrderedMeasurementToClientMutationFn = ApolloReactCommon.MutationFunction<SendOrderedMeasurementToClientMutation, SendOrderedMeasurementToClientMutationVariables>;
export type SendOrderedMeasurementToClientComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendOrderedMeasurementToClientMutation, SendOrderedMeasurementToClientMutationVariables>, 'mutation'>;

    export const SendOrderedMeasurementToClientComponent = (props: SendOrderedMeasurementToClientComponentProps) => (
      <ApolloReactComponents.Mutation<SendOrderedMeasurementToClientMutation, SendOrderedMeasurementToClientMutationVariables> mutation={SendOrderedMeasurementToClientDocument} {...props} />
    );
    

/**
 * __useSendOrderedMeasurementToClientMutation__
 *
 * To run a mutation, you first call `useSendOrderedMeasurementToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderedMeasurementToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderedMeasurementToClientMutation, { data, loading, error }] = useSendOrderedMeasurementToClientMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendOrderedMeasurementToClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendOrderedMeasurementToClientMutation, SendOrderedMeasurementToClientMutationVariables>) {
        return ApolloReactHooks.useMutation<SendOrderedMeasurementToClientMutation, SendOrderedMeasurementToClientMutationVariables>(SendOrderedMeasurementToClientDocument, baseOptions);
      }
export type SendOrderedMeasurementToClientMutationHookResult = ReturnType<typeof useSendOrderedMeasurementToClientMutation>;
export type SendOrderedMeasurementToClientMutationResult = ApolloReactCommon.MutationResult<SendOrderedMeasurementToClientMutation>;
export type SendOrderedMeasurementToClientMutationOptions = ApolloReactCommon.BaseMutationOptions<SendOrderedMeasurementToClientMutation, SendOrderedMeasurementToClientMutationVariables>;
export const EditJobHomeownerDocument = gql`
    mutation EditJobHomeowner($jobId: ID!, $description: String, $date: String) {
  editJob(jobId: $jobId, description: $description, date: $date) {
    id
  }
}
    `;
export type EditJobHomeownerMutationFn = ApolloReactCommon.MutationFunction<EditJobHomeownerMutation, EditJobHomeownerMutationVariables>;
export type EditJobHomeownerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditJobHomeownerMutation, EditJobHomeownerMutationVariables>, 'mutation'>;

    export const EditJobHomeownerComponent = (props: EditJobHomeownerComponentProps) => (
      <ApolloReactComponents.Mutation<EditJobHomeownerMutation, EditJobHomeownerMutationVariables> mutation={EditJobHomeownerDocument} {...props} />
    );
    

/**
 * __useEditJobHomeownerMutation__
 *
 * To run a mutation, you first call `useEditJobHomeownerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJobHomeownerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJobHomeownerMutation, { data, loading, error }] = useEditJobHomeownerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      description: // value for 'description'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useEditJobHomeownerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditJobHomeownerMutation, EditJobHomeownerMutationVariables>) {
        return ApolloReactHooks.useMutation<EditJobHomeownerMutation, EditJobHomeownerMutationVariables>(EditJobHomeownerDocument, baseOptions);
      }
export type EditJobHomeownerMutationHookResult = ReturnType<typeof useEditJobHomeownerMutation>;
export type EditJobHomeownerMutationResult = ApolloReactCommon.MutationResult<EditJobHomeownerMutation>;
export type EditJobHomeownerMutationOptions = ApolloReactCommon.BaseMutationOptions<EditJobHomeownerMutation, EditJobHomeownerMutationVariables>;
export const DeleteJobHomeownerDocument = gql`
    mutation DeleteJobHomeowner($jobId: ID!) {
  deleteJob(jobId: $jobId)
}
    `;
export type DeleteJobHomeownerMutationFn = ApolloReactCommon.MutationFunction<DeleteJobHomeownerMutation, DeleteJobHomeownerMutationVariables>;
export type DeleteJobHomeownerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteJobHomeownerMutation, DeleteJobHomeownerMutationVariables>, 'mutation'>;

    export const DeleteJobHomeownerComponent = (props: DeleteJobHomeownerComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteJobHomeownerMutation, DeleteJobHomeownerMutationVariables> mutation={DeleteJobHomeownerDocument} {...props} />
    );
    

/**
 * __useDeleteJobHomeownerMutation__
 *
 * To run a mutation, you first call `useDeleteJobHomeownerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobHomeownerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobHomeownerMutation, { data, loading, error }] = useDeleteJobHomeownerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDeleteJobHomeownerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJobHomeownerMutation, DeleteJobHomeownerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJobHomeownerMutation, DeleteJobHomeownerMutationVariables>(DeleteJobHomeownerDocument, baseOptions);
      }
export type DeleteJobHomeownerMutationHookResult = ReturnType<typeof useDeleteJobHomeownerMutation>;
export type DeleteJobHomeownerMutationResult = ApolloReactCommon.MutationResult<DeleteJobHomeownerMutation>;
export type DeleteJobHomeownerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJobHomeownerMutation, DeleteJobHomeownerMutationVariables>;
export const ChangeBidAmountDocument = gql`
    mutation ChangeBidAmount($jobId: ID!, $bidId: ID!, $amount: Float!) {
  changeBidAmount(jobId: $jobId, bidId: $bidId, amount: $amount) {
    id
  }
}
    `;
export type ChangeBidAmountMutationFn = ApolloReactCommon.MutationFunction<ChangeBidAmountMutation, ChangeBidAmountMutationVariables>;
export type ChangeBidAmountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeBidAmountMutation, ChangeBidAmountMutationVariables>, 'mutation'>;

    export const ChangeBidAmountComponent = (props: ChangeBidAmountComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeBidAmountMutation, ChangeBidAmountMutationVariables> mutation={ChangeBidAmountDocument} {...props} />
    );
    

/**
 * __useChangeBidAmountMutation__
 *
 * To run a mutation, you first call `useChangeBidAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBidAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBidAmountMutation, { data, loading, error }] = useChangeBidAmountMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      bidId: // value for 'bidId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useChangeBidAmountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeBidAmountMutation, ChangeBidAmountMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeBidAmountMutation, ChangeBidAmountMutationVariables>(ChangeBidAmountDocument, baseOptions);
      }
export type ChangeBidAmountMutationHookResult = ReturnType<typeof useChangeBidAmountMutation>;
export type ChangeBidAmountMutationResult = ApolloReactCommon.MutationResult<ChangeBidAmountMutation>;
export type ChangeBidAmountMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeBidAmountMutation, ChangeBidAmountMutationVariables>;
export const Request3dModelDocument = gql`
    mutation Request3dModel($propertyId: ID!, $promoCode: String) {
  request3dModel(propertyId: $propertyId, promoCode: $promoCode) {
    id
  }
}
    `;
export type Request3dModelMutationFn = ApolloReactCommon.MutationFunction<Request3dModelMutation, Request3dModelMutationVariables>;
export type Request3dModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Request3dModelMutation, Request3dModelMutationVariables>, 'mutation'>;

    export const Request3dModelComponent = (props: Request3dModelComponentProps) => (
      <ApolloReactComponents.Mutation<Request3dModelMutation, Request3dModelMutationVariables> mutation={Request3dModelDocument} {...props} />
    );
    

/**
 * __useRequest3dModelMutation__
 *
 * To run a mutation, you first call `useRequest3dModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequest3dModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [request3dModelMutation, { data, loading, error }] = useRequest3dModelMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useRequest3dModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Request3dModelMutation, Request3dModelMutationVariables>) {
        return ApolloReactHooks.useMutation<Request3dModelMutation, Request3dModelMutationVariables>(Request3dModelDocument, baseOptions);
      }
export type Request3dModelMutationHookResult = ReturnType<typeof useRequest3dModelMutation>;
export type Request3dModelMutationResult = ApolloReactCommon.MutationResult<Request3dModelMutation>;
export type Request3dModelMutationOptions = ApolloReactCommon.BaseMutationOptions<Request3dModelMutation, Request3dModelMutationVariables>;
export const AdminPromosDocument = gql`
    query AdminPromos {
  adminPromos {
    id
    code
    zipCodes
    creditsLeft
    startingCredits
    createdAt
    userType
    expiresAt
    percentageDiscount
  }
}
    `;
export type AdminPromosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminPromosQuery, AdminPromosQueryVariables>, 'query'>;

    export const AdminPromosComponent = (props: AdminPromosComponentProps) => (
      <ApolloReactComponents.Query<AdminPromosQuery, AdminPromosQueryVariables> query={AdminPromosDocument} {...props} />
    );
    

/**
 * __useAdminPromosQuery__
 *
 * To run a query within a React component, call `useAdminPromosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPromosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPromosQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPromosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminPromosQuery, AdminPromosQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminPromosQuery, AdminPromosQueryVariables>(AdminPromosDocument, baseOptions);
      }
export function useAdminPromosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPromosQuery, AdminPromosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminPromosQuery, AdminPromosQueryVariables>(AdminPromosDocument, baseOptions);
        }
export type AdminPromosQueryHookResult = ReturnType<typeof useAdminPromosQuery>;
export type AdminPromosLazyQueryHookResult = ReturnType<typeof useAdminPromosLazyQuery>;
export type AdminPromosQueryResult = ApolloReactCommon.QueryResult<AdminPromosQuery, AdminPromosQueryVariables>;
export function refetchAdminPromosQuery(variables?: AdminPromosQueryVariables) {
      return { query: AdminPromosDocument, variables: variables }
    }
export const AdminCreatePromoDocument = gql`
    mutation AdminCreatePromo($code: String!, $zipCodes: [String!]!, $startingCredits: Int!, $userType: UserRole!, $expiresAt: DateTime, $percentageDiscount: Int!) {
  adminCreatePromo(code: $code, zipCodes: $zipCodes, startingCredits: $startingCredits, userType: $userType, expiresAt: $expiresAt, percentageDiscount: $percentageDiscount) {
    id
  }
}
    `;
export type AdminCreatePromoMutationFn = ApolloReactCommon.MutationFunction<AdminCreatePromoMutation, AdminCreatePromoMutationVariables>;
export type AdminCreatePromoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminCreatePromoMutation, AdminCreatePromoMutationVariables>, 'mutation'>;

    export const AdminCreatePromoComponent = (props: AdminCreatePromoComponentProps) => (
      <ApolloReactComponents.Mutation<AdminCreatePromoMutation, AdminCreatePromoMutationVariables> mutation={AdminCreatePromoDocument} {...props} />
    );
    

/**
 * __useAdminCreatePromoMutation__
 *
 * To run a mutation, you first call `useAdminCreatePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreatePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreatePromoMutation, { data, loading, error }] = useAdminCreatePromoMutation({
 *   variables: {
 *      code: // value for 'code'
 *      zipCodes: // value for 'zipCodes'
 *      startingCredits: // value for 'startingCredits'
 *      userType: // value for 'userType'
 *      expiresAt: // value for 'expiresAt'
 *      percentageDiscount: // value for 'percentageDiscount'
 *   },
 * });
 */
export function useAdminCreatePromoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminCreatePromoMutation, AdminCreatePromoMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminCreatePromoMutation, AdminCreatePromoMutationVariables>(AdminCreatePromoDocument, baseOptions);
      }
export type AdminCreatePromoMutationHookResult = ReturnType<typeof useAdminCreatePromoMutation>;
export type AdminCreatePromoMutationResult = ApolloReactCommon.MutationResult<AdminCreatePromoMutation>;
export type AdminCreatePromoMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminCreatePromoMutation, AdminCreatePromoMutationVariables>;
export const AdminSetpromoCreditsDocument = gql`
    mutation AdminSetpromoCredits($id: ID!, $credits: Int!) {
  adminSetpromoCredits(id: $id, credits: $credits) {
    id
  }
}
    `;
export type AdminSetpromoCreditsMutationFn = ApolloReactCommon.MutationFunction<AdminSetpromoCreditsMutation, AdminSetpromoCreditsMutationVariables>;
export type AdminSetpromoCreditsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminSetpromoCreditsMutation, AdminSetpromoCreditsMutationVariables>, 'mutation'>;

    export const AdminSetpromoCreditsComponent = (props: AdminSetpromoCreditsComponentProps) => (
      <ApolloReactComponents.Mutation<AdminSetpromoCreditsMutation, AdminSetpromoCreditsMutationVariables> mutation={AdminSetpromoCreditsDocument} {...props} />
    );
    

/**
 * __useAdminSetpromoCreditsMutation__
 *
 * To run a mutation, you first call `useAdminSetpromoCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSetpromoCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSetpromoCreditsMutation, { data, loading, error }] = useAdminSetpromoCreditsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      credits: // value for 'credits'
 *   },
 * });
 */
export function useAdminSetpromoCreditsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminSetpromoCreditsMutation, AdminSetpromoCreditsMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminSetpromoCreditsMutation, AdminSetpromoCreditsMutationVariables>(AdminSetpromoCreditsDocument, baseOptions);
      }
export type AdminSetpromoCreditsMutationHookResult = ReturnType<typeof useAdminSetpromoCreditsMutation>;
export type AdminSetpromoCreditsMutationResult = ApolloReactCommon.MutationResult<AdminSetpromoCreditsMutation>;
export type AdminSetpromoCreditsMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminSetpromoCreditsMutation, AdminSetpromoCreditsMutationVariables>;
export const ValidatePromoDocument = gql`
    query ValidatePromo($propertyId: ID!, $promoCode: String!) {
  validatePromo(propertyId: $propertyId, promoCode: $promoCode) {
    finalPrice
  }
}
    `;
export type ValidatePromoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ValidatePromoQuery, ValidatePromoQueryVariables>, 'query'> & ({ variables: ValidatePromoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ValidatePromoComponent = (props: ValidatePromoComponentProps) => (
      <ApolloReactComponents.Query<ValidatePromoQuery, ValidatePromoQueryVariables> query={ValidatePromoDocument} {...props} />
    );
    

/**
 * __useValidatePromoQuery__
 *
 * To run a query within a React component, call `useValidatePromoQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePromoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePromoQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useValidatePromoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidatePromoQuery, ValidatePromoQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidatePromoQuery, ValidatePromoQueryVariables>(ValidatePromoDocument, baseOptions);
      }
export function useValidatePromoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidatePromoQuery, ValidatePromoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidatePromoQuery, ValidatePromoQueryVariables>(ValidatePromoDocument, baseOptions);
        }
export type ValidatePromoQueryHookResult = ReturnType<typeof useValidatePromoQuery>;
export type ValidatePromoLazyQueryHookResult = ReturnType<typeof useValidatePromoLazyQuery>;
export type ValidatePromoQueryResult = ApolloReactCommon.QueryResult<ValidatePromoQuery, ValidatePromoQueryVariables>;
export function refetchValidatePromoQuery(variables?: ValidatePromoQueryVariables) {
      return { query: ValidatePromoDocument, variables: variables }
    }
export const AdminGetUsersDocument = gql`
    query AdminGetUsers($page: Int!, $search: String) {
  adminGetUsersCount(search: $search)
  adminGetUsers(page: $page, search: $search) {
    id
    role
    email
    createdAt
    ongoingJobCount
    biddableJobCount
    fullName
    properties {
      id
      houseNumber
      streetName
      city
      state
      zipCode
      ready
    }
  }
}
    `;
export type AdminGetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>, 'query'> & ({ variables: AdminGetUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminGetUsersComponent = (props: AdminGetUsersComponentProps) => (
      <ApolloReactComponents.Query<AdminGetUsersQuery, AdminGetUsersQueryVariables> query={AdminGetUsersDocument} {...props} />
    );
    

/**
 * __useAdminGetUsersQuery__
 *
 * To run a query within a React component, call `useAdminGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, baseOptions);
      }
export function useAdminGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, baseOptions);
        }
export type AdminGetUsersQueryHookResult = ReturnType<typeof useAdminGetUsersQuery>;
export type AdminGetUsersLazyQueryHookResult = ReturnType<typeof useAdminGetUsersLazyQuery>;
export type AdminGetUsersQueryResult = ApolloReactCommon.QueryResult<AdminGetUsersQuery, AdminGetUsersQueryVariables>;
export function refetchAdminGetUsersQuery(variables?: AdminGetUsersQueryVariables) {
      return { query: AdminGetUsersDocument, variables: variables }
    }
export const AdminGetPropertyDocument = gql`
    query AdminGetProperty($id: ID!) {
  adminGetProperty(id: $id) {
    id
    createdAt
    houseNumber
    streetName
    city
    state
    zipCode
    ready
    renoworksId
    legacyHomeSpecId
    renoworksDisplayId
    propertySpec {
      ...PropertySpecsFragment
    }
  }
}
    ${PropertySpecsFragmentFragmentDoc}`;
export type AdminGetPropertyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminGetPropertyQuery, AdminGetPropertyQueryVariables>, 'query'> & ({ variables: AdminGetPropertyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminGetPropertyComponent = (props: AdminGetPropertyComponentProps) => (
      <ApolloReactComponents.Query<AdminGetPropertyQuery, AdminGetPropertyQueryVariables> query={AdminGetPropertyDocument} {...props} />
    );
    

/**
 * __useAdminGetPropertyQuery__
 *
 * To run a query within a React component, call `useAdminGetPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetPropertyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminGetPropertyQuery, AdminGetPropertyQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminGetPropertyQuery, AdminGetPropertyQueryVariables>(AdminGetPropertyDocument, baseOptions);
      }
export function useAdminGetPropertyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminGetPropertyQuery, AdminGetPropertyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminGetPropertyQuery, AdminGetPropertyQueryVariables>(AdminGetPropertyDocument, baseOptions);
        }
export type AdminGetPropertyQueryHookResult = ReturnType<typeof useAdminGetPropertyQuery>;
export type AdminGetPropertyLazyQueryHookResult = ReturnType<typeof useAdminGetPropertyLazyQuery>;
export type AdminGetPropertyQueryResult = ApolloReactCommon.QueryResult<AdminGetPropertyQuery, AdminGetPropertyQueryVariables>;
export function refetchAdminGetPropertyQuery(variables?: AdminGetPropertyQueryVariables) {
      return { query: AdminGetPropertyDocument, variables: variables }
    }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CompleteResetPasswordDocument = gql`
    mutation CompleteResetPassword($code: String!, $password: String!) {
  completeResetPassword(code: $code, password: $password)
}
    `;
export type CompleteResetPasswordMutationFn = ApolloReactCommon.MutationFunction<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>;
export type CompleteResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>, 'mutation'>;

    export const CompleteResetPasswordComponent = (props: CompleteResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables> mutation={CompleteResetPasswordDocument} {...props} />
    );
    

/**
 * __useCompleteResetPasswordMutation__
 *
 * To run a mutation, you first call `useCompleteResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeResetPasswordMutation, { data, loading, error }] = useCompleteResetPasswordMutation({
 *   variables: {
 *      code: // value for 'code'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCompleteResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>(CompleteResetPasswordDocument, baseOptions);
      }
export type CompleteResetPasswordMutationHookResult = ReturnType<typeof useCompleteResetPasswordMutation>;
export type CompleteResetPasswordMutationResult = ApolloReactCommon.MutationResult<CompleteResetPasswordMutation>;
export type CompleteResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteResetPasswordMutation, CompleteResetPasswordMutationVariables>;
export const SubmitRatingDocument = gql`
    mutation SubmitRating($code: String!, $ratingGiven: Int!, $comment: String) {
  submitRating(code: $code, ratingGiven: $ratingGiven, comment: $comment)
}
    `;
export type SubmitRatingMutationFn = ApolloReactCommon.MutationFunction<SubmitRatingMutation, SubmitRatingMutationVariables>;
export type SubmitRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitRatingMutation, SubmitRatingMutationVariables>, 'mutation'>;

    export const SubmitRatingComponent = (props: SubmitRatingComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitRatingMutation, SubmitRatingMutationVariables> mutation={SubmitRatingDocument} {...props} />
    );
    

/**
 * __useSubmitRatingMutation__
 *
 * To run a mutation, you first call `useSubmitRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRatingMutation, { data, loading, error }] = useSubmitRatingMutation({
 *   variables: {
 *      code: // value for 'code'
 *      ratingGiven: // value for 'ratingGiven'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSubmitRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRatingMutation, SubmitRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRatingMutation, SubmitRatingMutationVariables>(SubmitRatingDocument, baseOptions);
      }
export type SubmitRatingMutationHookResult = ReturnType<typeof useSubmitRatingMutation>;
export type SubmitRatingMutationResult = ApolloReactCommon.MutationResult<SubmitRatingMutation>;
export type SubmitRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRatingMutation, SubmitRatingMutationVariables>;
export const GetContractorDetailsDocument = gql`
    query GetContractorDetails {
  contractor {
    ...ContractorFragment
  }
}
    ${ContractorFragmentFragmentDoc}`;
export type GetContractorDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractorDetailsQuery, GetContractorDetailsQueryVariables>, 'query'>;

    export const GetContractorDetailsComponent = (props: GetContractorDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetContractorDetailsQuery, GetContractorDetailsQueryVariables> query={GetContractorDetailsDocument} {...props} />
    );
    

/**
 * __useGetContractorDetailsQuery__
 *
 * To run a query within a React component, call `useGetContractorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractorDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractorDetailsQuery, GetContractorDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractorDetailsQuery, GetContractorDetailsQueryVariables>(GetContractorDetailsDocument, baseOptions);
      }
export function useGetContractorDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractorDetailsQuery, GetContractorDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractorDetailsQuery, GetContractorDetailsQueryVariables>(GetContractorDetailsDocument, baseOptions);
        }
export type GetContractorDetailsQueryHookResult = ReturnType<typeof useGetContractorDetailsQuery>;
export type GetContractorDetailsLazyQueryHookResult = ReturnType<typeof useGetContractorDetailsLazyQuery>;
export type GetContractorDetailsQueryResult = ApolloReactCommon.QueryResult<GetContractorDetailsQuery, GetContractorDetailsQueryVariables>;
export function refetchGetContractorDetailsQuery(variables?: GetContractorDetailsQueryVariables) {
      return { query: GetContractorDetailsDocument, variables: variables }
    }
export const GetContractorJobsDocument = gql`
    query GetContractorJobs($page: Int!, $view: String!, $trades: [TradeGroup!], $jobId: ID) {
  contractor {
    id
    trades {
      id
      group
    }
  }
  contractorJobs(page: $page, view: $view, trades: $trades, jobId: $jobId) {
    ...ContractorJobFragment
  }
  contractorOngoingJobsCount(trades: $trades)
  contractorPendingBidsCount(trades: $trades)
  contractorBiddableJobsCount(trades: $trades)
  contractorClosedJobsCount(trades: $trades)
}
    ${ContractorJobFragmentFragmentDoc}`;
export type GetContractorJobsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractorJobsQuery, GetContractorJobsQueryVariables>, 'query'> & ({ variables: GetContractorJobsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContractorJobsComponent = (props: GetContractorJobsComponentProps) => (
      <ApolloReactComponents.Query<GetContractorJobsQuery, GetContractorJobsQueryVariables> query={GetContractorJobsDocument} {...props} />
    );
    

/**
 * __useGetContractorJobsQuery__
 *
 * To run a query within a React component, call `useGetContractorJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      view: // value for 'view'
 *      trades: // value for 'trades'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetContractorJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractorJobsQuery, GetContractorJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractorJobsQuery, GetContractorJobsQueryVariables>(GetContractorJobsDocument, baseOptions);
      }
export function useGetContractorJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractorJobsQuery, GetContractorJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractorJobsQuery, GetContractorJobsQueryVariables>(GetContractorJobsDocument, baseOptions);
        }
export type GetContractorJobsQueryHookResult = ReturnType<typeof useGetContractorJobsQuery>;
export type GetContractorJobsLazyQueryHookResult = ReturnType<typeof useGetContractorJobsLazyQuery>;
export type GetContractorJobsQueryResult = ApolloReactCommon.QueryResult<GetContractorJobsQuery, GetContractorJobsQueryVariables>;
export function refetchGetContractorJobsQuery(variables?: GetContractorJobsQueryVariables) {
      return { query: GetContractorJobsDocument, variables: variables }
    }
export const AdminGetJobSpecificationsDocument = gql`
    query AdminGetJobSpecifications($jobTitle: String, $view: String) {
  adminGetJobSpecifications(jobTitle: $jobTitle, view: $view) {
    id
    title
  }
}
    `;
export type AdminGetJobSpecificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables>, 'query'>;

    export const AdminGetJobSpecificationsComponent = (props: AdminGetJobSpecificationsComponentProps) => (
      <ApolloReactComponents.Query<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables> query={AdminGetJobSpecificationsDocument} {...props} />
    );
    

/**
 * __useAdminGetJobSpecificationsQuery__
 *
 * To run a query within a React component, call `useAdminGetJobSpecificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetJobSpecificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetJobSpecificationsQuery({
 *   variables: {
 *      jobTitle: // value for 'jobTitle'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useAdminGetJobSpecificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables>(AdminGetJobSpecificationsDocument, baseOptions);
      }
export function useAdminGetJobSpecificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables>(AdminGetJobSpecificationsDocument, baseOptions);
        }
export type AdminGetJobSpecificationsQueryHookResult = ReturnType<typeof useAdminGetJobSpecificationsQuery>;
export type AdminGetJobSpecificationsLazyQueryHookResult = ReturnType<typeof useAdminGetJobSpecificationsLazyQuery>;
export type AdminGetJobSpecificationsQueryResult = ApolloReactCommon.QueryResult<AdminGetJobSpecificationsQuery, AdminGetJobSpecificationsQueryVariables>;
export function refetchAdminGetJobSpecificationsQuery(variables?: AdminGetJobSpecificationsQueryVariables) {
      return { query: AdminGetJobSpecificationsDocument, variables: variables }
    }
export const SignupJoinDealDocument = gql`
    mutation SignupJoinDeal($dealBidderId: ID!, $firstName: String!, $lastName: String!, $email: String!, $password: String!, $houseNumber: String!, $streetName: String!, $city: String!, $state: String!, $county: ID!, $zipCode: String!, $lat: String!, $lng: String!, $proposedDate: String) {
  signupJoinDeal(dealBidderId: $dealBidderId, firstName: $firstName, lastName: $lastName, email: $email, password: $password, houseNumber: $houseNumber, streetName: $streetName, city: $city, state: $state, county: $county, zipCode: $zipCode, lat: $lat, lng: $lng, proposedDate: $proposedDate)
}
    `;
export type SignupJoinDealMutationFn = ApolloReactCommon.MutationFunction<SignupJoinDealMutation, SignupJoinDealMutationVariables>;
export type SignupJoinDealComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupJoinDealMutation, SignupJoinDealMutationVariables>, 'mutation'>;

    export const SignupJoinDealComponent = (props: SignupJoinDealComponentProps) => (
      <ApolloReactComponents.Mutation<SignupJoinDealMutation, SignupJoinDealMutationVariables> mutation={SignupJoinDealDocument} {...props} />
    );
    

/**
 * __useSignupJoinDealMutation__
 *
 * To run a mutation, you first call `useSignupJoinDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupJoinDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupJoinDealMutation, { data, loading, error }] = useSignupJoinDealMutation({
 *   variables: {
 *      dealBidderId: // value for 'dealBidderId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      houseNumber: // value for 'houseNumber'
 *      streetName: // value for 'streetName'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      county: // value for 'county'
 *      zipCode: // value for 'zipCode'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      proposedDate: // value for 'proposedDate'
 *   },
 * });
 */
export function useSignupJoinDealMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupJoinDealMutation, SignupJoinDealMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupJoinDealMutation, SignupJoinDealMutationVariables>(SignupJoinDealDocument, baseOptions);
      }
export type SignupJoinDealMutationHookResult = ReturnType<typeof useSignupJoinDealMutation>;
export type SignupJoinDealMutationResult = ApolloReactCommon.MutationResult<SignupJoinDealMutation>;
export type SignupJoinDealMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupJoinDealMutation, SignupJoinDealMutationVariables>;
export const VerifyUserDocument = gql`
    mutation VerifyUser($userId: ID!, $verificationCode: String!) {
  verifyUser(userId: $userId, verificationCode: $verificationCode) {
    verified
  }
}
    `;
export type VerifyUserMutationFn = ApolloReactCommon.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;
export type VerifyUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyUserMutation, VerifyUserMutationVariables>, 'mutation'>;

    export const VerifyUserComponent = (props: VerifyUserComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyUserMutation, VerifyUserMutationVariables> mutation={VerifyUserDocument} {...props} />
    );
    

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, baseOptions);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = ApolloReactCommon.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyUserMutation, VerifyUserMutationVariables>;